import cx from "classnames";
import { AnswerWithReviewer } from "types/answer-with-reviewer.type";
import { Question } from "types/question";
import QuestionHeader from "./QuestionHeader";
import TextFeedback from "./TextFeedback";

type TextFeedbacksProps = {
  question: Question;
  answersWithReviewers: AnswerWithReviewer[];
  showUserProfileCard?: boolean;
  isSingleCol?: boolean;
  hint?: string;
};

const TextFeedbacks: React.FC<TextFeedbacksProps> = ({
  question,
  answersWithReviewers,
  showUserProfileCard = false,
  isSingleCol = true,
  hint,
}) => {
  const { sequence, label } = question;

  return (
    <div className="flex flex-col text-neutral-150">
      <QuestionHeader sequence={sequence} label={label} hint={hint} />
      <div
        className={cx("grid gap-6", {
          "grid-cols-2": !isSingleCol,
          "grid-cols-1": isSingleCol,
        })}
      >
        {answersWithReviewers.map(({ answer, reviewer }) => {
          return (
            <TextFeedback
              key={answer.id}
              answer={answer}
              reviewer={reviewer}
              showUserProfileCard={showUserProfileCard}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TextFeedbacks;

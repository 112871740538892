import { Question } from "types/question";
import cx from "classnames";
import PreviewQuestion from "./PreviewQuestion";
import { useMemo } from "react";
import { ArrayUtil } from "utils";
import { Divide } from "components/common/layouts";

type PreviewFormProps = {
  name: string;
  questions: Question[];
  className?: string;
};

const PreviewForm: React.FC<PreviewFormProps> = ({
  name,
  questions,
  className,
}) => {
  const sortedQuestions = useMemo(
    () => ArrayUtil.sortAscBySequence(questions),
    [questions],
  );
  return (
    <div className={cx(className)}>
      <Divide
        className={cx("bg-blue-10 rounded-2xl px-8 pt-10 pb-20 min-h-[552px]")}
      >
        <div className="mb-6">
          <span className="body-20-semibold text-neutral-200">{name}</span>
        </div>
        <div className="pt-6 flex flex-col gap-6">
          {sortedQuestions.map((question) => (
            <PreviewQuestion question={question} key={question.id} />
          ))}
        </div>
      </Divide>
    </div>
  );
};

export default PreviewForm;

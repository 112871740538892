import { useHttpQueryService } from "hooks";
import Main from "./Main";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import { PropsWithChildren, useEffect } from "react";
import { useAuthStore, useFeedbackRequestStore } from "store";
import { FeedbackRequestService } from "services";

type Props = PropsWithChildren & {
  mainClassName?: string;
};

const Layout: React.FC<Props> = ({ children, mainClassName }) => {
  const user = useAuthStore((state) => state.user);
  const setTotalsOfTodoRequest = useFeedbackRequestStore(
    (state) => state.setTotalsOfTodoRequest,
  );

  // Fetch totals of to do requests
  const { result: totals } = useHttpQueryService({
    request: async () => FeedbackRequestService.getTotalsOfTodoRequest(),
  });

  useEffect(() => {
    if (totals) {
      setTotalsOfTodoRequest(totals);
    }
  }, [totals]);

  if (!user) return null;

  return (
    <div className="w-full bg-blue-10">
      <div className="max-w-[1440px] mx-auto flex">
        <SideBar role={user.role} />
        <div className="w-full flex min-h-[100vh] flex-col">
          <NavBar avatar={user.avatar} fullName={user.fullName} />
          <Main className={mainClassName}>{children}</Main>
        </div>
      </div>
    </div>
  );
};

export default Layout;

import { AsyncDropdown, TextArea, TextField } from "components/common/forms";
import { ChangeEvent } from "react";
import { SingleValue } from "react-select";
import { FieldErrors } from "types/field-errors.type";
import { IMultipleChoiceOption } from "types/input.type";
import {
  ProactivelyGiveAnytimeFeedbackFormData,
  ProactivelyGiveAnytimeFeedbackRequest,
} from "types/proactively-give-anytime-feedback.type";

type ProactivelyGiveAnytimeFeedbackFormProps = {
  formData: ProactivelyGiveAnytimeFeedbackFormData;
  onChange: (newFormData: ProactivelyGiveAnytimeFeedbackFormData) => void;
  searchReviewee: (searchText: string) => Promise<IMultipleChoiceOption[]>;
  validationError: FieldErrors<ProactivelyGiveAnytimeFeedbackRequest>;
  onClearValidationError: () => void;
};

const ProactivelyGiveAnytimeFeedbackForm: React.FC<
  ProactivelyGiveAnytimeFeedbackFormProps
> = ({
  formData,
  onChange,
  searchReviewee,
  validationError,
  onClearValidationError,
}) => {
  const { name, reviewee, feedback } = formData;

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newFormData: ProactivelyGiveAnytimeFeedbackFormData = {
      ...formData,
      name: value,
    };

    onChange(newFormData);
  };

  const onRevieweeChange = (value: SingleValue<IMultipleChoiceOption>) => {
    if (!value) return;

    const newFormData: ProactivelyGiveAnytimeFeedbackFormData = {
      ...formData,
      reviewee: value,
    };

    onChange(newFormData);
  };

  const handleChangeFeedback = (name: string, value: string) => {
    const newFormData: ProactivelyGiveAnytimeFeedbackFormData = {
      ...formData,
      feedback: value,
    };

    onChange(newFormData);
  };

  return (
    <form className="flex flex-col gap-6">
      <AsyncDropdown<false>
        id="reviewee"
        value={reviewee}
        onChange={onRevieweeChange}
        label="Who is this feedback about?"
        placeholder="Find people by email..."
        request={searchReviewee}
        errorMessage={validationError?.revieweeId}
        onClearError={onClearValidationError}
        required
      />
      <TextField
        label="What is this feedback about?"
        hint="This will be the title of your feedback"
        placeholder="Enter the title for your feedback form"
        value={name}
        onChange={handleChangeName}
        id={"name"}
        errorMessage={validationError?.name}
        onClearError={onClearValidationError}
      />

      <TextArea
        label="Feedback"
        hint="You can give some information for reviewers to understand more about the question"
        placeholder="Enter your feedback form"
        value={feedback}
        onTextFormatterChange={handleChangeFeedback}
        id={"feedback"}
        rows={6}
        errorMessage={validationError?.feedback}
        onClearError={onClearValidationError}
      />
    </form>
  );
};

export default ProactivelyGiveAnytimeFeedbackForm;

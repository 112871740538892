import Joi from "joi";
import { SendOut360Request } from "types/feedback-request";

export const sendOut360Schema = Joi.object<SendOut360Request>({
  formId: Joi.number().required().messages({
    "any.required": "Form is required.",
  }),
  dueDate: Joi.date()
    .required()
    .messages({
      "date.base": "Deadline must be a valid date",
      "any.required": "Deadline is required for 360 Form",
    })
    .when("$dueDateChanged", {
      is: Joi.boolean().valid(true).required(),
      then: Joi.date().min(new Date()).messages({
        "date.min": "Deadline must be greater than the current date",
      }),
      otherwise: Joi.any(),
    }),
  publishDate: Joi.date().greater(Joi.ref("dueDate")).required().messages({
    "date.base": "Publish date must be a valid date",
    "date.greater": "Publish date must be greater than the deadline",
    "any.required": "Publish date is required for 360 Form",
  }),
  startDateOfPeriod: Joi.date().required().messages({
    "date.base": "Start date must be a valid date",
    "any.required": "Start date is required for 360 Form",
  }),
  endDateOfPeriod: Joi.date()
    .greater(Joi.ref("startDateOfPeriod"))
    .required()
    .messages({
      "date.base": "End date must be a valid date",
      "date.greater": "End date must be greater than start date",
      "any.required": "End date is required for 360 Form",
    }),
});

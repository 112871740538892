import ToDoFeedbackRequestItem from "./ToDoItem";
import cx from "classnames";
import NoToDoFeedbackRequest from "pages/todo/NoToDoFeedbackRequest";
import { TodoRequestResponse } from "responses";

type ToDoListProps = {
  feedbackRequests: TodoRequestResponse[];
  className?: string;
};

const ToDoList: React.FC<ToDoListProps> = ({ feedbackRequests, className }) => {
  return (
    <>
      {!feedbackRequests || feedbackRequests.length === 0 ? (
        <NoToDoFeedbackRequest />
      ) : (
        <div className={cx("flex flex-col gap-4", className)}>
          {feedbackRequests.map((item) => (
            <ToDoFeedbackRequestItem key={item.id} feedbackRequest={item} />
          ))}
        </div>
      )}
    </>
  );
};

export default ToDoList;

import { useMemo } from "react";
import { OptionQuestion } from "types/question";
import { ArrayUtil } from "utils";
import { RadioGroup } from "components/common/forms";

type PreviewMultipleChoiceQuestionProps = {
  question: OptionQuestion;
};
const PreviewMultipleChoiceQuestion: React.FC<
  PreviewMultipleChoiceQuestionProps
> = ({ question }) => {
  const {
    label: questionLabel,
    hint,
    answerOptions,
    id: questionId,
    sequence,
  } = question;
  const id = `question-multiple-${questionId}-${sequence}`;
  let label: string = question.sequence + 1 + ". ";

  const sortedOptions = useMemo(
    () =>
      ArrayUtil.sortAscBySequence(answerOptions).map(({ label, id }) => {
        return { label, value: String(id), disabled: true };
      }),
    [answerOptions],
  );

  if (!questionLabel) {
    label += "...";
    return (
      <p className="mb-2">
        <label htmlFor={id} className="body-16-semibold text-neutral-200">
          {label}
        </label>
      </p>
    );
  }

  label += questionLabel;

  return (
    <RadioGroup
      name={`radio-group-${id}`}
      label={label}
      hint={hint}
      options={sortedOptions}
      id={`question-${id}`}
    />
  );
};
export default PreviewMultipleChoiceQuestion;

import { UserRole } from "enums/user";
import AdminHome from "./AdminHome";
import { Navigate } from "react-router-dom";
import { PAGE_PATHS } from "constants/page-paths";
import { useAuthStore } from "store";

const Home = () => {
  const user = useAuthStore((state) => state.user);

  if (!user) return null;

  if (user.role === UserRole.Employee) {
    return <Navigate to={PAGE_PATHS.REQUEST} />;
  }

  return <AdminHome user={user} />;
};
export default Home;

import { LoadingWrapper } from "components/common/layouts";
import { TableMyReviewers } from "components/feedbackRequest";
import { useError, useHttpQueryService } from "hooks";
import { FeedbackRequestService } from "services";

type MyReviewersTabProps = {
  formId?: number;
};

const MyReviewersTab: React.FC<MyReviewersTabProps> = ({ formId }) => {
  const {
    result: reviewers,
    isLoading,
    error,
  } = useHttpQueryService({
    request: async () => FeedbackRequestService.getMyReviewersByFormId(formId!),
    requestOption: { enabled: !!formId },
  });

  useError(error, true);

  if (!formId) return <p>Please select form to view list your reviewer.</p>;

  return (
    <LoadingWrapper isLoading={isLoading}>
      {reviewers && <TableMyReviewers users={reviewers} />}
    </LoadingWrapper>
  );
};

export default MyReviewersTab;

import { Editor } from "@tiptap/react";
import { Button, ButtonProps } from "components/ui/button";
import { FC } from "react";
import {
  RiBold,
  RiItalic,
  RiListOrdered,
  RiListUnordered,
  RiParagraph,
} from "react-icons/ri";
import { IconType } from "react-icons";
import { cn } from "lib/utils";

interface ToolbarProps {
  editor: Editor;
}
const Toolbar: FC<ToolbarProps> = ({ editor }) => {
  return (
    <div
      className="flex items-center px-[1rem] py-[0.5rem] gap-[0.5rem] max-w-full flex-wrap"
      onClick={(ev) => ev.stopPropagation()}
    >
      <ToolButton
        aria-label="bold"
        isActive={editor.isActive("bold")}
        icon={RiBold}
        onClick={() => editor.chain().focus().toggleBold().run()}
      />
      <ToolButton
        aria-label="italic"
        isActive={editor.isActive("italic")}
        icon={RiItalic}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      />
      {/* <ToolButton
        aria-label="h1"
        isActive={editor.isActive("heading", { level: 1 })}
        icon={RiH1}
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
      />
      <ToolButton
        aria-label="h2"
        isActive={editor.isActive("heading", { level: 2 })}
        icon={RiH2}
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
      /> */}
      <ToolButton
        aria-label="paragraph"
        icon={RiParagraph}
        onClick={() =>
          editor.chain().focus().setParagraph().unsetItalic().unsetBold().run()
        }
      />
      <ToolButton
        aria-label="list-ordered"
        isActive={editor.isActive("orderedList")}
        icon={RiListOrdered}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      />
      <ToolButton
        aria-label="list-bullet"
        isActive={editor.isActive("bulletList")}
        icon={RiListUnordered}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      />
    </div>
  );
};

interface ToolButtonProps extends ButtonProps {
  isActive?: boolean;
  icon: IconType;
}
const ToolButton: FC<ToolButtonProps> = ({
  icon: Icon,
  isActive,
  ...buttonProps
}) => {
  return (
    <Button
      className={cn("p-[0.25rem] h-fit", {
        "bg-turquoise-75 text-neutral-15": isActive,
      })}
      type="button"
      {...buttonProps}
    >
      <Icon size="1rem" />
    </Button>
  );
};

export default Toolbar;

import { ENDPOINT_URL } from "constants/api-url";
import { UserStatusType } from "enums/user";
import { MeResponse, UserProfileResponse } from "responses";
import { User } from "types/user";
import { http } from "utils";

const CONTROLLER_PREFIX = ENDPOINT_URL.USER;

const UserApi = {
  getByToken: async (): Promise<MeResponse> => {
    const res = await http.get<MeResponse>(CONTROLLER_PREFIX + "/me");
    return res.data;
  },

  search: async (
    searchText: string,
    userStatus?: UserStatusType,
  ): Promise<User[]> => {
    const res = await http.get(`${CONTROLLER_PREFIX}/search`, {
      params: {
        searchText,
        userStatus,
      },
    });

    const { data } = res;
    return data;
  },

  getUserProfileById: async (id: number): Promise<UserProfileResponse> => {
    const res = await http.get<UserProfileResponse>(
      `${CONTROLLER_PREFIX}/${id}`,
    );
    return res.data;
  },
};

export default UserApi;

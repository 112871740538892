import DropdownField, {
  DropdownProps,
} from "components/common/forms/DropdownField";
import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import { SendOut360Form, SendOutPeerForm } from "components/feedbackRequest";
import { PreviewForm } from "components/preview";
import { FormName, FormType } from "enums/form";
import { UserStatusType } from "enums/user";
import { useError, useHttpMutationService, useHttpQueryService } from "hooks";
import { useCallback, useMemo, useState } from "react";
import { FeedbackRequestService, FormService, UserService } from "services";
import { SendOut360Request, SendOutPeerRequest } from "types/feedback-request";
import { FormWithQuestion } from "types/form";
import { toastError, toastSuccess } from "utils/toast";
import { cn } from "lib/utils";
import { FieldErrors } from "types/field-errors.type";

const SendingOutSurvey = () => {
  const [selectedFormType, setSelectedFormType] = useState<FormType>();
  const [selectedForm, setSelectedForm] = useState<FormWithQuestion>();
  const [validationError, setValidationError] = useState<
    FieldErrors<SendOut360Request | SendOutPeerRequest>
  >({});
  const {
    result: forms,
    isLoading: isFetching,
    error: fetchError,
    refetch: refetchForms,
  } = useHttpQueryService({
    request: () =>
      FormService.get({
        formType: [FormType.THREE_HUNDRED_SIXTY, FormType.PEER],
      }),
  });

  const { result: running360Forms, refetch: refetchRunning360Forms } =
    useHttpQueryService({
      request: () => FormService.getRunning360Forms(),
    });
  const formOptions = useMemo(() => {
    if (selectedFormType) {
      let formsToDisplay: FormWithQuestion[] = [];
      if (
        selectedFormType === FormType.THREE_HUNDRED_SIXTY &&
        running360Forms &&
        running360Forms.length > 0
      ) {
        formsToDisplay = running360Forms;
      } else if (forms && forms.length > 0) {
        formsToDisplay = forms.filter(
          (form) => form.formType === selectedFormType,
        );
      }

      return formsToDisplay
        .filter((form) => form.formType === selectedFormType)
        .reduce(
          (res, form) => {
            res[form.id] = form.name;
            return res;
          },
          {} as DropdownProps["options"],
        );
    }
    return {};
  }, [forms, selectedFormType, running360Forms]);

  const onSelectForm = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValidationError({});
    setSelectedForm(forms?.find((form) => form.id === Number(e.target.value)));
  };

  const onSelectFormType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValidationError({});
    setSelectedFormType(e.target.value as FormType);
    setSelectedForm(undefined);
  };

  const { mutate: sendOut360, isLoading: isSendingOut360 } =
    useHttpMutationService({
      request: (data?: SendOut360Request) =>
        FeedbackRequestService.send360ToAll(data!),
    });

  const { mutate: sendOutPeer, isLoading: isSendingOutPeer } =
    useHttpMutationService({
      request: (data?: SendOutPeerRequest) =>
        FeedbackRequestService.sendOutPeer(data!),
    });

  useError(fetchError, true);

  const sendFormSuccessfully = () => {
    toastSuccess("The form has been sent successfully.");
    setSelectedFormType(undefined);
    setSelectedForm(undefined);
    refetchForms();
    refetchRunning360Forms();
  };

  const sendFormUnsuccessfully = () => {
    toastError(
      "An error occurred while sending out the form. Please contact CodeLink dev team if this error still persists!",
    );
  };

  const handleSubmitSendOut360Form = async (data: SendOut360Request) => {
    const responseData = await sendOut360(data);

    if (responseData) {
      sendFormSuccessfully();
    } else {
      sendFormUnsuccessfully();
    }
  };

  const handleSubmitSendOutPeerForm = async (data: SendOutPeerRequest) => {
    const responseData = await sendOutPeer(data);

    if (responseData) {
      sendFormSuccessfully();
    } else {
      sendFormUnsuccessfully();
    }
  };

  const handleSearchUsers = useCallback(async (searchText: string) => {
    if (!searchText) return [];
    const users = await UserService.search(searchText, UserStatusType.ACTIVE);
    return users;
  }, []);

  return (
    <Layout>
      <LoadingWrapper isLoading={isFetching}>
        <PageTitle className="mb-6">Sending out Survey</PageTitle>
        <div className="grid grid-cols-2 gap-10">
          <div className="space-y-8">
            <DropdownField
              label="Form type"
              options={{
                [FormType.THREE_HUNDRED_SIXTY]: FormName.THREE_HUNDRED_SIXTY,
                [FormType.PEER]: FormName.PEER,
              }}
              value={selectedFormType ? selectedFormType : ""}
              placeholder="Select form type"
              id="form-select-type"
              name="form-select-type"
              onChange={onSelectFormType}
              className={cn(!selectedFormType && "text-neutral-50")}
            />
            <DropdownField
              label="Choose your form"
              errorMessage={validationError.formId}
              options={formOptions}
              defaultValue=""
              value={selectedForm?.id ? `${selectedForm.id}` : ""}
              id="from-select"
              name="form-select"
              onChange={onSelectForm}
              placeholder="Select form"
              className={cn(!selectedFormType && "text-neutral-50")}
              disabled={!selectedFormType}
            />
            {selectedFormType &&
              (selectedFormType === FormType.THREE_HUNDRED_SIXTY ? (
                <SendOut360Form
                  selectedForm={selectedForm}
                  onSubmit={handleSubmitSendOut360Form}
                  isSubmitting={isSendingOut360}
                  validationError={validationError}
                  setValidationError={setValidationError}
                />
              ) : (
                <SendOutPeerForm
                  selectedForm={selectedForm}
                  onSelectForm={onSelectForm}
                  onSubmit={handleSubmitSendOutPeerForm}
                  validationError={validationError}
                  setValidationError={setValidationError}
                  isSubmitting={isSendingOutPeer}
                  onSearchUser={handleSearchUsers}
                />
              ))}
          </div>
          {selectedForm && (
            <PreviewForm
              name={selectedForm.name}
              questions={selectedForm.questions}
              className="flex-1"
            />
          )}
        </div>
      </LoadingWrapper>
    </Layout>
  );
};

export default SendingOutSurvey;

import { CheckboxList, RadioGroup, TextArea } from "components/common/forms";
import { QuestionType } from "enums/form";
import { CreateAnswerRequest } from "types/answer";
import { IMultipleChoiceOption } from "types/input.type";
import { Question } from "types/question";

interface IAnswerItemProps {
  question: Question;
  answers: CreateAnswerRequest[];
  onChange: (question: Question, value: string | number) => void;
  errorMessage?: string;
}

const AnswerItem: React.FC<IAnswerItemProps> = ({
  question,
  answers,
  onChange,
  errorMessage,
}) => {
  const { id, label, sequence, hint, questionType } = question;

  const displayLabel = label ? `${sequence + 1}. ${label}` : "";

  const answersByQuestion = answers.filter(
    (answer) => answer.questionId === id,
  );

  const handleTextChange = (name: string, value: string) => {
    onChange(question, value);
  };

  const handleOnChange = (value: string | number) => {
    onChange(question, value);
  };

  const renderField = () => {
    switch (questionType) {
      case QuestionType.MULTIPLE_CHOICE:
        return (
          <RadioGroup
            name={`radio-group-${question.id}`}
            hint={hint}
            label={displayLabel}
            selectedValue={
              answersByQuestion.length > 0
                ? answersByQuestion[0]?.answerOptionId
                : undefined
            }
            options={question.answerOptions.map<IMultipleChoiceOption>(
              (option) => ({
                label: option.label,
                value: option.id,
              }),
            )}
            onChange={handleOnChange}
            errorMessage={errorMessage}
          />
        );
      case QuestionType.CHECK_BOXES:
        return (
          <CheckboxList
            hint={hint}
            label={displayLabel}
            options={question.answerOptions.map<IMultipleChoiceOption>(
              (option) => ({
                label: option.label,
                value: option.id,
              }),
            )}
            selectedValues={answersByQuestion
              .filter((answer) => answer !== undefined)
              .map((answer) => String(answer.answerOptionId))}
            onChange={handleOnChange}
          />
        );
      default:
        return (
          <TextArea
            hint={hint}
            label={displayLabel}
            rows={6}
            id={`text-question-${id}`}
            value={answersByQuestion[0]?.answerText || ""}
            onTextFormatterChange={handleTextChange}
            errorMessage={errorMessage}
            required
          />
        );
    }
  };

  return renderField();
};

export default AnswerItem;

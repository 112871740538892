import ExportDropdownButton from "components/common/buttons/ExportDropdownButton";
import { Table } from "components/common/forms";
import { DownloadIcon, EyeIcon } from "components/icons";
import { UserRole } from "enums/user";
import { DropdownItemType } from "pages/360-feedback-list/Three60FeedbackList";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Column } from "react-table";
import { Form } from "types/form";
import { getDisplayDate } from "utils/date";

const CAN_VIEW_360_FEEDBACK_ROLES = [UserRole.SuperAdmin];
const canView360Feedback = (role: UserRole | undefined) =>
  role && CAN_VIEW_360_FEEDBACK_ROLES.includes(role);

type TableFormProps = {
  forms: Form[];
  items: DropdownItemType[];
  onOpenChange: (id: number) => void;
  isOpen: boolean;
  formId: number;
  role: UserRole | undefined;
};

const Table360Feedback: React.FC<TableFormProps> = ({
  forms,
  items,
  isOpen,
  formId,
  onOpenChange,
  role,
}) => {
  const handleOpenChange = (id: number) => {
    onOpenChange(id);
  };

  const columns: Column<Form>[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        id: "name",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        id: "createdAt",
        Cell: ({ cell: { value } }) => {
          const dateString = value ? getDisplayDate(value) : "-";
          return <span>{dateString}</span>;
        },
      },
      {
        Header: () => null,
        id: "buttons",
        Cell: ({ row }: { row: { original: Form } }) => (
          <div className="flex items-center justify-end">
            {canView360Feedback(role) && (
              <Link to={`${row.original.id}`}>
                <EyeIcon className="w-[24px] h-[24px] text-neutral-100" />
              </Link>
            )}
            <ExportDropdownButton
              Icon={DownloadIcon}
              id={row.original.id}
              items={items}
              isOpen={row.original.id === formId && isOpen}
              onOpenChange={handleOpenChange}
            />
          </div>
        ),
      },
    ],
    [isOpen, formId],
  );

  return <Table columns={columns} data={forms} />;
};

export default Table360Feedback;

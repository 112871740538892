import { FormEvent, useCallback, useMemo, useState } from "react";
import { FormWithQuestion } from "types/form";
import { getDateFieldValue } from "utils/due-date-form";
import { toastError, toastSuccess } from "utils/toast";
import { EmployeeRequest360FeedbackBody } from "types/feedback-request";
import { MultiValue } from "types/common";
import { FeedbackRequestService, UserService } from "services";
import { AsyncDropdown, Button, DateField } from "components/common/forms";
import { ConfirmModal } from "components/common/modals";
import { IMultipleChoiceOption } from "types/input.type";
import { useError, useHttpMutationService, useHttpQueryService } from "hooks";
import DropdownField, {
  DropdownProps,
} from "components/common/forms/DropdownField";
import { useAuthStore } from "store";
import { LoadingWrapper } from "components/common/layouts";
import { UserStatusType } from "enums/user";

type Request360ReviewerTabProps = {
  formOptions: DropdownProps["options"];
  selectedForm?: FormWithQuestion;
  onSelectForm: React.ChangeEventHandler<HTMLSelectElement>;
};

const Request360ReviewerTab: React.FC<Request360ReviewerTabProps> = ({
  formOptions,
  selectedForm,
  onSelectForm,
}) => {
  const [showConfirmSendModal, setShowConfirmSendModal] = useState(false);
  const [showConfirmSubmittedModal, setShowConfirmSubmittedModal] =
    useState(false);
  const [submittedModalContent, setSubmittedModalContent] = useState("");

  const [reviewers, setReviewers] = useState<IMultipleChoiceOption[]>([]);

  const currentUser = useAuthStore((state) => state.user);
  const currentUserId = currentUser!.id;
  const selectedFormId = selectedForm?.id;

  const {
    result: alreadyRequestedReviewers,
    isLoading,
    refetch,
    error,
  } = useHttpQueryService({
    request: async () =>
      FeedbackRequestService.getMyReviewersByFormId(selectedFormId!),
    requestOption: { enabled: !!selectedFormId },
  });

  useError(error, true);

  const { mutate, isLoading: isSubmitting } = useHttpMutationService({
    request: (data?: EmployeeRequest360FeedbackBody) =>
      FeedbackRequestService.request360Feedback(data!),
  });

  const dueDate = useMemo(() => {
    return selectedForm?.dueDate;
  }, [selectedForm?.dueDate]);

  const handleCloseConfirmModal = () => {
    setShowConfirmSendModal(false);
  };

  const handleCloseConfirmSubmittedModal = () => {
    setShowConfirmSubmittedModal(false);
  };

  const onReviewersChange = (value: MultiValue<IMultipleChoiceOption>) => {
    setReviewers(value as IMultipleChoiceOption[]);
  };

  const handleSubmit = async () => {
    if (!selectedForm) {
      return handleCloseConfirmModal();
    }

    const reviewerIds = reviewers.map((reviewer) => +reviewer.value);

    const data: EmployeeRequest360FeedbackBody = {
      formId: selectedForm.id,
      userIds: reviewerIds,
    };

    const responseData = await mutate(data);

    if (responseData) {
      const { createdUserIds, alreadySubmittedUserIds } = responseData;
      if (createdUserIds && createdUserIds.length > 0) {
        handleShowSuccessToast(createdUserIds);
      }
      if (alreadySubmittedUserIds && alreadySubmittedUserIds.length > 0) {
        handleShowConfirmSubmittedModal(alreadySubmittedUserIds);
      }
    } else {
      toastError("An error occurred while requesting reviewers.");
    }

    handleCloseConfirmModal();
    setReviewers([]);
    refetch();
  };

  const handleShowConfirmSendModal = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!selectedForm) return;

    setShowConfirmSendModal(true);
  };

  const handleShowConfirmSubmittedModal = (
    alreadySubmittedUserIds: number[],
  ) => {
    const submittedUsers = reviewers
      .filter((user) => alreadySubmittedUserIds.includes(Number(user.value)))
      .map((user) => user.label);
    setSubmittedModalContent(
      `${submittedUsers
        .map((user) => user)
        .join(
          ", ",
        )} already proactively given their 360 feedback for you. You can see their feedback when the cycle is over.`,
    );
    setShowConfirmSubmittedModal(true);
  };

  const handleShowSuccessToast = (createdUserIds: number[]) => {
    const createdUsers = reviewers
      .filter((user) => createdUserIds.includes(Number(user.value)))
      .map((user) => user.label);
    toastSuccess(
      `Requested reviewers ${createdUsers
        .map((user) => user)
        .join(", ")} successfully.`,
    );
  };

  const searchReviewers = useCallback(
    async (searchText: string) => {
      const users = await UserService.search(searchText, UserStatusType.ACTIVE);

      const possibleReviewers = users.filter(({ id }) => {
        const alreadyReviewer = alreadyRequestedReviewers?.find(
          (reviewer) => id === reviewer.id,
        );

        if (id === currentUserId || alreadyReviewer) return false;
        return true;
      });

      const userOptions = possibleReviewers.map<IMultipleChoiceOption>(
        (reviewer) => ({
          value: reviewer.id,
          label: `${reviewer.fullName} (${reviewer.email})`,
        }),
      );
      return userOptions;
    },
    [currentUserId, alreadyRequestedReviewers],
  );

  return (
    <LoadingWrapper isLoading={isLoading}>
      <div>
        <form className="max-w-[800px]" onSubmit={handleShowConfirmSendModal}>
          <div className="flex flex-col gap-6">
            <DropdownField
              label="Choose your form"
              options={formOptions}
              value={String(selectedFormId)}
              id="formSelect"
              name="form-select"
              onChange={onSelectForm}
              disabled
            />

            {dueDate && (
              <DateField
                id="form-due-date"
                label="Deadline"
                hint="Deadline for this survey"
                value={getDateFieldValue(dueDate)}
                name="dueDate"
                disabled
              />
            )}
            <AsyncDropdown<true>
              id="reviewers"
              isMulti
              value={reviewers}
              onChange={onReviewersChange}
              label="Your Reviewer"
              hint="Please select reviewers you would like to request"
              placeholder="Select"
              request={searchReviewers}
              required
            />
          </div>
          <Button
            label="Send"
            className="w-full mt-12"
            type="submit"
            size="large"
          />
        </form>

        <ConfirmModal
          open={showConfirmSendModal}
          title="Request 360 Review"
          content="Are you sure you want to send this form to selected reviewers?"
          yesButton="Yes"
          noButton="No"
          onYes={handleSubmit}
          onNo={handleCloseConfirmModal}
          yesButtonLoading={isSubmitting}
        />

        <ConfirmModal
          open={showConfirmSubmittedModal}
          title="Already submitted feedback"
          content={submittedModalContent}
          yesButton="Ok"
          onYes={handleCloseConfirmSubmittedModal}
        />
      </div>
    </LoadingWrapper>
  );
};

export default Request360ReviewerTab;

import cx from "classnames";
import { ErrorOutlineIcon } from "components/icons";

type FormErrorMessageProps = {
  className?: string;
  message: string;
};

const FormErrorMessage: React.FC<FormErrorMessageProps> = ({
  className,
  message,
}) => {
  return (
    <div className={cx(className, "flex")}>
      <ErrorOutlineIcon className="mr-2 -ml-[2px]" />
      <p className="body-16-regular text-state-error-100">{message}</p>
    </div>
  );
};
export default FormErrorMessage;

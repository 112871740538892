import { Divide } from "components/common/layouts";
import { UserProfileCard } from "components/common/ui";
import { UserProfile } from "types/user";
import Markdown from "react-markdown";

type PreviewAnytimeFeedbackProps = {
  name: string;
  reviewer: UserProfile;
  feedback: string;
};

const PreviewAnytimeFeedback: React.FC<PreviewAnytimeFeedbackProps> = ({
  name,
  reviewer,
  feedback,
}) => {
  return (
    <div>
      <Divide
        className={"bg-blue-10 rounded-2xl px-8 pt-10 pb-20 min-h-[552px]"}
      >
        <div className="mb-6">
          <span className="body-20-semibold text-neutral-200">{name}</span>
        </div>
        <div className="pt-6">
          <UserProfileCard user={reviewer} showText showAvatar />
          <Markdown className="mt-4 body-14-regular text-neutral-100">
            {feedback}
          </Markdown>
        </div>
      </Divide>
    </div>
  );
};

export default PreviewAnytimeFeedback;

import { ChangeEventHandler, FormEvent, useEffect, useState } from "react";
import { FormWithQuestion } from "types/form";
import {
  DEFAULT_START_OF_DATE_TIME_STRING,
  getDateFieldValue,
  getDateFieldValueWithTimezone,
  getDateTime,
  getDateTimeWithTimezone,
} from "utils/due-date-form";
import { Button, DateField } from "components/common/forms";
import { SendOut360Request } from "types/feedback-request";
import { ConfirmModal } from "components/common/modals";
import { FieldErrors } from "types/field-errors.type";
import { toastError } from "utils/toast";
import { sendOut360Schema } from "schemas";
import { ValidationUtil } from "utils";
import { getCurrentBiannualDateRangeValue } from "utils/date";
import Joi from "joi";
import { DEFAULT_DATE_FIELD_FORMAT } from "constants/date";

type SendOut360FormProps = {
  selectedForm?: FormWithQuestion;
  onSubmit: (data: SendOut360Request) => Promise<void>;
  isSubmitting?: boolean;
  setValidationError: (validationError: object) => void;
  validationError: FieldErrors<SendOut360Request>;
};

const SendOut360Form: React.FC<SendOut360FormProps> = ({
  selectedForm,
  onSubmit,
  isSubmitting,
  setValidationError,
  validationError,
}) => {
  const [showConfirmSendModal, setShowConfirmSendModal] = useState(false);

  const [default360StartDate, default360EndDate] =
    getCurrentBiannualDateRangeValue();

  const [formValues, setFormValues] = useState<Partial<SendOut360Request>>({
    startDateOfPeriod: default360StartDate,
    endDateOfPeriod: default360EndDate,
  });

  useEffect(() => {
    setFormValues({
      formId: selectedForm?.id,
      startDateOfPeriod: selectedForm?.startDateOfPeriod || default360StartDate,
      endDateOfPeriod: selectedForm?.endDateOfPeriod || default360EndDate,
      dueDate: selectedForm?.dueDate,
      publishDate: selectedForm?.publishDate,
    });
  }, [selectedForm]);

  const handleSubmit = async () => {
    const { error, value: validData } = validateForm();

    if (!error) {
      await onSubmit(validData);
    }

    handleCloseConfirmModal();
  };

  const validateForm = (): Joi.ValidationResult<SendOut360Request> => {
    const dueDateChanged =
      selectedForm?.dueDate?.getTime() !== formValues.dueDate?.getTime();

    const { error, value } = sendOut360Schema.validate(formValues, {
      context: { dueDateChanged: dueDateChanged },
    });

    if (error) {
      const newValidationError = ValidationUtil.transformValidationError(error);
      setValidationError(newValidationError);
      toastError(error.message);
    }
    return { error, value };
  };

  const handleShowConfirmSendModal = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { error } = validateForm();
    if (!error) {
      setShowConfirmSendModal(true);
    }
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmSendModal(false);
  };

  const handleClearErrorValidation = () => {
    setValidationError({});
  };

  const onDueDateChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const dueDateJs = getDateTimeWithTimezone(e.target.value);
    const publishDateStr = dueDateJs
      .clone()
      .add(5, "days")
      .format(DEFAULT_DATE_FIELD_FORMAT);
    const publishDate = getPublishDate(publishDateStr);
    const dueDate = dueDateJs.toDate();

    setFormValues({
      ...formValues,
      dueDate,
      publishDate,
    });
  };

  const onDateChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: getDateTime(e.target.value),
    });
  };

  const getPublishDate = (dateStr: string) => {
    const publishDate = getDateTimeWithTimezone(
      dateStr,
      DEFAULT_START_OF_DATE_TIME_STRING,
    );
    return publishDate.toDate();
  };

  const onPublishDateChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const publishDate = getPublishDate(e.target.value);

    setFormValues({
      ...formValues,
      publishDate,
    });
  };

  return (
    <form
      className="flex-1 flex flex-col gap-8"
      onSubmit={handleShowConfirmSendModal}
    >
      <div className="flex flex-col gap-8">
        <div className="space-y-2">
          <h2 className="font-semibold space-y-2">Feedback Cycle Period</h2>
          <div className="flex gap-4">
            <DateField
              id="form-start-date"
              label="Start date"
              name="startDateOfPeriod"
              className="mb-0"
              value={
                formValues.startDateOfPeriod
                  ? getDateFieldValue(formValues.startDateOfPeriod)
                  : ""
              }
              onChange={onDateChange}
              required
              disabled={!!selectedForm?.startDateOfPeriod}
            />
            <DateField
              id="form-end-date"
              label="End date"
              name="endDateOfPeriod"
              className="mb-0"
              value={
                formValues.endDateOfPeriod
                  ? getDateFieldValue(formValues.endDateOfPeriod)
                  : ""
              }
              onChange={onDateChange}
              required
              disabled={!!selectedForm?.endDateOfPeriod}
            />
          </div>
        </div>
      </div>

      <DateField
        id={"form-due-date"}
        label={"Deadline"}
        hint={"Give this survey a deadline"}
        value={
          formValues.dueDate
            ? getDateFieldValueWithTimezone(formValues.dueDate)
            : ""
        }
        onChange={onDueDateChange}
        name={"dueDate"}
        className="mb-0"
        errorMessage={validationError?.dueDate}
        onClearError={handleClearErrorValidation}
      />

      <DateField
        id={"form-publish-date"}
        label={"Publish Date"}
        hint={"Give this survey a publish date"}
        value={
          formValues.publishDate
            ? getDateFieldValueWithTimezone(formValues.publishDate)
            : ""
        }
        onChange={onPublishDateChange}
        name={"publishDate"}
        className="mb-0"
        errorMessage={validationError?.publishDate}
        onClearError={handleClearErrorValidation}
      />

      <Button
        label="Send to All"
        className="w-full"
        type="submit"
        size="large"
      />

      <ConfirmModal
        open={showConfirmSendModal}
        title="Send out form"
        content="Are you sure you want to send this form to users?"
        yesButton="Yes"
        noButton="No"
        onYes={handleSubmit}
        onNo={handleCloseConfirmModal}
        yesButtonLoading={isSubmitting}
      />
    </form>
  );
};

export default SendOut360Form;

import { BackButton } from "components/common/forms";
import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import { FeedbackIcon } from "components/common/ui";
import { FeedbackIProvidedItem } from "components/feedback";
import { FORM_TYPE_MAP } from "constants/form-type-map";
import {
  useError,
  useHttpQueryService,
  useLoading,
  useNumericParam,
} from "hooks";
import Markdown from "react-markdown";
import { FeedbackService, FormService } from "services";
import { getDisplayDate } from "utils/date";

const FeedbackIProvided: React.FC = () => {
  const formId = useNumericParam("id");

  const {
    result: form,
    error: formFetchError,
    isLoading: isFormLoading,
  } = useHttpQueryService({
    request: () => FormService.getFormViewById(formId),
    requestOption: {
      enabled: !!formId,
    },
  });

  const {
    result: feedbacks,
    error: feedbackFetchError,
    isLoading: isFeedbackLoading,
  } = useHttpQueryService({
    request: () => FeedbackService.getFeedbackIProvided(formId),
    requestOption: {
      enabled: !!formId,
    },
  });

  const isLoading = useLoading([isFeedbackLoading, isFormLoading]);

  useError(formFetchError, true);

  useError(feedbackFetchError, true);

  return (
    <Layout>
      <LoadingWrapper isLoading={isLoading}>
        {form && (
          <>
            <div className="flex gap-6 items-center mb-6">
              <BackButton className="text-neutral-200" />
              <div className="flex gap-2 items-center">
                <FeedbackIcon type={form.formType} />
                <PageTitle>{form.name}</PageTitle>
              </div>
            </div>
            <div className="my-6 flex flex-col gap-4">
              <div className="flex gap-2 items-center">
                <span className="text-neutral-100 body-14-regular">
                  {FORM_TYPE_MAP[form.formType]} - Deadline:{" "}
                  {form.dueDate && getDisplayDate(form.dueDate)}
                </span>
              </div>
              <Markdown className="body-16-regular text-neutral-150">
                {form?.description}
              </Markdown>
            </div>
            <div className="flex flex-col gap-4">
              {feedbacks?.map((feedback) => {
                return (
                  <FeedbackIProvidedItem
                    key={feedback.id}
                    form={form}
                    feedback={feedback}
                  />
                );
              })}
            </div>
          </>
        )}
      </LoadingWrapper>
    </Layout>
  );
};

export default FeedbackIProvided;

import { UserApi } from "apis";
import { UserStatusType } from "enums/user";
import { User } from "types/user";

const UserService = {
  getByToken: async () => {
    const result = await UserApi.getByToken();
    return result;
  },

  search: async (
    searchText: string,
    userStatus?: UserStatusType,
  ): Promise<User[]> => {
    const users = await UserApi.search(searchText, userStatus);
    return users;
  },

  getUserProfileById: async (id: number) => {
    const result = await UserApi.getUserProfileById(id);
    return result;
  },
};

export default UserService;

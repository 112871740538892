import cx from "classnames";
import { AnonymousFeedbackAvatarIcon } from "components/icons";
import {
  Avatar,
  AvatarFallback,
  AvatarFallbackProps,
  AvatarImage,
  AvatarProps,
} from "components/ui/avatar";
import { UserStatusType } from "enums/user";
import { FC } from "react";
import { ANONYMOUS_DISPLAY_NAME } from "utils/user.util";

interface UserAvatarProps {
  avatarUrl?: string;
  name: string;
  className?: AvatarProps["className"];
  fallbackClassName?: AvatarFallbackProps["className"];
  status?: UserStatusType;
}
const UserAvatar: FC<UserAvatarProps> = ({
  avatarUrl,
  name,
  className,
  fallbackClassName,
  status = UserStatusType.ACTIVE,
}) => {
  const getAvatarContent = () => {
    if (name === ANONYMOUS_DISPLAY_NAME) return <AnonymousFeedbackAvatarIcon />;
    const nameParts = name.split(" ");
    const initials =
      nameParts.length < 2
        ? "U"
        : `${nameParts[0].charAt(0)}${nameParts[1].charAt(0)}`.toUpperCase();

    return initials;
  };

  return (
    <Avatar
      className={cx(className, {
        "bg-neutral-50": status === UserStatusType.INACTIVE,
      })}
    >
      <AvatarImage src={avatarUrl} />
      <AvatarFallback className={fallbackClassName}>
        {getAvatarContent()}
      </AvatarFallback>
    </Avatar>
  );
};

export default UserAvatar;

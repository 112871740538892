import Joi from "joi";
import { FormType, QuestionType } from "enums/form";
import {
  MAXIMUM_FORM_TITLE,
  MAXIMUM_QUESTION_HINT,
  MAXIMUM_QUESTION_LABEL,
  MAXIMUM_SLACK_TEXT_FIELD,
} from "constants/form-fields.constant";

const createAnswerOptionSchema = Joi.object({
  label: Joi.string().trim().max(255).required().messages({
    "string.base": "Option label must be a string",
    "string.max": "Option label must not exceed 255 characters",
    "string.empty": "Option label is required",
  }),
  sequence: Joi.number().min(0).required().messages({
    "number.base": "Option sequence must be a number",
    "number.min": "Option sequence must be greater than or equal to 0",
    "any.required": "Option sequence is required",
  }),
});

const createQuestionSchema = Joi.object({
  label: Joi.string()
    .max(MAXIMUM_QUESTION_LABEL)
    .trim()
    .required()
    .messages({
      "string.base": "Question label must be a string",
      "string.max": `Question name must not exceed ${MAXIMUM_QUESTION_LABEL} characters`,
      "string.empty": "Question label is required",
    }),
  hint: Joi.string()
    .max(MAXIMUM_QUESTION_HINT)
    .trim()
    .allow("")
    .required()
    .messages({
      "string.base": "Question hint must be a string",
      "string.max": `Question helper text must not exceed ${MAXIMUM_QUESTION_HINT} characters`,
      "any.required": "Question hint is required",
    }),
  sequence: Joi.number().min(0).required().messages({
    "number.base": "Question sequence must be a number",
    "number.min": "Question sequence must be greater than or equal to 0",
    "any.required": "Question sequence is required",
  }),
  questionType: Joi.string()
    .valid(...Object.values(QuestionType))
    .required()
    .messages({
      "any.only": "Invalid question type",
      "any.required": "Question type is required",
    }),
  answerOptions: Joi.alternatives().conditional("questionType", {
    is: QuestionType.TEXT,
    then: Joi.forbidden().messages({
      "any.unknown": "Answer options are not allowed for text questions",
    }),
    otherwise: Joi.array()
      .items(createAnswerOptionSchema)
      .min(1)
      .required()
      .messages({
        "array.base": "Answer options must be an array",
        "array.min": "At least one answer option is required",
        "any.required": "Answer options are required",
      }),
  }),
});

export const createFormSchema = Joi.object({
  name: Joi.string()
    .trim()
    .max(MAXIMUM_FORM_TITLE)
    .required()
    .messages({
      "string.base": "Form name must be a string",
      "string.max": `Form name must not exceed ${MAXIMUM_FORM_TITLE} characters`,
      "string.empty": "Form name is required",
    }),
  description: Joi.string()
    .trim()
    .allow("")
    .max(MAXIMUM_SLACK_TEXT_FIELD)
    .required()
    .messages({
      "string.base": "Form description must be a string",
      "string.max": `Form description must not exceed ${MAXIMUM_SLACK_TEXT_FIELD} characters`,
      "any.required": "Form description is required",
    }),
  formType: Joi.string()
    .valid(...Object.values(FormType))
    .required()
    .messages({
      "any.only": "Invalid form type",
      "any.required": "Form type is required",
    }),
  questions: Joi.array()
    .items(createQuestionSchema)
    .min(1)
    .required()
    .messages({
      "array.base": "Questions must be an array",
      "array.min": "At least one question is required",
      "any.required": "Questions are required",
    }),
});

export enum UserRole {
  Admin = "admin",
  SuperAdmin = "super_admin",
  Employee = "employee",
  Assistant = "assistant",
}
export enum UserStatusType {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

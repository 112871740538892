import SearchField from "components/common/forms/SearchField";
import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import LoadingIndicator from "components/common/layouts/LoadingIndicator";
import {
  EMPTY_LIST_IMG,
  NO_SEARCH_RESULT_IMG,
} from "constants/images.constants";
import {
  EMPTY_PEER_FEEDBACK_LIST_TEXT,
  NO_SEARCH_RESULT_TEXT,
} from "constants/messages.constant";
import {
  LIMIT_RECORDS_PER_REQUEST,
  START_PAGE,
  WAIT_SEARCH_TIME,
} from "constants/pagination.constant";
import {
  useError,
  useHttpQueryService,
  useInViewport,
  useLoading,
} from "hooks";
import { useDebounce } from "hooks";
import { ChangeEvent, useEffect, useState } from "react";
import { PeerFeedbackResponse } from "responses";
import { FeedbackRequestService } from "services";
import TablePeerFeedback from "./TablePeerFeedback";

const generatePeerFeedbacksKey = (peerFeedbacks: PeerFeedbackResponse[]) => {
  return peerFeedbacks
    .map((peerFeedback) => `${peerFeedback.formId}-${peerFeedback.revieweeId}`)
    .join("++");
};

// Here we check if the response has already been included in the current data by comparing the key of the current data and the new response data.
// Form id and reviewee id are used to generate the key. These fields are unique for each peer feedback.
// We assume that the BE responses identical data in the same order.
const ifCurrentIncludedNewResponse = (
  currentPeerFeedbacks: PeerFeedbackResponse[],
  newPeerFeedbacks: PeerFeedbackResponse[],
) => {
  const currentPeerFeedbacksKey =
    generatePeerFeedbacksKey(currentPeerFeedbacks);
  const newPeerFeedbacksKey = generatePeerFeedbacksKey(newPeerFeedbacks);

  return currentPeerFeedbacksKey.includes(newPeerFeedbacksKey);
};

const PeerFeedbackList = () => {
  const [page, setPage] = useState<number>(START_PAGE);
  const skip = LIMIT_RECORDS_PER_REQUEST * page;
  const [data, setData] = useState<PeerFeedbackResponse[]>([]);
  const [hasNext, setHasNext] = useState<boolean>(true);
  const [noRecordText, setNoRecordText] = useState<string>(
    EMPTY_PEER_FEEDBACK_LIST_TEXT,
  );
  const [noRecordImg, setNoRecordImg] = useState<string>(EMPTY_LIST_IMG);
  const [keyword, setKeyword] = useState<string>("");

  const {
    result: dataOfOneFetch,
    error,
    isLoading: isFetching,
    refetch: fetchMore,
  } = useHttpQueryService({
    request: () =>
      FeedbackRequestService.getPeerFeedbackList({
        take: LIMIT_RECORDS_PER_REQUEST,
        skip,
        keyword,
      }),
  });

  useEffect(() => {
    if (page !== undefined && page !== START_PAGE) {
      fetchMore();
    }
  }, [page]);

  useError(error);

  useEffect(() => {
    if (!dataOfOneFetch) return;

    const { data: responseFeedbacks, count } = dataOfOneFetch;

    // Due to the re-mount of the useEffect in the React 18, the responseFeedbacks is fetched 3 times which is added to the data 3 times.
    // My assumption is that the useHttpQueryService re-mount the useEffect 3 times and fetch the data 3 times. Meanwhile, the data in this state does not re-initialized.
    // This make the response data between local dev and production different on the first render.
    // This is not a production issue but might lead to some confusion in the local dev environment and hard to debug if there is an issue.
    const currentDataIncludesResponse = ifCurrentIncludedNewResponse(
      data,
      responseFeedbacks,
    );
    if (currentDataIncludesResponse) return;

    const newFeedbacksLength = responseFeedbacks.length + data.length;
    const isExceedCount = newFeedbacksLength >= count;
    setHasNext(!isExceedCount);

    const newData = data.concat(responseFeedbacks);
    setData(newData);
  }, [dataOfOneFetch]);

  const { targetRef: setLastElement, isInViewPort } =
    useInViewport<HTMLTableRowElement>();

  useEffect(() => {
    //if the last element is in viewport and not fetching data and still has next data
    //then fetching new data
    //and set last element to null to avoid making many requests
    if (isInViewPort && !isFetching && hasNext) {
      setLastElement(null);
      setPage((page) => page + 1);
    }
  }, [isInViewPort, isFetching, hasNext]);

  const handleChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setKeyword(text);
    setPage(0);
    setData([]);
  };

  const { isDelaying, debouncedValue } = useDebounce(
    keyword,
    fetchMore,
    WAIT_SEARCH_TIME,
  );

  //set text and image for the case no record found based on search keyword
  useEffect(() => {
    const noRecordText = debouncedValue
      ? NO_SEARCH_RESULT_TEXT
      : EMPTY_PEER_FEEDBACK_LIST_TEXT;
    const noRecordImg = debouncedValue ? NO_SEARCH_RESULT_IMG : EMPTY_LIST_IMG;

    setNoRecordText(noRecordText);
    setNoRecordImg(noRecordImg);
  }, [debouncedValue]);

  const isLoading = useLoading([isDelaying, isFetching]);

  return (
    <Layout>
      <LoadingWrapper isLoading={false}>
        <div className="flex flex-col h-full">
          <div className="flex justify-between mb-6">
            <PageTitle>Peer Feedback</PageTitle>
            <SearchField
              value={keyword}
              onChange={handleChangeKeyword}
              placeholder="Search by email"
            />
          </div>
          <TablePeerFeedback
            peerFeedbacks={data}
            lastRowRef={setLastElement}
            noRecordText={noRecordText}
            noRecordImg={noRecordImg}
            isLoading={isLoading}
          />
          {isLoading && (
            <div className="flex justify-center mt-2">
              <LoadingIndicator />
            </div>
          )}
        </div>
      </LoadingWrapper>
    </Layout>
  );
};

export default PeerFeedbackList;

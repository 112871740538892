import Markdown from "react-markdown";

type QuestionHeaderProps = {
  label?: string;
  hint?: string;
  sequence: number;
};

const QuestionHeader: React.FC<QuestionHeaderProps> = ({
  label,
  hint,
  sequence,
}) => {
  return (
    <div className="mb-3">
      {label && (
        <span className="body-16-semibold">{`${sequence + 1}. ${label}`}</span>
      )}
      {hint && (
        <span className="body-14-regular text-neutral-50">
          <Markdown>{hint}</Markdown>
        </span>
      )}
    </div>
  );
};

export default QuestionHeader;

import { CreateIcon, RequestIcon } from "components/icons";
import { PAGE_PATHS } from "constants/page-paths";
import { useError, useHttpQueryService } from "hooks";
import { useMemo, useState } from "react";
import { FormService } from "services";
import { DropdownButton } from "../buttons";
import { DropdownButtonItemType } from "../buttons/DropdownButton";

const NavbarDropdownButtonGroup: React.FC = () => {
  const [openRequestDropdown, setOpenRequestDropdown] =
    useState<boolean>(false);

  const [openCreateDropdown, setCreateRequestDropdown] =
    useState<boolean>(false);

  const { result: formQueryResult, error } = useHttpQueryService({
    request: () => FormService.getRunning360Forms(),
  });

  useError(error, true);

  const handleChangeRequestDropdown = (newState: boolean) => {
    setOpenRequestDropdown(newState);
  };

  const handleChangeCreateDropdown = (newState: boolean) => {
    setCreateRequestDropdown(newState);
  };

  const is360CycleRunning = useMemo(() => {
    if (!formQueryResult || formQueryResult.length === 0) return false;

    const form = formQueryResult[0];

    if (!form.dueDate || form.dueDate < new Date()) return false;

    return true;
  }, [formQueryResult]);

  const requestDropdownItems = useMemo<DropdownButtonItemType[]>(() => {
    const items: DropdownButtonItemType[] = [
      {
        label: "Anytime Feedback",
        link: PAGE_PATHS.REQUEST_ANYTIME_FEEDBACK,
      },
    ];

    if (is360CycleRunning) {
      // Add 360 item to the beginning of the array
      items.unshift({
        label: "360 Feedback",
        link: PAGE_PATHS.REQUEST_ADD_360_REVIEWER,
      });
    }

    return items;
  }, [is360CycleRunning]);

  const createDropdownItems = useMemo<DropdownButtonItemType[]>(() => {
    const items: DropdownButtonItemType[] = [
      {
        label: "Anytime Feedback",
        link: PAGE_PATHS.FEEDBACK_PROACTIVELY_GIVE_ANYTIME,
      },
    ];

    if (is360CycleRunning) {
      // Add 360 item to the beginning of the array
      items.unshift({
        label: "360 Feedback",
        link: PAGE_PATHS.FEEDBACK_PROACTIVELY_GIVE_THREE60,
      });
    }

    return items;
  }, [is360CycleRunning]);

  return (
    <div className="flex gap-3 items-center">
      <DropdownButton
        label="Create"
        Icon={CreateIcon}
        items={createDropdownItems}
        open={openCreateDropdown}
        onChange={handleChangeCreateDropdown}
      />
      <DropdownButton
        Icon={RequestIcon}
        label="Request"
        items={requestDropdownItems}
        open={openRequestDropdown}
        onChange={handleChangeRequestDropdown}
      />
    </div>
  );
};

export default NavbarDropdownButtonGroup;

import { FeedbackApi } from "apis";
import { FeedbackIProvidedResponse } from "responses";
import { AnswersGroupedByQuestion } from "types/answer-with-reviewer.type";
import {
  CreateFeedBackRequest,
  Feedback,
  ProactivelyGive360FeedbackRequest,
} from "types/feedback";
import { ProactivelyGiveAnytimeFeedbackRequest } from "types/proactively-give-anytime-feedback.type";
import { FeedbackUtil } from "utils";

const FeedbackService = {
  create: async (request: CreateFeedBackRequest) => {
    const response = await FeedbackApi.createFeedback(request);
    return response.data;
  },

  createAnytimeFeeback: async (
    request: ProactivelyGiveAnytimeFeedbackRequest,
  ) => {
    const response = await FeedbackApi.createAnytimeFeedback(request);
    return response.data;
  },

  create360Feeback: async (request: ProactivelyGive360FeedbackRequest) => {
    const response = await FeedbackApi.create360Feedback(request);
    return response.data;
  },

  getFeedbackForMe: async (
    formId: number,
  ): Promise<AnswersGroupedByQuestion> => {
    const feedbacks = await FeedbackApi.getFeedbackForMe(formId);
    return FeedbackUtil.groupByQuestion(feedbacks);
  },

  getFeedbackIProvided: async (
    formId: number,
  ): Promise<FeedbackIProvidedResponse[]> => {
    return await FeedbackApi.getFeedbackIProvided(formId);
  },

  get360FeedbackByReviewee: async (
    formId: number,
    revieeeId: number,
  ): Promise<Feedback[]> => {
    const feedbacks = await FeedbackApi.get360FeedbackByRevieweeId(
      formId,
      revieeeId,
    );
    return feedbacks;
  },

  getPeerFeedbackByReviewee: async (
    formId: number,
    revieeeId: number,
  ): Promise<Feedback[]> => {
    const feedbacks = await FeedbackApi.getPeerFeedbackByRevieweeId(
      formId,
      revieeeId,
    );

    return feedbacks;
  },

  getFeedbackDraftByFeedbackRequestId: async (
    feedbackRequesId: number,
  ): Promise<FeedbackIProvidedResponse> => {
    return await FeedbackApi.getFeedbackDraftByFeedbackRequestId(
      feedbackRequesId,
    );
  },

  saveDraft: async (request: CreateFeedBackRequest) => {
    const response = await FeedbackApi.saveDraft(request);
    return response.data;
  },

  getMyRevieweesByFormId: async (formId: number) => {
    return await FeedbackApi.getMyRevieweesByFormId(formId);
  },

  get360Report: async (formId: number) => {
    return await FeedbackApi.get360Report(formId);
  },
};

export default FeedbackService;

import { IconSquareToggleButton } from "components/common/buttons";
import { CollapsibleSidebar } from "components/common/collapses";
import { BackButton, Button } from "components/common/forms";
import { Layout, PageTitle } from "components/common/layouts";
import { PreviewIcon } from "components/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  RequestAnytimeFeedbackFormData,
  RequestAnytimeFeedbackRequest,
} from "types/request-anytime-feedback";
import { DueDateFormUtil, ValidationUtil } from "utils";
import RequestAnytimeFeedbackForm from "./RequestAnytimeFeedbackForm";
import { FeedbackRequestService, UserService } from "services";
import { useAuthStore } from "store";
import { FieldErrors } from "types/field-errors.type";
import { requestAnytimeFeedbackSchema } from "schemas";
import { toastError, toastSuccess } from "utils/toast";
import {
  useError,
  useFormBlockNavigation,
  useHttpMutationService,
} from "hooks";
import { isEqual } from "lodash";
import { useNavigate } from "react-router-dom";
import { ConfirmModal } from "components/common/modals";
import { PreviewAnytimeForm } from "components/preview";
import { AMOUNT_OF_DAY_ADDED_TO_DEADLINE } from "constants/date";
import { TipTapRef } from "components/common/forms/Tiptap/Tiptap";
import { IMultipleChoiceOption } from "types/input.type";
import { UserStatusType } from "enums/user";

const INITIAL_FORM_DATA: RequestAnytimeFeedbackFormData = {
  name: "",
  dueDate: DueDateFormUtil.getDate(AMOUNT_OF_DAY_ADDED_TO_DEADLINE),
  reviewers: [],
  description: "",
};

const RequestAnytimeFeedback: React.FC = () => {
  const [isExpandingPreviewSection, setIsExpandingPreviewSection] =
    useState<boolean>(true);

  const [showConfirmSubmitModal, setShowConfirmSubmitModal] = useState(false);

  const [formData, setFormData] =
    useState<RequestAnytimeFeedbackFormData>(INITIAL_FORM_DATA);

  const [validationError, setValidationError] = useState<
    FieldErrors<RequestAnytimeFeedbackRequest>
  >({});

  const {
    setFormIsDirty,
    showLeaveModal,
    handleCancelLeaveModal,
    handleConfirmLeaveModal,
  } = useFormBlockNavigation();

  const navigate = useNavigate();

  const editorRef = useRef<TipTapRef>(null);

  const { name, description, reviewers, dueDate } = formData;

  const dataToSubmit: RequestAnytimeFeedbackRequest = useMemo(() => {
    const reviewerIds = reviewers.map<number>(({ value }) => +value);
    const data = {
      name,
      description,
      dueDate,
      reviewerIds,
    };

    return data;
  }, [formData]);

  const {
    mutate,
    isLoading: isSubmitting,
    error,
  } = useHttpMutationService({
    request: (payload?: RequestAnytimeFeedbackRequest) =>
      FeedbackRequestService.requestAnytimeFeedback(payload!),
  });

  const currentUser = useAuthStore(({ user }) => user);
  const currentUserId = currentUser!.id;
  const currentUserEmail = currentUser!.email;

  useEffect(() => {
    const formIsDirty = !isEqual(formData, INITIAL_FORM_DATA);
    setFormIsDirty(formIsDirty);
  }, [formData]);

  const validateForm = () => {
    const { error } = requestAnytimeFeedbackSchema.validate(dataToSubmit);

    if (error) {
      const newValidationError =
        ValidationUtil.transformValidationError<RequestAnytimeFeedbackRequest>(
          error,
        );
      setValidationError(newValidationError);
      toastError(error.message);
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    const response = await mutate(dataToSubmit);
    handleCloseConfirmSubmitModal();

    if (response) {
      setFormData(INITIAL_FORM_DATA);
      editorRef.current?.setContent("");
      toastSuccess("Your request for feedback is sent successfully!");
    }
  };

  useError(error);

  const handleCollapseAboutSection = () => {
    setIsExpandingPreviewSection(false);
  };

  const handleClickPreviewIcon = () => {
    setIsExpandingPreviewSection((prev) => !prev);
  };

  const handleChangeFormData = (
    newFormData: RequestAnytimeFeedbackFormData,
  ) => {
    setFormData(newFormData);
  };

  const searchReviewers = useCallback(
    async (searchText: string) => {
      const users = await UserService.search(searchText, UserStatusType.ACTIVE);

      const possibleReviewers = users.filter(({ id }) => id !== currentUserId);

      const userOptions = possibleReviewers.map<IMultipleChoiceOption>(
        (reviewer) => ({
          value: reviewer.id,
          label: `${reviewer.fullName} (${reviewer.email})`,
        }),
      );

      return userOptions;
    },
    [currentUserId],
  );

  const handleClearErrorValidation = () => {
    setValidationError({});
  };

  const handleClickCancelButton = () => {
    navigate(-1);
  };

  const handleShowConfirmSubmitModal = () => {
    if (!validateForm()) return;

    setShowConfirmSubmitModal(true);
  };

  const handleCloseConfirmSubmitModal = () => {
    setShowConfirmSubmitModal(false);
  };

  return (
    <Layout mainClassName="!p-0">
      <div className="h-full flex flex-col">
        <div className="h-full flex justify-between relative">
          <div className="flex-auto px-8 py-8">
            <div className="mb-6 flex gap-6 items-center">
              <BackButton className="text-neutral-200" />
              <PageTitle>Request New Anytime Feedback</PageTitle>
            </div>

            <RequestAnytimeFeedbackForm
              editorRef={editorRef}
              formData={formData}
              onChange={handleChangeFormData}
              revieweeEmail={currentUserEmail}
              searchReviewers={searchReviewers}
              validationError={validationError}
              onClearValidationError={handleClearErrorValidation}
            />
          </div>

          <CollapsibleSidebar
            isExpanding={isExpandingPreviewSection}
            onCollapse={handleCollapseAboutSection}
            title={"Form Preview"}
            className="flex-none"
          >
            <PreviewAnytimeForm name={name} description={description} />
          </CollapsibleSidebar>

          <IconSquareToggleButton
            Icon={PreviewIcon}
            className="absolute top-8 right-8"
            onClick={handleClickPreviewIcon}
            state={isExpandingPreviewSection ? "on" : "off"}
          />
        </div>

        <div className="sticky bottom-0 mt-auto bg-neutral-0 flex justify-end gap-4 pt-6 pb-8 px-8 border-t border-neutral-20 rounded-b-3xl">
          <Button
            variant="secondary"
            size="medium"
            label="Cancel"
            onClick={handleClickCancelButton}
          />
          <Button
            variant="primary"
            size="medium"
            label="Request Feedback"
            onClick={handleShowConfirmSubmitModal}
          />
        </div>
      </div>
      <ConfirmModal
        open={showLeaveModal}
        title="Leave Page?"
        content="Please be aware that any of your unsaved actions will be discarded. Proceed anyway?"
        yesButton="Confirm"
        noButton="Cancel"
        onYes={handleConfirmLeaveModal}
        onNo={handleCancelLeaveModal}
      />

      <ConfirmModal
        open={showConfirmSubmitModal}
        title="Ready to request?"
        content="Your reviewer(s) will be notified to provide feedback"
        yesButton="Request feedback"
        noButton="Cancel"
        onYes={handleSubmit}
        onNo={handleCloseConfirmSubmitModal}
        yesButtonLoading={isSubmitting}
      />
    </Layout>
  );
};

export default RequestAnytimeFeedback;

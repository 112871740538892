import { AnswersGroupedByQuestion } from "types/answer-with-reviewer.type";
import { Question } from "types/question";
import FeedbackByForm from "./FeedbackByForm";
import { Collapse } from "components/common/collapses";
import UserBriefInfo from "components/user/UserBriefInfo";
import { Feedback } from "types/feedback";

type FeedbackByRevieweeProps = {
  questions: Question[];
  feedbacks: Feedback[];
};

const FeedbackByReviewee: React.FC<FeedbackByRevieweeProps> = ({
  questions,
  feedbacks,
}) => {
  if (feedbacks.length === 0)
    return (
      <p className="mt-4 body-16-regular text-neutral-200">
        There is no feedback for this employee
      </p>
    );

  return (
    <div className="flex flex-col gap-4 mt-4">
      {feedbacks.map((feedback) => {
        const answersGroupedByQuestion = feedback.answers.reduce(
          (accumulator, answer) => {
            const { questionId } = answer;
            accumulator[questionId] = [{ answer }];
            return accumulator;
          },
          {} as AnswersGroupedByQuestion,
        );
        return (
          <Collapse
            key={feedback.id}
            header={
              <h3 className="body-16-medium text-neutral-100 flex items-center gap-[0.25rem]">
                <UserBriefInfo
                  name={feedback.reviewer?.fullName || ""}
                  email={feedback.reviewer?.email || ""}
                  avatarUrl={feedback.reviewer?.avatar || ""}
                  className="text-[16px] font-[600] text-turquoise-100"
                  status={feedback.reviewer?.status}
                />
              </h3>
            }
          >
            <div className="p-6 border-t border-neutral-25 flex flex-col gap-4">
              <FeedbackByForm
                questions={questions}
                answersGroupedByQuestion={answersGroupedByQuestion}
                className="px-6 py-4"
              />
            </div>
          </Collapse>
        );
      })}
    </div>
  );
};

export default FeedbackByReviewee;

import { PAGE_PATHS } from "constants/page-paths";
import { matchPath, useLocation } from "react-router-dom";
import { PAGE_PERMISSIONS } from "constants/page-permission";
import { useAuthStore } from "store";

export const useIsAllowedAccess = (): boolean => {
  const user = useAuthStore((state) => state.user);
  const location = useLocation();

  if (!user) {
    return false;
  }

  const matchRoutes = Object.entries(PAGE_PATHS).filter((pathDefinition) => {
    const path = pathDefinition[1]; // value from entry
    return matchPath(path, location.pathname);
  });

  if (matchRoutes.length == 0) {
    console.error("Path is not supported");
    return false;
  }

  const currentRoutePath = matchRoutes[0][1];
  return (
    PAGE_PERMISSIONS[currentRoutePath] &&
    PAGE_PERMISSIONS[currentRoutePath].includes(user.role)
  );
};

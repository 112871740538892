import { BackButton } from "components/common/forms";
import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import { FeedbackIcon } from "components/common/ui";
import FormWithFeedback from "components/feedback/FormWithFeedback";
import { FORM_TYPE_MAP } from "constants/form-type-map";
import { FormType } from "enums/form";
import {
  useError,
  useHttpQueryService,
  useLoading,
  useNumericParam,
} from "hooks";
import { isEmpty } from "lodash";
import { FeedbackService, FormService } from "services";
import { getDisplayDate } from "utils/date";
import Markdown from "react-markdown";

const FeedbackForMe: React.FC = () => {
  const formId = useNumericParam("id");

  const {
    result: form,
    error: formFetchError,
    isLoading: isFormLoading,
  } = useHttpQueryService({
    request: () => FormService.getFormViewById(formId),
    requestOption: {
      enabled: !!formId,
    },
  });

  const {
    result: answersGroupedByQuestion,
    error: feedbackFetchError,
    isLoading: isFeedbackLoading,
  } = useHttpQueryService({
    request: () => FeedbackService.getFeedbackForMe(formId),
    requestOption: {
      enabled: !!formId,
    },
  });

  const isLoading = useLoading([isFeedbackLoading, isFormLoading]);

  useError(formFetchError, true);

  useError(feedbackFetchError, true);

  return (
    <Layout>
      <LoadingWrapper isLoading={isLoading}>
        {form && answersGroupedByQuestion && (
          <div>
            <div className="flex gap-6 items-center mb-6">
              <BackButton className="text-neutral-200" />
              <div className="flex gap-2 items-center">
                <FeedbackIcon type={form.formType} />
                <PageTitle>{form.name}</PageTitle>
              </div>
            </div>
            {isEmpty(answersGroupedByQuestion) ? (
              <p className="mt-2">There is no feedback for you</p>
            ) : (
              <div>
                <div className="my-6 flex flex-col gap-4">
                  <div className="flex gap-2 items-center">
                    <div>
                      <span className="text-neutral-100 body-16-medium">
                        Type:&nbsp;
                      </span>
                      <span className="text-neutral-150 body-16-regular">
                        {FORM_TYPE_MAP[form.formType]}
                      </span>
                    </div>
                    {form.dueDate && form.formType !== FormType.ANYTIME && (
                      <>
                        <span className="text-neutral-100 body-14-regular">
                          |
                        </span>
                        <div>
                          <span className="text-neutral-100 body-16-medium">
                            Deadline:&nbsp;
                          </span>
                          <span className="text-neutral-150 body-16-regular">
                            {getDisplayDate(form.dueDate)}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  {form.description && form.formType == FormType.ANYTIME && (
                    <Markdown className="body-16-regular text-neutral-150">
                      {form.description}
                    </Markdown>
                  )}
                </div>
                <FormWithFeedback
                  form={form}
                  answersGroupedByQuestion={answersGroupedByQuestion}
                />
              </div>
            )}
          </div>
        )}
      </LoadingWrapper>
    </Layout>
  );
};

export default FeedbackForMe;

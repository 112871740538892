import { useMemo } from "react";
import { OptionQuestion } from "types/question";
import { CheckboxList } from "components/common/forms";
import { IMultipleChoiceOption } from "types/input.type";

interface IPreviewCheckboxQuestionProps {
  question: OptionQuestion;
}

const PreviewCheckboxQuestion: React.FC<IPreviewCheckboxQuestionProps> = ({
  question,
}) => {
  const { label: questionLabel, hint, answerOptions } = question;
  const id = `question-${question.id}`;
  let label: string = question.sequence + 1 + ". ";
  const sortedOptions = useMemo<IMultipleChoiceOption[]>(
    () =>
      [...answerOptions]
        .sort((a, b) => a.sequence - b.sequence)
        .map(({ label, id }) => {
          return { label, value: String(id), isDisabled: true };
        }),
    [answerOptions],
  );

  if (!questionLabel) {
    label += "...";
    return (
      <p className="mb-2">
        <label htmlFor={id} className="body-16-semibold text-neutral-200">
          {label}
        </label>
      </p>
    );
  } else {
    label += questionLabel;
  }

  return <CheckboxList hint={hint} label={label} options={sortedOptions} />;
};

export default PreviewCheckboxQuestion;

import {
  CustomizedTabs,
  Layout,
  LoadingWrapper,
  PageTitle,
} from "components/common/layouts";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { FormType } from "enums/form";
import ToDo360FeedbackRequests from "./ToDo360FeedbackRequests";
import ToDoPeerFeedbackRequests from "./ToDoPeerFeedbackRequests";
import ToDoAnytimeFeedbackRequests from "./ToDoAnytimeFeedbackRequests";
import { useFeedbackRequestStore } from "store";
import { useError, useHttpQueryService } from "hooks";
import { FeedbackRequestService } from "services";
import { Badge } from "components/common";
import { getBadgeNumberForTodoRequest } from "utils/feedback-request.util";
import { MAXIMUM_NUMBER_OF_TODO_REQUEST_TO_DISPLAY } from "constants/feedback-request.constant";

const ToDoFeedbackRequests = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [titles, setTitles] = useState<ReactNode[]>([<></>, <></>, <></>]);
  const setTotalsOfTodoRequest = useFeedbackRequestStore(
    (state) => state.setTotalsOfTodoRequest,
  );

  // Fetch totals of to do requests
  const {
    result: totals,
    isLoading,
    error,
  } = useHttpQueryService({
    request: async () => FeedbackRequestService.getTotalsOfTodoRequest(),
  });

  useError(error, true);

  const panels: React.ReactNode[] = useMemo(
    () => [
      <ToDo360FeedbackRequests key={FormType.THREE_HUNDRED_SIXTY} />,
      <ToDoPeerFeedbackRequests key={FormType.PEER} />,
      <ToDoAnytimeFeedbackRequests key={FormType.ANYTIME} />,
    ],
    [],
  );

  useEffect(() => {
    setTotalsOfTodoRequest(totals ?? []);
  }, [totals]);

  const getBadge = (total: number | undefined) => {
    if (total) {
      const label = getBadgeNumberForTodoRequest(total);
      if (label) {
        const className = `ml-2 block text-center m-auto h-fit ${total > MAXIMUM_NUMBER_OF_TODO_REQUEST_TO_DISPLAY ? "py-0.5 w-[25px]" : " p-0 w-[16px]"}`;
        return (
          <Badge
            label={label}
            variant="error"
            size="small"
            className={className}
          />
        );
      }
    }
    return null;
  };

  useEffect(() => {
    if (totals) {
      setTitles([
        <span key={FormType.THREE_HUNDRED_SIXTY} className="flex">
          360 Feedback
          {getBadge(
            totals.find((n) => n.formType === FormType.THREE_HUNDRED_SIXTY)
              ?.total,
          )}
        </span>,
        <span key={FormType.PEER} className="flex">
          Peer Feedback
          {getBadge(totals.find((n) => n.formType === FormType.PEER)?.total)}
        </span>,
        <span key={FormType.ANYTIME} className="flex">
          Anytime Feedback
          {getBadge(totals.find((n) => n.formType === FormType.ANYTIME)?.total)}
        </span>,
      ]);
    }
  }, [totals]);
  return (
    <Layout mainClassName="flex flex-col">
      <PageTitle className="mb-6">Your todo</PageTitle>
      <LoadingWrapper isLoading={isLoading}>
        <CustomizedTabs
          titles={titles}
          panels={panels}
          tabIndex={tabIndex}
          onSelect={setTabIndex}
        />
      </LoadingWrapper>
    </Layout>
  );
};

export default ToDoFeedbackRequests;

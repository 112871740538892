import { BackButton, Button } from "components/common/forms";
import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import ProactivelyGive360FeedbackForm from "./ProactivelyGive360FeedbackForm";
import { ConfirmModal } from "components/common/modals";
import { FeedbackIcon } from "components/common/ui";
import { FORM_TYPE_MAP } from "constants/form-type-map";
import { getDisplayDate } from "utils/date";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldErrors } from "types/field-errors.type";
import {
  FeedbackRequestService,
  FeedbackService,
  FormService,
  UserService,
} from "services";
import { ValidationUtil } from "utils";
import { useAuthStore } from "store";
import { useNavigate } from "react-router-dom";
import {
  useError,
  useFormBlockNavigation,
  useHttpMutationService,
  useHttpQueryService,
  useLoading,
} from "hooks";
import { FormWithQuestion } from "types/form";
import {
  ProactivelyGive360FeedbackFormData,
  ProactivelyGive360FeedbackRequest,
} from "types/feedback";
import { PAGE_PATHS } from "constants/page-paths";
import { toastError, toastSuccess } from "utils/toast";
import { proactivelyGive360FeedbackSchema } from "schemas/proactively-give-360-feedback.schema";
import { isEqual } from "lodash";
import { IMultipleChoiceOption } from "types/input.type";
import { UserStatusType } from "enums/user";

const INITIAL_FORM_DATA: ProactivelyGive360FeedbackFormData = {
  reviewee: undefined,
  answers: [],
};

const ProactivelyGive360Feedback = () => {
  const navigate = useNavigate();

  const [showConfirmSubmitModal, setShowConfirmSubmitModal] =
    useState<boolean>(false);
  const [formWithQuestion, setFormWithQuestion] = useState<FormWithQuestion>();
  const [formData, setFormData] =
    useState<ProactivelyGive360FeedbackFormData>(INITIAL_FORM_DATA);
  const [validationError, setValidationError] = useState<
    FieldErrors<ProactivelyGive360FeedbackRequest>
  >({});

  const { reviewee, answers } = formData;
  const selectedFormId = formWithQuestion?.id;
  const currentUserId = useAuthStore(({ user }) => user?.id);

  const {
    setFormIsDirty,
    showLeaveModal,
    handleCancelLeaveModal,
    handleConfirmLeaveModal,
  } = useFormBlockNavigation();

  const {
    result: formResult,
    error: formFetchError,
    isLoading: isFormLoading,
  } = useHttpQueryService({
    request: () => FormService.getRunning360Forms(),
  });

  const {
    result: submitResult,
    mutate: submit360Feedback,
    error: submitError,
    isLoading: isSubmitting,
  } = useHttpMutationService({
    request: (payload?: ProactivelyGive360FeedbackRequest) =>
      FeedbackService.create360Feeback(payload!),
  });

  const {
    result: revieweesSubmitted,
    isLoading: isLoadingRevieweesSubmitted,
    error: fetchSubmittedError,
    refetch: refetchRevieweesSubmitted,
  } = useHttpQueryService({
    request: async () =>
      FeedbackService.getMyRevieweesByFormId(selectedFormId!),
    requestOption: { enabled: !!selectedFormId },
  });

  const {
    result: revieweesRequested,
    isLoading: isLoadingRevieweesRequested,
    error: fetchRequestedError,
    refetch: refetchRevieweesRequested,
  } = useHttpQueryService({
    request: async () =>
      FeedbackRequestService.getMyRevieweesByFormId(selectedFormId!),
    requestOption: { enabled: !!selectedFormId },
  });

  const isLoading = useLoading([
    isFormLoading,
    isLoadingRevieweesRequested,
    isLoadingRevieweesSubmitted,
    isSubmitting,
  ]);

  const dataToSubmit: ProactivelyGive360FeedbackRequest = useMemo(() => {
    const data: ProactivelyGive360FeedbackRequest = {
      answers,
      formId: selectedFormId!,
    };

    if (reviewee) {
      data.revieweeId = +reviewee.value;
    }

    return data;
  }, [formData]);

  const handleChangeFormData = (
    newFormData: ProactivelyGive360FeedbackFormData,
  ) => {
    setFormData(newFormData);
  };

  const validateForm = () => {
    const { error } = proactivelyGive360FeedbackSchema.validate(dataToSubmit);

    if (error) {
      const newValidationError =
        ValidationUtil.transformValidationError<ProactivelyGive360FeedbackRequest>(
          error,
        );
      setValidationError(newValidationError);
      toastError(error.message);
      return false;
    }

    const noOfQuestions = formWithQuestion!.questions.length;
    const noOfAnswersByQuestionId = new Set(
      answers.map((answer) => answer.questionId),
    ).size;

    if (noOfQuestions != noOfAnswersByQuestionId) {
      toastError("You must complete all questions.");
      return false;
    }

    return true;
  };

  const handleClickCancelButton = () => {
    navigate(-1);
  };

  const handleShowConfirmSubmitModal = () => {
    if (!validateForm()) return;

    setShowConfirmSubmitModal(true);
  };

  const handleSubmit = async () => {
    const payload = dataToSubmit;

    const response = await submit360Feedback(payload);
    handleCloseConfirmSubmitModal();
    if (response) {
      handleCloseConfirmSubmitModal();
      toastSuccess(`Feedback has been submitted for ${reviewee?.label}`);
    }

    setFormData(INITIAL_FORM_DATA);
  };

  const handleCloseConfirmSubmitModal = () => {
    setShowConfirmSubmitModal(false);
  };

  const handleClearErrorValidation = () => {
    setValidationError({});
  };

  const searchReviewee = useCallback(
    async (searchText: string) => {
      const users = await UserService.search(searchText, UserStatusType.ACTIVE);

      const possibleReviewers = users.filter(({ id }) => {
        const alreadyRequestedReviewer = revieweesRequested?.find(
          (reviewer) => id === reviewer.id,
        );
        const alreadySubmittedReviewer = revieweesSubmitted?.find(
          (reviewer) => id === reviewer.id,
        );

        if (
          id === currentUserId ||
          alreadyRequestedReviewer ||
          alreadySubmittedReviewer
        )
          return false;
        return true;
      });

      const userOptions = possibleReviewers.map<IMultipleChoiceOption>(
        (reviewer) => ({
          value: reviewer.id,
          label: `${reviewer.fullName} (${reviewer.email})`,
        }),
      );

      return userOptions;
    },
    [currentUserId, revieweesRequested, revieweesSubmitted],
  );

  useEffect(() => {
    if (formFetchError || fetchRequestedError || fetchSubmittedError) {
      return navigate(PAGE_PATHS.PAGE_NOT_FOUND, { replace: true });
    }
  }, [formFetchError, fetchRequestedError, fetchSubmittedError]);

  useEffect(() => {
    if (answers.length === 0) {
      setFormIsDirty(false);
    }

    const answersIsDirty = answers.some(
      ({ answerOptionId, answerText }) => !!answerOptionId || !!answerText,
    );

    setFormIsDirty(answersIsDirty);
  }, [answers]);

  useEffect(() => {
    if (formResult) {
      setFormWithQuestion(formResult[0]);
    }
  }, [formResult]);

  useEffect(() => {
    const formIsDirty = !isEqual(formData, INITIAL_FORM_DATA);
    setFormIsDirty(formIsDirty);
  }, [formData]);

  useEffect(() => {
    if (submitResult) {
      refetchRevieweesSubmitted();
      refetchRevieweesRequested();
    }
  }, [submitResult]);

  useError(submitError);

  const confirmationMsg = formWithQuestion?.dueDate
    ? `This action cannot be reverted. Your feedback will be locked and shared to the reviewee on ${getDisplayDate(
        formWithQuestion.dueDate,
      )}.`
    : "Your feedback will be released immediately.";

  return (
    <Layout mainClassName="!p-0">
      <LoadingWrapper isLoading={isLoading}>
        <div className="h-full flex flex-col">
          {formWithQuestion && (
            <div className="h-full flex justify-between relative">
              <div className="flex-auto px-8 py-8">
                <div className="mb-6 flex gap-6 items-center">
                  <BackButton className="text-neutral-200" />
                  <FeedbackIcon type={formWithQuestion.formType!} />
                  <PageTitle>
                    {formWithQuestion?.name}
                    {reviewee && (
                      <span>
                        &nbsp;for{" "}
                        <span className="text-turquoise-75">
                          {reviewee?.label}
                        </span>
                      </span>
                    )}
                  </PageTitle>
                </div>

                <div className="my-6 flex flex-col gap-4">
                  <div className="flex gap-2 items-center">
                    <div>
                      <span className="text-neutral-100 body-16-medium">
                        Type:&nbsp;
                      </span>
                      <span className="text-neutral-150 body-16-regular">
                        {FORM_TYPE_MAP[formWithQuestion.formType]}
                      </span>
                    </div>
                    <span className="text-neutral-100 body-14-regular">|</span>
                    <div>
                      <span className="text-neutral-100 body-16-medium">
                        Deadline:&nbsp;
                      </span>
                      <span className="text-neutral-150 body-16-regular">
                        {getDisplayDate(formWithQuestion.dueDate!)}
                      </span>
                    </div>
                  </div>
                </div>

                <ProactivelyGive360FeedbackForm
                  form={formWithQuestion!}
                  formData={formData}
                  onChange={handleChangeFormData}
                  searchReviewee={searchReviewee}
                  onClearValidationError={handleClearErrorValidation}
                  validationError={validationError}
                />
              </div>
            </div>
          )}

          <div className="sticky bottom-0 mt-auto bg-neutral-0 flex justify-end gap-4 pt-6 pb-8 px-8 border-t border-neutral-20 rounded-b-3xl">
            <Button
              variant="secondary"
              size="medium"
              label="Cancel"
              onClick={handleClickCancelButton}
            />
            <Button
              variant="primary"
              size="medium"
              label="Share your feedback"
              onClick={handleShowConfirmSubmitModal}
            />
          </div>
        </div>

        <ConfirmModal
          open={showLeaveModal}
          title="Leave Page?"
          content="Please be aware that any of your unsaved actions will be discarded. Proceed anyway?"
          yesButton="Confirm"
          noButton="Cancel"
          onYes={handleConfirmLeaveModal}
          onNo={handleCancelLeaveModal}
        />

        <ConfirmModal
          open={showConfirmSubmitModal}
          title="Share your feedback?"
          content={confirmationMsg}
          yesButton="Share Feedback"
          noButton="Cancel"
          onYes={handleSubmit}
          onNo={handleCloseConfirmSubmitModal}
          yesButtonLoading={isSubmitting}
        />
      </LoadingWrapper>
    </Layout>
  );
};

export default ProactivelyGive360Feedback;

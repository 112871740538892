import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import { FormFeedbackItem } from "components/form-feedback";
import { useError, useHttpQueryService } from "hooks";
import { isEmpty } from "lodash";
import { FormService } from "services";
import { useAuthStore } from "store";
import { transformToUserProfile } from "utils/user.util";

const FeedbackIProvidedList: React.FC = () => {
  const user = useAuthStore((state) => state.user);
  if (!user) return null;

  const {
    result: formFeedback,
    error,
    isLoading,
  } = useHttpQueryService({
    request: () => FormService.getFormFeedbackIProvided(),
  });

  useError(error);

  const reviewers = [transformToUserProfile(user, true)];

  const data = formFeedback?.map(({ reviewees, ...form }) => {
    const newReviewees = reviewees.map((reviewee) =>
      transformToUserProfile(reviewee),
    );

    return {
      form,
      reviewees: newReviewees,
    };
  });

  return (
    <Layout>
      <LoadingWrapper isLoading={isLoading}>
        <PageTitle className="mb-6">Feedback you provided</PageTitle>
        {isEmpty(formFeedback) ? (
          <p>{"You haven't provided any feedback."}</p>
        ) : (
          <div className={"flex flex-col gap-4"}>
            {data?.map(({ reviewees, form }) => {
              return (
                <FormFeedbackItem
                  key={form.id}
                  form={form}
                  reviewees={reviewees}
                  reviewers={reviewers}
                />
              );
            })}
          </div>
        )}
      </LoadingWrapper>
    </Layout>
  );
};

export default FeedbackIProvidedList;

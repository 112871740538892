import { WarningIcon } from "components/icons";
import cx from "classnames";

type InfoMessageBlockFor360Props = {
  className?: string;
};

const InfoMessageBlockFor360: React.FC<InfoMessageBlockFor360Props> = ({
  className,
}) => {
  return (
    <div
      className={cx(
        className,
        "flex flex-col px-8 py-6 border border-yellow-100 rounded-2xl bg-yellow-10",
      )}
    >
      <div className="flex gap-2 mb-[8px]">
        <WarningIcon />
        <p className="text-neutral-150 body-16-semibold">
          Important note on submitting 360 form
        </p>
      </div>
      <p className="text-neutral-150 body-16-regular">
        Please be aware that saved draft versions of feedback will not be
        automatically submitted at the end of the 360 cycle. If you want your
        feedback to be shared, please make sure to submit it before the cycle
        ends.
      </p>
    </div>
  );
};
export default InfoMessageBlockFor360;

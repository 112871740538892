import { LoadingWrapper } from "components/common/layouts";
import { FormType } from "enums/form";
import { useError, useHttpQueryService } from "hooks";
import { FeedbackRequestService } from "services";
import ListToDoFeedbackRequests from "components/feedback-request/ToDoList";
import { InfoMessageBlockFor360 } from "components/common/ui";

const ToDo360FeedbackRequests = () => {
  const {
    result: feedbackRequests,
    error,
    isLoading,
  } = useHttpQueryService({
    request: () =>
      FeedbackRequestService.getByFormType(FormType.THREE_HUNDRED_SIXTY),
  });

  useError(error, true);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <div className="flex flex-col gap-4 h-full">
        {feedbackRequests && feedbackRequests.length > 0 && (
          <InfoMessageBlockFor360 />
        )}
        <ListToDoFeedbackRequests feedbackRequests={feedbackRequests ?? []} />
      </div>
    </LoadingWrapper>
  );
};

export default ToDo360FeedbackRequests;

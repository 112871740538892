import dayjs from "dayjs";

export const getDisplayDate = (date: Date): string => {
  return date.toLocaleDateString(undefined, {
    dateStyle: "medium",
  });
};

export const getCurrentBiannualDateRangeValue = () => {
  const today = dayjs();
  const firstHalfStart = dayjs().startOf("year");
  const firstHalfEnd = dayjs().startOf("year").add(5, "months").endOf("month");
  const secondHalfStart = dayjs().startOf("year").add(6, "months");
  const secondHalfEnd = dayjs().endOf("year");

  if (today.isBefore(firstHalfEnd))
    return [firstHalfStart.toDate(), firstHalfEnd.toDate()];
  return [secondHalfStart.toDate(), secondHalfEnd.toDate()];
};

import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import Table360Feedback from "components/form-feedback/Table360FormFeedback";
import { ERROR_GENERATE_REPORT } from "constants/messages.constant";
import { useError, useHttpMutationService, useHttpQueryService } from "hooks";
import { isEmpty } from "lodash";
import { useState } from "react";
import { FeedbackService, FormService } from "services";
import { useAuthStore } from "store";
import { toastError } from "utils/toast";

export type DropdownItemType = {
  label: string;
  isExporting: boolean;
  onAction: (index: number) => void;
};

const Three60FeedbackList = () => {
  const user = useAuthStore((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [formId, setFormId] = useState(-1);

  const createDropdownItems: DropdownItemType[] = [
    {
      label: "Export as Microsoft Excel (.xlsx)",
      isExporting: false,
      onAction: (index: number) => {
        createDropdownItems[index].isExporting = true;
        downloadReport(formId, index);
      },
    },
  ];

  const {
    result: forms,
    error,
    isLoading,
  } = useHttpQueryService({
    request: () => FormService.get360FormFeedback(),
  });

  const { mutate: get360Report, error: fetchReportError } =
    useHttpMutationService({
      request: (formId?: number) => FeedbackService.get360Report(formId!),
    });

  useError(error);

  useError(fetchReportError);

  const downloadReport = async (formId: number, dropdownId: number) => {
    const report = await get360Report(formId);

    // Create a URL for the blob
    if (report) {
      const url = window.URL.createObjectURL(report.blob);

      // Create a temporary anchor element
      const a = document.createElement("a");
      a.href = url;
      a.download = report.filename;

      // Simulate a click on the anchor element to start the download
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } else {
      toastError(ERROR_GENERATE_REPORT);
    }

    resetState(dropdownId);
  };

  const resetState = (dropdownId: number) => {
    createDropdownItems[dropdownId].isExporting = false;
    setIsOpen(false);
    setFormId(-1);
  };

  const handleOpenChange = (formId: number) => {
    setFormId(formId);
    setIsOpen(!isOpen);
  };

  return (
    <Layout>
      <LoadingWrapper isLoading={isLoading}>
        <PageTitle className="mb-6">360 Feedback</PageTitle>
        {isEmpty(forms) ? (
          <p>There is no feedback available</p>
        ) : (
          <Table360Feedback
            items={createDropdownItems}
            isOpen={isOpen}
            onOpenChange={handleOpenChange}
            formId={formId}
            forms={forms!}
            role={user?.role}
          />
        )}
      </LoadingWrapper>
    </Layout>
  );
};

export default Three60FeedbackList;

import { AxiosResponse } from "axios";
import {
  CreateFeedBackRequest,
  Feedback,
  ProactivelyGive360FeedbackRequest,
} from "types/feedback";
import { ProactivelyGiveAnytimeFeedbackRequest } from "types/proactively-give-anytime-feedback.type";
import { ENDPOINT_URL } from "constants/api-url";
import { http } from "utils";
import { FeedbackIProvidedResponse, UserProfileResponse } from "responses";
import { getFileNameFromResponse } from "utils/http.util";

const CONTROLLER_PREFIX = ENDPOINT_URL.FEEDBACK;

const FeedbackApi = {
  createFeedback: async (
    payload: CreateFeedBackRequest,
  ): Promise<AxiosResponse<boolean>> => {
    return http.post(CONTROLLER_PREFIX, payload);
  },

  createAnytimeFeedback: async (
    payload: ProactivelyGiveAnytimeFeedbackRequest,
  ): Promise<AxiosResponse<boolean>> => {
    return http.post(`${CONTROLLER_PREFIX}/anytime`, payload);
  },

  create360Feedback: async (
    payload: ProactivelyGive360FeedbackRequest,
  ): Promise<AxiosResponse<boolean>> => {
    return http.post(`${CONTROLLER_PREFIX}/360`, payload);
  },

  getFeedbackForMe: async (formId: number): Promise<Feedback[]> => {
    const res = await http.get<Feedback[]>(
      `${CONTROLLER_PREFIX}/for-me/${formId}`,
    );
    return res.data;
  },

  getFeedbackIProvided: async (
    formId: number,
  ): Promise<FeedbackIProvidedResponse[]> => {
    const res = await http.get<FeedbackIProvidedResponse[]>(
      `${CONTROLLER_PREFIX}/i-provided/${formId}`,
    );
    return res.data;
  },

  get360FeedbackByRevieweeId: async (
    formId: number,
    revieweeId: number,
  ): Promise<Feedback[]> => {
    const res = await http.get<Feedback[]>(
      `${CONTROLLER_PREFIX}/360/${revieweeId}/${formId}`,
    );
    return res.data;
  },

  getPeerFeedbackByRevieweeId: async (
    formId: number,
    revieweeId: number,
  ): Promise<Feedback[]> => {
    const res = await http.get<Feedback[]>(
      `${CONTROLLER_PREFIX}/peer/${revieweeId}/${formId}`,
    );
    return res.data;
  },

  getFeedbackDraftByFeedbackRequestId: async (
    feedbackRequesId: number,
  ): Promise<FeedbackIProvidedResponse> => {
    const res = await http.get<FeedbackIProvidedResponse>(
      `${CONTROLLER_PREFIX}/draft/${feedbackRequesId}`,
    );
    return res.data;
  },

  saveDraft: async (
    payload: CreateFeedBackRequest,
  ): Promise<AxiosResponse<boolean>> => {
    return http.post(`${CONTROLLER_PREFIX}/draft`, payload);
  },

  getMyRevieweesByFormId: async (
    formId: number,
  ): Promise<UserProfileResponse[]> => {
    const res = await http.get(`${CONTROLLER_PREFIX}/reviewees/${formId}`);

    return res.data;
  },

  get360Report: async (
    formId: number,
  ): Promise<{
    blob: Blob;
    filename: string;
  }> => {
    const res = await http.get(`${CONTROLLER_PREFIX}/report/${formId}`, {
      responseType: "blob",
    });

    const filename = getFileNameFromResponse(res);

    const blob = res.data;

    return { blob, filename };
  },
};

export default FeedbackApi;

import { AsyncDropdown, BackButton } from "components/common/forms";
import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import { FeedbackByReviewee } from "components/feedback";
import {
  useError,
  useHttpQueryService,
  useNumericParam,
  useStateWithPrevious,
} from "hooks";
import { useCallback, useEffect } from "react";
import { SingleValue } from "react-select";
import { FeedbackService, FormService, UserService } from "services";
import { IMultipleChoiceOption } from "types/input.type";
import { User } from "types/user";
import { FormFieldUtil } from "utils";

const Three60Feedback: React.FC = () => {
  const formId = useNumericParam("id");
  const [reviewee, setReviewee, previousReviewee] =
    useStateWithPrevious<IMultipleChoiceOption | null>(null);
  const revieweeId = reviewee?.value;

  const {
    result: form,
    error: formFetchError,
    isLoading: isFormLoading,
  } = useHttpQueryService({
    request: () => FormService.getFormViewById(formId),
    requestOption: {
      enabled: !!formId,
    },
  });

  const {
    result: feedbacks,
    error: feedbackFetchError,
    isLoading: isFeedbackLoading,
    refetch,
  } = useHttpQueryService({
    request: () =>
      FeedbackService.get360FeedbackByReviewee(formId, +revieweeId!),
    requestOption: {
      enabled: !!revieweeId,
    },
  });

  useEffect(() => {
    const previousRevieweeId = previousReviewee?.value;
    if (revieweeId && previousRevieweeId && previousRevieweeId !== revieweeId) {
      refetch();
    }
  }, [revieweeId]);

  useError(formFetchError, true);

  useError(feedbackFetchError, true);

  const handleChangeReviewee = (
    selectedOption: SingleValue<IMultipleChoiceOption>,
  ) => {
    if (!selectedOption) return;

    setReviewee(selectedOption);
  };

  const request = useCallback(async (searchText: string) => {
    const users = await UserService.search(searchText);

    const userOptions = FormFieldUtil.toMultipleChoiceOptions<User>(
      users,
      "id",
      "email",
    );

    return userOptions;
  }, []);

  return (
    <Layout>
      <LoadingWrapper isLoading={isFormLoading}>
        {form && (
          <div>
            <div className="flex gap-6 items-center mb-6">
              <BackButton className="text-neutral-200" />
              <PageTitle>{form.name}</PageTitle>
            </div>
            <AsyncDropdown<false>
              className="w-full max-w-[472px]"
              onChange={handleChangeReviewee}
              value={reviewee}
              request={request}
              id={"reviewee"}
            />

            <LoadingWrapper isLoading={isFeedbackLoading}>
              <FeedbackByReviewee
                questions={form.questions}
                feedbacks={feedbacks || []}
              ></FeedbackByReviewee>
            </LoadingWrapper>
          </div>
        )}
      </LoadingWrapper>
    </Layout>
  );
};

export default Three60Feedback;

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form } from "types/form";
import { toastError, toastSuccess } from "utils/toast";
import { FormService } from "services";
import { Button } from "components/common/forms";
import { ConfirmModal } from "components/common/modals";
import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import { TableForm } from "components/form";
import { FormType } from "enums/form";
import { useError, useHttpQueryService } from "hooks";

const Forms: React.FC = () => {
  const [forms, setForms] = useState<Form[]>([]);
  const [openDeleteFormModal, setOpenDeleteFormModal] =
    useState<boolean>(false);
  const [selectedFormId, setSelectedFormId] = useState<number>(0);

  const {
    result: fetchedForms,
    isLoading,
    error,
  } = useHttpQueryService({
    request: () =>
      FormService.get({
        formType: [FormType.THREE_HUNDRED_SIXTY, FormType.PEER],
      }),
  });

  useEffect(() => {
    if (!fetchedForms) return;

    setForms(fetchedForms);
  }, [fetchedForms]);

  useError(error, true);

  const deleteForm = async (id: number) => {
    if (!id) return;

    try {
      await FormService.delete(id);
      toastSuccess("Delete the form successfully!");
      const newForms = forms.filter((form) => form.id !== id);
      setForms(newForms);
    } catch (error) {
      toastError("Something went wrong when deleting the form!");
    } finally {
      closeDeleteFormModal();
    }
  };
  const handleDeleteForm = (id: number) => {
    if (!id) return;

    setSelectedFormId(id);
    setOpenDeleteFormModal(true);
  };
  const closeDeleteFormModal = () => {
    setOpenDeleteFormModal(false);
    setSelectedFormId(0);
  };

  return (
    <Layout>
      <ConfirmModal
        open={openDeleteFormModal}
        onYes={() => deleteForm(selectedFormId)}
        onNo={closeDeleteFormModal}
        title={"Delete Confirmation"}
        content={"Are you sure you want to delete this form?"}
        yesButton={"Yes"}
        noButton={"No"}
      />
      <LoadingWrapper isLoading={isLoading}>
        <div className="flex justify-between mb-10">
          <PageTitle>Form Management</PageTitle>
          <Link to={"create"}>
            <Button label="Create a new form" />
          </Link>
        </div>
        <TableForm forms={forms} onDeleteForm={handleDeleteForm} />
      </LoadingWrapper>
    </Layout>
  );
};
export default Forms;

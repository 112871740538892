import { IconSquareToggleButton } from "components/common/buttons";
import { CollapsibleSidebar } from "components/common/collapses";
import { PreviewIcon } from "components/icons";
import { PreviewForm } from "components/preview";
import { FormWithQuestion } from "types/form";

type FormPreviewSectionWrapperProps = {
  isExpanding: boolean;
  onCollapse: () => void;
  onChange: () => void;
  form: FormWithQuestion;
};

const FormPreviewSectionWrapper: React.FC<FormPreviewSectionWrapperProps> = ({
  isExpanding,
  onCollapse,
  onChange,
  form,
}) => {
  const { name, questions } = form;
  return (
    <>
      <CollapsibleSidebar
        isExpanding={isExpanding}
        onCollapse={onCollapse}
        title={"Form Preview"}
      >
        <PreviewForm name={name} questions={questions} />
      </CollapsibleSidebar>
      <IconSquareToggleButton
        Icon={PreviewIcon}
        className="absolute top-8 right-8"
        onClick={onChange}
        state={isExpanding ? "on" : "off"}
      />
    </>
  );
};

export default FormPreviewSectionWrapper;

import { useMemo } from "react";
import {
  AnswerOption,
  CreateEditAnswerOptionRequest,
} from "types/answer-option";
import CreateAnswerOption from "./AnswerOption";
import { ArrayUtil } from "utils";
import { Button } from "components/common/forms";
import { FieldErrors } from "types/field-errors.type";

type AnswerOptionsProps = {
  answerOptions: AnswerOption[];
  onChange: (newOptions: AnswerOption[]) => void;
  validationError?: FieldErrors<CreateEditAnswerOptionRequest[]>;
  onClearValidationError?: () => void;
};

const AnswerOptions: React.FC<AnswerOptionsProps> = ({
  answerOptions,
  onChange,
  validationError,
  onClearValidationError,
}) => {
  const handleAddOption = () => {
    const newOption: AnswerOption = {
      id: -Date.now(),
      label: "",
      sequence: answerOptions.length,
    };
    const newOptions: AnswerOption[] = [...answerOptions, newOption];
    onChange(newOptions);
  };

  const handleChangeOption = (id: number, newOption: AnswerOption) => {
    const newOptions = answerOptions.map((option) =>
      option.id === id ? newOption : option,
    );
    onChange(newOptions);
  };

  const handleDeleteOption = (sequence: number) => {
    const newOptions = answerOptions.reduce((res, option) => {
      if (option.sequence === sequence) return res;
      if (option.sequence < sequence) res.push(option);
      else {
        const newOption = {
          ...option,
          sequence: option.sequence - 1,
        };
        res.push(newOption);
      }
      return res;
    }, [] as AnswerOption[]);
    onChange(newOptions);
  };

  const canMoveOptionUp = (sequence: number) => sequence !== 0;

  const canMoveOptionDown = (sequence: number) =>
    sequence !== answerOptions.length - 1;

  const swapPosition = (sequence1: number, sequence2: number): void => {
    const newOptions = answerOptions.map((option) => {
      if (option.sequence === sequence1)
        return { ...option, sequence: sequence2 };
      if (option.sequence === sequence2)
        return { ...option, sequence: sequence1 };
      return option;
    });
    onChange(newOptions);
  };

  const handleMoveOptionUp = (sequence: number) => {
    if (!canMoveOptionUp(sequence)) return;
    const prevSequence = sequence - 1;
    swapPosition(sequence, prevSequence);
  };

  const handleMoveOptionDown = (sequence: number) => {
    if (!canMoveOptionDown(sequence)) return;
    const nextSequence = sequence + 1;
    swapPosition(sequence, nextSequence);
  };

  const sortedAnswerOptions = useMemo(
    () => ArrayUtil.sortAscBySequence(answerOptions),
    [answerOptions],
  );

  return (
    <>
      <div className="flex flex-col gap-1">
        {sortedAnswerOptions.map((option: AnswerOption) => {
          const { id, sequence } = option;
          return (
            <CreateAnswerOption
              key={id}
              option={option}
              onChange={(newOption: AnswerOption) =>
                handleChangeOption(id, newOption)
              }
              onRemove={() => handleDeleteOption(sequence)}
              canMoveUp={canMoveOptionUp(sequence)}
              canMoveDown={canMoveOptionDown(sequence)}
              onMoveUp={() => handleMoveOptionUp(sequence)}
              onMoveDown={() => handleMoveOptionDown(sequence)}
              validationError={validationError?.[sequence]}
              onClearValidationError={onClearValidationError}
            />
          );
        })}
      </div>
      <Button label="Add option" type="button" onClick={handleAddOption} />
    </>
  );
};
export default AnswerOptions;

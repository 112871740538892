import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipTriggerProps,
} from "components/ui/tooltip";
import { FC, HTMLAttributes } from "react";
import UserAvatar from "./UserAvatar";
import { cn } from "lib/utils";
import { UserStatusType } from "enums/user";

interface UserBriefInfoProps {
  avatarUrl: string;
  name: string;
  email: string;
  status?: UserStatusType;
  triggerClassName?: TooltipTriggerProps["className"];
  className?: HTMLAttributes<HTMLDivElement>["className"];
}
const UserBriefInfo: FC<UserBriefInfoProps> = ({
  avatarUrl,
  name,
  email,
  triggerClassName,
  className,
  status = UserStatusType.ACTIVE,
}) => {
  const showAvatar = !["You", "Anonymous Reviewer(s)"].includes(name);
  const showTooltip = !["You", "Anonymous Reviewer(s)"].includes(name);

  if (!showTooltip)
    return (
      <div
        className={cn("text-[0.875rem] font-[500] leading-[22.4px]", className)}
      >
        {name}
      </div>
    );

  return (
    <div
      className={cn(
        "flex items-center text-[0.875rem] font-[500] leading-[22.4px]",
        className,
      )}
    >
      <TooltipProvider>
        <Tooltip delayDuration={100}>
          <TooltipTrigger>
            <div
              className={cn(
                "flex items-center gap-x-[0.25rem]",
                triggerClassName,
              )}
            >
              {showAvatar && (
                <UserAvatar
                  className="w-[1.25rem] h-[1.25rem]"
                  fallbackClassName="text-[0.5rem]"
                  name={name}
                  avatarUrl={avatarUrl}
                  status={status}
                />
              )}
              <div
                className={
                  status === UserStatusType.INACTIVE ? "text-neutral-50" : ""
                }
              >
                {name}
              </div>
            </div>
          </TooltipTrigger>
          <TooltipContent side="bottom">
            <div>
              {`${email} 
              ${
                status === UserStatusType.INACTIVE
                  ? "(Deactivated account)"
                  : ""
              }`}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export default UserBriefInfo;

export enum FormType {
  THREE_HUNDRED_SIXTY = "360",
  PEER = "peer",
  ANYTIME = "anytime",
}

export enum FormName {
  THREE_HUNDRED_SIXTY = "360 Feedback",
  PEER = "Peer Feedback",
}

export enum QuestionType {
  TEXT = "text",
  MULTIPLE_CHOICE = "multiple_choice",
  CHECK_BOXES = "check_boxes",
}

import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import { FormFeedbackItem } from "components/form-feedback";
import { useError, useHttpQueryService } from "hooks";
import { isEmpty } from "lodash";
import { FormService } from "services";
import { useAuthStore } from "store";
import { UserUtil } from "utils";

const FeedbackForMeList: React.FC = () => {
  const user = useAuthStore((state) => state.user);
  if (!user) return null;

  const {
    result: forms,
    error,
    isLoading,
  } = useHttpQueryService({
    request: () => FormService.getFormFeedbackForMe(),
  });

  const reviewees = [UserUtil.transformToUserProfile(user, true)];

  useError(error);

  return (
    <Layout>
      <LoadingWrapper isLoading={isLoading}>
        <PageTitle className="mb-6">Feedback for you</PageTitle>

        {isEmpty(forms) ? (
          <p>There is no feedback for you at the moment.</p>
        ) : (
          <div className={"flex flex-col gap-4"}>
            {forms?.map((form) => {
              return (
                <FormFeedbackItem
                  key={form.id}
                  form={form}
                  reviewees={reviewees}
                  reviewers={UserUtil.getReviewersInfoToDisplay(form.reviewers)}
                />
              );
            })}
          </div>
        )}
      </LoadingWrapper>
    </Layout>
  );
};

export default FeedbackForMeList;

import { FormType, QuestionType } from "enums/form";
import { ChangeEvent, useEffect, useState } from "react";
import { CreateEditQuestionRequest, Question } from "types/question";
import { createQuestionObject } from "utils/question";
import AnswerOptionsWrapper from "./AnswerOptionsWrapper";
import { Button, DropdownField, TextArea } from "components/common/forms";
import { Collapse } from "components/common/collapses";
import { DeleteIcon, DownArrowIcon, UpArrowIcon } from "components/icons";
import { FieldErrors } from "types/field-errors.type";

type CreateQuestionProps = {
  question: Question;
  onChange: (newQuestion: Question) => void;
  canMoveUp: boolean;
  canMoveDown: boolean;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onRemove: () => void;
  validationError?: FieldErrors<CreateEditQuestionRequest>;
  onClearValidationError?: () => void;
  formType: FormType;
};
const CreateQuestion: React.FC<CreateQuestionProps> = ({
  question,
  onChange,
  canMoveUp,
  canMoveDown,
  onMoveUp,
  onMoveDown,
  onRemove,
  validationError,
  onClearValidationError,
  formType,
}) => {
  const [questionTypeOptions, setQuestionTypeOptions] = useState<{
    [optionValue: string]: string;
  }>({
    [QuestionType.TEXT]: "Free Text",
    [QuestionType.MULTIPLE_CHOICE]: "Multiple Choice",
  });

  const handleChangeField = (
    e: ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    let newQuestion: Question;
    if (e.target.name === "questionType") {
      const newQuestionType = e.target.value as QuestionType;
      newQuestion = createQuestionObject(newQuestionType, {
        id: question.id,
        sequence: question.sequence,
        label: question.label,
        hint: question.hint,
      });
    } else {
      newQuestion = {
        ...question,
        [e.target.name]: e.target.value,
      };
    }
    onChange(newQuestion);
  };

  useEffect(() => {
    let newQuestionTypeOptions = {
      ...questionTypeOptions,
    };

    if (formType !== FormType.PEER) {
      newQuestionTypeOptions = {
        ...newQuestionTypeOptions,
        // [QuestionType.CHECK_BOXES]: "Check Boxes",
      };
    } else {
      // delete newQuestionTypeOptions[QuestionType.CHECK_BOXES];
    }
    setQuestionTypeOptions(newQuestionTypeOptions);
  }, [formType]);

  const sequence = question.sequence;
  const tags = [
    `Question ${sequence + 1}`,
    questionTypeOptions[question.questionType],
    question.label,
  ];

  const handleChangeHint = (name: string, value: string) => {
    const newQuestion = {
      ...question,
      [name]: value,
    };
    onChange(newQuestion);
  };

  return (
    <div className="flex gap-4">
      <Collapse
        header={
          <div className="flex gap-2 flex-wrap body-14-medium text-turquoise-100">
            {tags.filter(Boolean).map((tag, index) => (
              <div
                key={index}
                className="bg-turquoise-5 px-3 py-[6px] rounded-lg border-turquoise-100 border"
              >
                <span>{tag}</span>
              </div>
            ))}
          </div>
        }
      >
        <div className="p-6 border-t border-neutral-25 flex flex-col gap-4">
          <DropdownField
            id={`question-type-${sequence}`}
            label="Question type"
            options={questionTypeOptions}
            value={question.questionType}
            onChange={handleChangeField}
            name="questionType"
            errorMessage={validationError?.questionType}
            onClearError={onClearValidationError}
          />
          <TextArea
            id={`question-label-${sequence}`}
            label="Question name"
            value={question.label}
            onChange={handleChangeField}
            name="label"
            rows={1}
            errorMessage={validationError?.label}
            onClearError={onClearValidationError}
          />
          <TextArea
            id={`question-hint-${sequence}`}
            label="Question helper text"
            value={question.hint}
            hint="You can give some information for reviewers to understand more about the question"
            onTextFormatterChange={handleChangeHint}
            name="hint"
            rows={3}
            errorMessage={validationError?.hint}
            onClearError={onClearValidationError}
          />
          <AnswerOptionsWrapper
            question={question}
            onChange={onChange}
            validationError={validationError}
            onClearValidationError={onClearValidationError}
          />
        </div>
      </Collapse>
      <div className="flex flex-col gap-2 w-fit h-fit bg-neutral-15 rounded-3xl p-2">
        <Button
          onClick={onMoveUp}
          variant="secondary"
          disabled={!canMoveUp}
          size="small"
        >
          <UpArrowIcon className="w-6 h-6" />
        </Button>
        <Button
          onClick={onMoveDown}
          variant="secondary"
          disabled={!canMoveDown}
          size="small"
        >
          <DownArrowIcon className="w-6 h-6" />
        </Button>
        <Button onClick={onRemove} variant="danger" size="small">
          <DeleteIcon className="w-6 h-6" />
        </Button>
      </div>
    </div>
  );
};
export default CreateQuestion;

import {
  AsyncDropdown,
  DateField,
  TextArea,
  TextField,
} from "components/common/forms";
import { TipTapRef } from "components/common/forms/Tiptap/Tiptap";
import { ChangeEvent, RefObject } from "react";
import { MultiValue } from "react-select";
import { FieldErrors } from "types/field-errors.type";
import { IMultipleChoiceOption } from "types/input.type";
import {
  RequestAnytimeFeedbackFormData,
  RequestAnytimeFeedbackRequest,
} from "types/request-anytime-feedback";
import { DueDateFormUtil } from "utils";
import { getTrimValueFromHTMLString } from "utils/string.util";

type RequestAnytimeFeedbackFormProps = {
  formData: RequestAnytimeFeedbackFormData;
  onChange: (newFormData: RequestAnytimeFeedbackFormData) => void;
  revieweeEmail: string;
  searchReviewers: (searchText: string) => Promise<IMultipleChoiceOption[]>;
  validationError?: FieldErrors<RequestAnytimeFeedbackRequest>;
  onClearValidationError?: () => void;
  editorRef: RefObject<TipTapRef>;
};

const RequestAnytimeFeedbackForm: React.FC<RequestAnytimeFeedbackFormProps> = ({
  formData,
  onChange,
  revieweeEmail,
  searchReviewers,
  validationError,
  onClearValidationError,
  editorRef,
}) => {
  const { name, dueDate, reviewers, description } = formData;

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newFormData: RequestAnytimeFeedbackFormData = {
      ...formData,
      name: value,
    };

    onChange(newFormData);
  };

  const handleChangeDueDate = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newDueDate: Date = DueDateFormUtil.getDateTime(value);

    const newFormData: RequestAnytimeFeedbackFormData = {
      ...formData,
      dueDate: newDueDate,
    };

    onChange(newFormData);
  };

  const onReviewersChange = (value: MultiValue<IMultipleChoiceOption>) => {
    const newReviewers = value.map<IMultipleChoiceOption>(
      (reviewer) => reviewer,
    );

    const newFormData: RequestAnytimeFeedbackFormData = {
      ...formData,
      reviewers: newReviewers,
    };

    onChange(newFormData);
  };

  const handleChangeDescription = (id: string, value: string) => {
    const trueValue = getTrimValueFromHTMLString(value);

    const newFormData: RequestAnytimeFeedbackFormData = {
      ...formData,
      description: trueValue,
    };

    onChange(newFormData);
  };

  return (
    <form className="flex flex-col gap-6">
      <TextField
        label={"This feedback is for you"}
        value={revieweeEmail}
        id={"reviewee-email"}
        disabled
      />

      <TextField
        label="What is this feedback about?"
        hint="This will be the title of your feedback"
        placeholder="Enter the title for your feedback form"
        value={name}
        onChange={handleChangeName}
        id={"name"}
        errorMessage={validationError?.name}
        onClearError={onClearValidationError}
      />

      <DateField
        id={"due-date"}
        label={"Deadline"}
        hint={"Give this survey a deadline"}
        value={DueDateFormUtil.getDateFieldValue(dueDate)}
        onChange={handleChangeDueDate}
        name={"dueDate"}
        errorMessage={validationError?.dueDate}
        onClearError={onClearValidationError}
      />

      <AsyncDropdown<true>
        id="reviewers"
        isMulti
        value={reviewers}
        onChange={onReviewersChange}
        label="Chose your reviewer(s)"
        placeholder="Select one or multiple people"
        request={searchReviewers}
        errorMessage={validationError?.reviewerIds}
        onClearError={onClearValidationError}
        required
      />

      <TextArea
        editorRef={editorRef}
        label="Description"
        hint="Please provide reviewers with information about the specific aspects you would like feedback on, such as performance, skill set, or any other relevant criteria."
        value={description}
        onTextFormatterChange={handleChangeDescription}
        id={"description"}
        rows={6}
        errorMessage={validationError?.description}
        onClearError={onClearValidationError}
      />
    </form>
  );
};

export default RequestAnytimeFeedbackForm;

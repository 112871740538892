import { useMemo } from "react";
import { Question } from "types/question";
import { ArrayUtil } from "utils";
import QuestionWithFeedbacks from "./QuestionWithFeedbacks";
import cx from "classnames";
import { AnswersGroupedByQuestion } from "types/answer-with-reviewer.type";

type FeedbackByFormProps = {
  questions: Question[];
  className?: string;
  answersGroupedByQuestion: AnswersGroupedByQuestion;
  showUserProfileCard?: boolean;
  isSingleCol?: boolean;
};

const FeedbackByForm: React.FC<FeedbackByFormProps> = ({
  questions,
  className,
  answersGroupedByQuestion,
  showUserProfileCard,
  isSingleCol = true,
}) => {
  const sortedQuestions = useMemo(() => {
    return ArrayUtil.sortAscBySequence(questions);
  }, [questions]);

  return (
    <div className={cx("flex gap-6 flex-col", className)}>
      {sortedQuestions.map((question, index) => {
        const { id } = question;
        return (
          <QuestionWithFeedbacks
            question={question}
            key={index}
            answersWithReviewers={answersGroupedByQuestion[id]}
            showUserProfileCard={showUserProfileCard}
            isSingleCol={isSingleCol}
          />
        );
      })}
    </div>
  );
};

export default FeedbackByForm;

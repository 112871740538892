import { Badge } from "components/common";
import { LinkButton } from "components/common/forms";
import { FeedbackIcon } from "components/common/ui";
import { DrawIcon, EyeIcon } from "components/icons";
import { FORM_TYPE_MAP } from "constants/form-type-map";
import { PAGE_PATHS } from "constants/page-paths";
import { FeedbackStatusType } from "enums/feedback";
import { FormType } from "enums/form";
import { generatePath } from "react-router-dom";
import { TodoRequestResponse } from "responses";
import { getDisplayDate } from "utils/date";
import UserBriefInfo from "components/user/UserBriefInfo";

type ToDoItemProps = {
  feedbackRequest: TodoRequestResponse;
};
const ToDoItem: React.FC<ToDoItemProps> = ({ feedbackRequest }) => {
  const {
    id,
    dueDate,
    form: { name, formType },
    reviewee,
    feedback,
  } = feedbackRequest;

  const feedbackStatus = feedback?.status;
  const buttonIcon =
    feedbackStatus === FeedbackStatusType.DRAFT ? (
      <DrawIcon className="w-[20px] h-[20px]" />
    ) : (
      <EyeIcon className="w-[20px] h-[20px]" />
    );
  const butttonLabel =
    feedbackStatus === FeedbackStatusType.DRAFT ? "Resume" : "View";

  return (
    <div className="bg-blue-10 px-8 py-6 flex flex-row rounded-3xl justify-between">
      <div className="flex flex-col justify-between gap-4">
        <div>
          <div className="flex pb-2 gap-2 items-center">
            <FeedbackIcon type={formType} />
            <span className="body-20-semibold text-neutral-200">{name}</span>
          </div>

          <div className="flex gap-2 items-center mb-4 ">
            <div>
              <span className="text-neutral body-14-semibold">Type:&nbsp;</span>
              <span className="text-neutral body-14-regular">
                {FORM_TYPE_MAP[formType]}
              </span>
            </div>
            <span className="text-neutral body-14-regular">|</span>
            <div>
              <span className="text-neutral body-14-semibold">
                Deadline:&nbsp;
              </span>
              <span className="text-neutral body-14-regular">
                {getDisplayDate(dueDate!)}
              </span>
            </div>
            {formType === FormType.THREE_HUNDRED_SIXTY && (
              <>
                <span className="text-neutral body-14-regular">|</span>
                <div className="flex flex-row">
                  <span className="text-neutral body-14-semibold">
                    Status:&nbsp;
                  </span>
                  {feedbackStatus === FeedbackStatusType.DRAFT ? (
                    <Badge label="In progress" variant="primary" size="small" />
                  ) : formType === FormType.THREE_HUNDRED_SIXTY ? (
                    <Badge
                      label="Not started"
                      variant="secondary"
                      size="small"
                    />
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="flex flex-col text-neutral-100 body-14-semibold gap-y-[0.25rem]">
          <span>
            Reviewer:&nbsp;<span className="body-14-regular">You</span>
          </span>
          <div className="flex items-center gap-x-[0.25rem]">
            <div>Reviewee:</div>
            <UserBriefInfo
              avatarUrl={reviewee.avatar || ""}
              name={reviewee.fullName}
              email={reviewee.email}
              status={reviewee.status}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end h-fit">
        <LinkButton to={generatePath(PAGE_PATHS.FEEDBACK_SEND, { id })}>
          {buttonIcon}
          {butttonLabel}
        </LinkButton>
      </div>
    </div>
  );
};

export default ToDoItem;

import { LoadingWrapper } from "components/common/layouts";
import { FormType } from "enums/form";
import { useError, useHttpQueryService } from "hooks";
import { FeedbackRequestService } from "services";
import ListToDoFeedbackRequests from "components/feedback-request/ToDoList";

const ToDo360FeedbackRequests = () => {
  const {
    result: feedbackRequests,
    error,
    isLoading,
  } = useHttpQueryService({
    request: () => FeedbackRequestService.getByFormType(FormType.PEER),
  });

  useError(error, true);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <ListToDoFeedbackRequests feedbackRequests={feedbackRequests ?? []} />
    </LoadingWrapper>
  );
};

export default ToDo360FeedbackRequests;

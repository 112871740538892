import { UserProfile } from "types/user";
import cx from "classnames";
import UserBriefInfo from "components/user/UserBriefInfo";

type UserListProps = {
  userProfiles: UserProfile[];
  className?: string;
  direction?: "horizontal" | "vertical";
};

const UserList: React.FC<UserListProps> = ({
  userProfiles,
  direction = "horizontal",
  className,
}) => {
  return (
    <div
      className={cx(
        "flex justify-start flex-wrap gap-[0.75rem]",
        { "flex-col": direction === "vertical" },
        { "flex-row": direction === "horizontal" },
        className,
      )}
    >
      {userProfiles.map<React.ReactNode>((userProfile, idx) => (
        <UserBriefInfo
          status={userProfile.status}
          key={`user_${idx}`}
          name={userProfile.displayName}
          avatarUrl={userProfile.avatar || ""}
          email={userProfile.email || ""}
        />
      ))}
    </div>
  );
};

export default UserList;

import { DownArrowIcon, UpArrowIcon } from "components/icons";
import { PropsWithChildren, useState } from "react";

type CollapseProps = PropsWithChildren & {
  header: React.ReactNode;
  defaultState?: boolean;
};
const Collapse: React.FC<CollapseProps> = ({
  header,
  children,
  defaultState = true,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultState);
  return (
    <div className="w-full h-fit bg-blue-10 rounded-2xl shadow">
      <div className="flex px-6 py-4 justify-between items-center">
        {header}
        <div
          className="cursor-pointer text-neutral-100"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <UpArrowIcon className="w-8 h-8" />
          ) : (
            <DownArrowIcon className="w-8 h-8" />
          )}
        </div>
      </div>

      {isOpen && children}
    </div>
  );
};
export default Collapse;

import { BackButton, Button } from "components/common/forms";
import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import ProactivelyGiveAnytimeFeedbackForm from "./ProactivelyGiveAnytimeFeedbackForm";
import { CollapsibleSidebar } from "components/common/collapses";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IconSquareToggleButton } from "components/common/buttons";
import { PreviewIcon } from "components/icons";
import { useNavigate } from "react-router-dom";
import { ConfirmModal } from "components/common/modals";
import {
  useError,
  useFormBlockNavigation,
  useHttpMutationService,
} from "hooks";
import {
  ProactivelyGiveAnytimeFeedbackFormData,
  ProactivelyGiveAnytimeFeedbackRequest,
} from "types/proactively-give-anytime-feedback.type";
import { FieldErrors } from "types/field-errors.type";
import { FeedbackService, UserService } from "services";
import { UserUtil, ValidationUtil } from "utils";
import { useAuthStore } from "store";
import { PreviewAnytimeFeedback } from "components/preview";
import { toastError, toastSuccess } from "utils/toast";
import { proactivelyGiveAnytimeFeedbackSchema } from "schemas";
import { isEqual } from "lodash";
import { IMultipleChoiceOption } from "types/input.type";
import { UserStatusType } from "enums/user";

const INITIAL_FORM_DATA: ProactivelyGiveAnytimeFeedbackFormData = {
  name: "",
  feedback: "",
};

const ProactivelyGiveAnytimeFeedback = () => {
  const navigate = useNavigate();

  const [isExpandingPreviewSection, setIsExpandingPreviewSection] =
    useState<boolean>(true);
  const [showConfirmSubmitModal, setShowConfirmSubmitModal] =
    useState<boolean>(false);
  const [formData, setFormData] =
    useState<ProactivelyGiveAnytimeFeedbackFormData>(INITIAL_FORM_DATA);
  const [validationError, setValidationError] = useState<
    FieldErrors<ProactivelyGiveAnytimeFeedbackRequest>
  >({});

  const { name, feedback, reviewee } = formData;
  const currentUser = useAuthStore(({ user }) => user);
  const currentUserId = currentUser!.id;

  useEffect(() => {
    const formIsDirty = !isEqual(formData, INITIAL_FORM_DATA);
    setFormIsDirty(formIsDirty);
  }, [formData]);

  const dataToSubmit: ProactivelyGiveAnytimeFeedbackRequest = useMemo(() => {
    const data: ProactivelyGiveAnytimeFeedbackRequest = {
      name,
      feedback,
    };

    if (reviewee) {
      data.revieweeId = +reviewee.value;
    }

    return data;
  }, [formData]);

  const validateForm = () => {
    const { error } =
      proactivelyGiveAnytimeFeedbackSchema.validate(dataToSubmit);

    if (error) {
      const newValidationError =
        ValidationUtil.transformValidationError<ProactivelyGiveAnytimeFeedbackRequest>(
          error,
        );
      setValidationError(newValidationError);
      toastError(error.message);
      return false;
    }

    return true;
  };

  const {
    mutate,
    error,
    isLoading: isSubmitLoading,
  } = useHttpMutationService({
    request: (payload?: ProactivelyGiveAnytimeFeedbackRequest) =>
      FeedbackService.createAnytimeFeeback(payload!),
  });

  const handleSubmit = async () => {
    const payload = dataToSubmit;

    const { error } = proactivelyGiveAnytimeFeedbackSchema.validate(payload);
    if (error) {
      return;
    }

    const response = await mutate(payload);
    handleCloseConfirmSubmitModal();
    if (response) {
      setFormIsDirty(false);
      toastSuccess(`Feedback has been submitted for ${reviewee?.label}`);
    }
    setFormData(INITIAL_FORM_DATA);
  };

  useError(error);

  const {
    setFormIsDirty,
    showLeaveModal,
    handleCancelLeaveModal,
    handleConfirmLeaveModal,
  } = useFormBlockNavigation();

  const handleCollapsePreviewSection = () => {
    setIsExpandingPreviewSection(false);
  };

  const handleClickPreviewIcon = () => {
    setIsExpandingPreviewSection((prev) => !prev);
  };

  const handleClickCancelButton = () => {
    navigate(-1);
  };

  const handleShowConfirmSubmitModal = () => {
    if (!validateForm()) return;

    setShowConfirmSubmitModal(true);
  };

  const handleCloseConfirmSubmitModal = () => {
    setShowConfirmSubmitModal(false);
  };

  const handleClearErrorValidation = () => {
    setValidationError({});
  };

  const handleChangeFormData = (
    newFormData: ProactivelyGiveAnytimeFeedbackFormData,
  ) => {
    setFormData(newFormData);
  };

  const searchReviewee = useCallback(
    async (searchText: string) => {
      const users = await UserService.search(searchText, UserStatusType.ACTIVE);

      const possibleReviewees = users.filter(({ id }) => id !== currentUserId);

      const userOptions = possibleReviewees.map<IMultipleChoiceOption>(
        (reviewee) => ({
          value: reviewee.id,
          label: `${reviewee.fullName} (${reviewee.email})`,
        }),
      );

      return userOptions;
    },
    [currentUserId],
  );

  return (
    <Layout mainClassName="!p-0">
      <LoadingWrapper isLoading={isSubmitLoading}>
        <div className="h-full flex flex-col">
          <div className="h-full flex justify-between relative">
            <div className="flex-auto px-8 py-8">
              <div className="mb-6 flex gap-6 items-center">
                <BackButton className="text-neutral-200" />
                <PageTitle>Give Anytime Feedback</PageTitle>
              </div>

              <ProactivelyGiveAnytimeFeedbackForm
                formData={formData}
                onChange={handleChangeFormData}
                searchReviewee={searchReviewee}
                validationError={validationError}
                onClearValidationError={handleClearErrorValidation}
              />
            </div>

            <CollapsibleSidebar
              isExpanding={isExpandingPreviewSection}
              onCollapse={handleCollapsePreviewSection}
              title={"Form Preview"}
              className="flex-none"
            >
              <PreviewAnytimeFeedback
                name={name}
                reviewer={UserUtil.transformToUserProfile()}
                feedback={feedback}
              />
            </CollapsibleSidebar>

            <IconSquareToggleButton
              Icon={PreviewIcon}
              className="absolute top-8 right-8"
              onClick={handleClickPreviewIcon}
              state={isExpandingPreviewSection ? "on" : "off"}
            />
          </div>

          <div className="sticky bottom-0 mt-auto bg-neutral-0 flex justify-end gap-4 pt-6 pb-8 px-8 border-t border-neutral-20 rounded-b-3xl">
            <Button
              variant="secondary"
              size="medium"
              label="Cancel"
              onClick={handleClickCancelButton}
            />
            <Button
              variant="primary"
              size="medium"
              label="Share your feedback"
              onClick={handleShowConfirmSubmitModal}
            />
          </div>
        </div>
        <ConfirmModal
          open={showLeaveModal}
          title="Leave Page?"
          content="Please be aware that any of your unsaved actions will be discarded. Proceed anyway?"
          yesButton="Confirm"
          noButton="Cancel"
          onYes={handleConfirmLeaveModal}
          onNo={handleCancelLeaveModal}
        />

        <ConfirmModal
          open={showConfirmSubmitModal}
          title="Share your feedback?"
          content="Your feedback will be released immediately"
          yesButton="Share Feedback"
          noButton="Cancel"
          onYes={handleSubmit}
          onNo={handleCloseConfirmSubmitModal}
          yesButtonLoading={isSubmitLoading}
        />
      </LoadingWrapper>
    </Layout>
  );
};

export default ProactivelyGiveAnytimeFeedback;

import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DEFAULT_DATE_FIELD_FORMAT } from "constants/date";

dayjs.extend(utc);
dayjs.extend(timezone);

const DEFAULT_END_OF_DATE_TIME_STRING = "23:59:59";
export const DEFAULT_START_OF_DATE_TIME_STRING = "00:00:00";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";
const DEFAULT_TIMEZONE = "Asia/Bangkok";

export const getDateTime = (
  dateOnly: string,
  timeString = DEFAULT_END_OF_DATE_TIME_STRING,
): Date => {
  return dayjs(
    `${dateOnly} ${timeString}`,
    `${DEFAULT_DATE_FIELD_FORMAT} ${DEFAULT_TIME_FORMAT}`,
  ).toDate();
};

export const getDateTimeWithTimezone = (
  dateOnly: string,
  timeString = DEFAULT_END_OF_DATE_TIME_STRING,
  timezone: string = DEFAULT_TIMEZONE,
): Dayjs => {
  return dayjs(
    `${dateOnly} ${timeString}`,
    `${DEFAULT_DATE_FIELD_FORMAT} ${DEFAULT_TIME_FORMAT}`,
  ).tz(timezone, true);
};

export const getDate = (
  addedDays = 0,
  date = new Date(),
  timeString = DEFAULT_END_OF_DATE_TIME_STRING,
): Date => {
  const day = dayjs(date);
  const dateString = day
    .add(addedDays, "day")
    .format(DEFAULT_DATE_FIELD_FORMAT);
  const initialDueDate = getDateTime(dateString, timeString);
  return initialDueDate;
};

export const getDateFieldValue = (date: Date) => {
  return dayjs(date).format(DEFAULT_DATE_FIELD_FORMAT);
};

export const getDateFieldValueWithTimezone = (
  date: Date,
  timezone: string = DEFAULT_TIMEZONE,
) => {
  return dayjs(date).tz(timezone).format(DEFAULT_DATE_FIELD_FORMAT);
};

import { getDisplayDate } from "utils/date";
import { Column } from "react-table";
import { useMemo } from "react";
import { LinkButton, Table } from "components/common/forms";
import { EyeIcon } from "components/icons";
import { PeerFeedbackResponse } from "responses";
import { PeerFeedbackTableItem } from "types/feedback-request";

type TablePeerFeedbackProps = {
  peerFeedbacks: PeerFeedbackResponse[];
  lastRowRef?: React.LegacyRef<HTMLTableRowElement>;
  className?: string;
  noRecordImg?: string;
  noRecordText?: string;
  isLoading?: boolean;
};

const TablePeerFeedback: React.FC<TablePeerFeedbackProps> = ({
  peerFeedbacks,
  lastRowRef,
  className,
  noRecordImg,
  noRecordText,
  isLoading,
}) => {
  const data = peerFeedbacks.map<PeerFeedbackTableItem>((item) => ({
    ...item,
    id: `${item.formId}-${item.revieweeId}`,
  }));

  const columns: Column<PeerFeedbackTableItem>[] = useMemo(
    () => [
      {
        Header: "Form name",
        accessor: "formName",
        id: "formName",
      },
      {
        Header: "Peer feedback for",
        accessor: "revieweeEmail",
        id: "revieweeEmail",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        id: "createdAt",
        Cell: ({ cell: { value } }) => {
          const dateString = value ? getDisplayDate(value) : "-";
          return <span>{dateString}</span>;
        },
      },
      {
        Header: () => null,
        id: "buttons",
        Cell: ({ row }: { row: { original: PeerFeedbackResponse } }) => (
          <div className="flex items-center justify-end">
            <LinkButton
              buttonClassName="py-[5px] body-14-semibold"
              to={`${row.original.formId}/${row.original.revieweeId}`}
            >
              <EyeIcon className="w-[24px] h-[24px]" />
              View
            </LinkButton>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Table
      columns={columns}
      data={data}
      lastRowRef={lastRowRef}
      className={className}
      noRecordImg={noRecordImg}
      noRecordText={noRecordText}
      isLoading={isLoading}
    />
  );
};

export default TablePeerFeedback;

import { AxiosResponse } from "axios";
import { ENDPOINT_URL } from "constants/api-url";
import {
  FormFeedbackForMeResponse,
  FormFeedbackIProvidedResponse,
} from "responses";
import {
  CreateEditFormRequest,
  FormWithQuestion,
  FormFilter,
  Form,
  GetFormViewQuery,
} from "types/form";
import { http } from "utils";

const CONTROLLER_PREFIX = ENDPOINT_URL.FORM;

const FormApi = {
  create: (data: CreateEditFormRequest): Promise<AxiosResponse> => {
    return http.post(CONTROLLER_PREFIX, data);
  },

  getFormViewById: async (
    id: number,
    query?: GetFormViewQuery,
  ): Promise<FormWithQuestion> => {
    const res = await http.get<FormWithQuestion>(`${CONTROLLER_PREFIX}/${id}`, {
      params: query,
    });
    return res.data;
  },

  getFormEditById: async (id: number): Promise<FormWithQuestion> => {
    const res = await http.get<FormWithQuestion>(
      `${CONTROLLER_PREFIX}/${id}/edit`,
    );
    return res.data;
  },

  put: async (data: CreateEditFormRequest): Promise<AxiosResponse> => {
    return http.put(CONTROLLER_PREFIX, data);
  },

  get: async (filter?: FormFilter): Promise<FormWithQuestion[]> => {
    const res = await http.get<FormWithQuestion[]>(CONTROLLER_PREFIX, {
      params: filter,
    });
    const { data } = res;
    return data;
  },

  getFormFeedbackForMe: async (): Promise<FormFeedbackForMeResponse[]> => {
    const res = await http.get<FormFeedbackForMeResponse[]>(
      `${CONTROLLER_PREFIX}/feedback/for-me`,
    );
    return res.data;
  },

  getFormFeedbackIProvided: async (): Promise<
    FormFeedbackIProvidedResponse[]
  > => {
    const res = await http.get<FormFeedbackIProvidedResponse[]>(
      `${CONTROLLER_PREFIX}/feedback/i-provided`,
    );
    return res.data;
  },

  get360FormFeedback: async (): Promise<Form[]> => {
    const res = await http.get<Form[]>(`${CONTROLLER_PREFIX}/feedback/360`);
    return res.data;
  },

  delete: (id: number): Promise<AxiosResponse> => {
    return http.delete(`${CONTROLLER_PREFIX}/${id}`);
  },

  getRunning360Forms: async (): Promise<FormWithQuestion[]> => {
    const res = await http.get<FormWithQuestion[]>(
      `${CONTROLLER_PREFIX}/running360Forms/getAll`,
    );
    const { data } = res;
    return data;
  },
};

export default FormApi;

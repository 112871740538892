import { PAGE_PATHS } from "constants/page-paths";
import { FormType } from "enums/form";
import { FormEvent, useEffect, useState } from "react";
import { CreateEditFormRequest, FormWithQuestion } from "types/form";
import { toastError, toastSuccess } from "utils/toast";
import { createFormSchema } from "schemas/create-form-schema";
import { sanitizeQuestionRequest } from "utils/question";
import { Question } from "types/question";
import { useNavigate } from "react-router-dom";
import { FormService } from "services";
import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import { CreateEditForm } from "components/form";
import { BackButton } from "components/common/forms";
import {
  useError,
  useFormBlockNavigation,
  useHttpMutationService,
} from "hooks";
import { ConfirmModal } from "components/common/modals";
import { isEqual } from "lodash";
import { FieldErrors } from "types/field-errors.type";
import { ArrayUtil, ValidationUtil } from "utils";
import { PreviewForm } from "components/preview";

const INITIAL_FORM: FormWithQuestion = {
  id: 0,
  name: "",
  description: "",
  formType: FormType.THREE_HUNDRED_SIXTY,
  questions: [],
};

const CreateForm: React.FC = () => {
  const [form, setForm] = useState<FormWithQuestion>(INITIAL_FORM);
  const [validationError, setValidationError] = useState<
    FieldErrors<CreateEditFormRequest>
  >({});

  const { name, description, questions, formType } = form;
  const navigate = useNavigate();

  const {
    result: response,
    mutate,
    isLoading,
    error,
  } = useHttpMutationService({
    request: (data?: CreateEditFormRequest) => FormService.create(data!),
  });

  const handleChange = (newForm: FormWithQuestion) => {
    setForm(newForm);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const createQuestionDto = ArrayUtil.sortAscBySequence(questions).map(
      (question: Question) => sanitizeQuestionRequest(question),
    );
    const data: CreateEditFormRequest = {
      name,
      formType,
      description,
      questions: createQuestionDto,
    };
    const { error, value: validatedData } = createFormSchema.validate(data);

    if (error) {
      const newValidationError =
        ValidationUtil.transformValidationError<CreateEditFormRequest>(error);
      setValidationError(newValidationError);
      toastError(error.message);
      return;
    }

    const response = await mutate(validatedData);

    if (response) {
      setFormIsDirty(false);
      toastSuccess("Form is created successfully!");
    }
  };

  const handleClearErrorValidation = () => {
    setValidationError({});
  };

  useError(error);

  const {
    formIsDirty,
    setFormIsDirty,
    showLeaveModal,
    handleCancelLeaveModal,
    handleConfirmLeaveModal,
  } = useFormBlockNavigation();

  useEffect(() => {
    const formIsDirty = !isEqual(form, INITIAL_FORM);
    setFormIsDirty(formIsDirty);
  }, [form]);

  useEffect(() => {
    if (!response || formIsDirty) return;

    navigate(PAGE_PATHS.FORM);
  }, [response, formIsDirty]);

  return (
    <Layout>
      <LoadingWrapper isLoading={isLoading}>
        <div className="w-full h-fit pb-8">
          <div className="flex gap-6 items-center mb-6">
            <BackButton className="text-neutral-200" />
            <PageTitle>Create Form</PageTitle>
          </div>
          <div className="flex gap-8">
            <CreateEditForm
              form={form}
              onChange={handleChange}
              onSubmit={handleSubmit}
              validationError={validationError}
              onClearValidationError={handleClearErrorValidation}
            />
            <PreviewForm
              name={name}
              questions={questions}
              className={"flex-1 h-fit"}
            />
          </div>
        </div>
        <ConfirmModal
          open={showLeaveModal}
          title="Leave Page?"
          content="Please be aware that any of your unsaved actions will be discarded. Proceed anyway?"
          yesButton="Confirm"
          noButton="Cancel"
          onYes={handleConfirmLeaveModal}
          onNo={handleCancelLeaveModal}
        />
      </LoadingWrapper>
    </Layout>
  );
};

export default CreateForm;

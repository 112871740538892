import { TextArea } from "components/common/forms";
import { TextQuestion } from "types/question";

type PreviewTextQuestionProps = {
  question: TextQuestion;
};
const PreviewTextQuestion: React.FC<PreviewTextQuestionProps> = ({
  question,
}) => {
  const id = `question-text-${question.id}-${question.sequence}`;
  let label: string = question.sequence + 1 + ". ";
  if (!question.label) {
    label += "...";
    return (
      <p className="mb-2">
        <label htmlFor={id} className="body-16-semibold text-neutral-200">
          {label}
        </label>
      </p>
    );
  }

  label += question.label;
  return (
    <TextArea
      onTextFormatterChange={() => {
        return;
      }}
      value={""}
      id={id}
      label={label}
      hint={question.hint}
      disabled
    />
  );
};
export default PreviewTextQuestion;

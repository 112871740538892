import cx from "classnames";
import { Divide } from "components/common/layouts";
import { TextArea } from "components/common/forms";
import Markdown from "react-markdown";

type PreviewAnytimeFormProps = {
  name: string;
  description: string;
  className?: string;
};

const PreviewAnytimeForm: React.FC<PreviewAnytimeFormProps> = ({
  name,
  description,
  className,
}) => {
  return (
    <div className={cx(className)}>
      <Divide
        className={cx("bg-blue-10 rounded-2xl px-8 pt-10 pb-20 min-h-[552px]")}
      >
        <div className="mb-6">
          <span className="body-20-semibold text-neutral-200">{name}</span>
        </div>
        <div className="pt-6">
          <Markdown>{description}</Markdown>
          <div className="pt-4">
            <TextArea
              onTextFormatterChange={() => {
                return;
              }}
              value={""}
              id={"anytime-feedback"}
              rows={6}
              disabled
            />
          </div>
        </div>
      </Divide>
    </div>
  );
};

export default PreviewAnytimeForm;

import {
  CheckIcon,
  FormIcon,
  GroupIcon,
  HomeIcon,
  PersonIcon,
  SendIcon,
  Three60Icon,
} from "components/icons";
import { PAGE_PATHS } from "./page-paths";

export const ADMIN_MENU_ITEM_PATHS = [
  PAGE_PATHS.HOME,
  PAGE_PATHS.FORM,
  PAGE_PATHS.REQUEST_SEND_TO_ALL,
  PAGE_PATHS.THREE60_FEEDBACK_LIST,
  PAGE_PATHS.PEER_FEEDBACK_LIST,
];
export const EMPLOYEE_MENU_ITEM_PATHS = [
  PAGE_PATHS.REQUEST,
  PAGE_PATHS.FEEDBACK_FOR_ME_LIST,
  PAGE_PATHS.FEEDBACK_I_PROVIDED_LIST,
];

export const MENU_ITEMS = {
  [PAGE_PATHS.HOME]: {
    name: "Home",
    Icon: HomeIcon,
    children: [],
  },
  [PAGE_PATHS.FORM]: {
    name: "Form Management",
    Icon: FormIcon,
    children: [PAGE_PATHS.FORM_CREATE, PAGE_PATHS.FORM_EDIT],
  },
  [PAGE_PATHS.REQUEST_SEND_TO_ALL]: {
    name: "Sending out Survey",
    Icon: SendIcon,
    children: [],
  },
  [PAGE_PATHS.THREE60_FEEDBACK_LIST]: {
    name: "360 Feedback",
    Icon: Three60Icon,
    children: [PAGE_PATHS.THREE60_FEEDBACK_BY_FORMID],
  },
  [PAGE_PATHS.PEER_FEEDBACK_LIST]: {
    name: "Peer Feedback",
    Icon: PersonIcon,
    children: [PAGE_PATHS.PEER_FEEDBACK_ITEM],
  },
  [PAGE_PATHS.REQUEST]: {
    name: "Your todo",
    Icon: CheckIcon,
    children: [PAGE_PATHS.REQUEST_ADD_360_REVIEWER, PAGE_PATHS.FEEDBACK_SEND],
  },
  [PAGE_PATHS.FEEDBACK_FOR_ME_LIST]: {
    name: "Feedback for you",
    Icon: PersonIcon,
    children: [PAGE_PATHS.FEEDBACK_FOR_ME_BY_FORMID],
  },
  [PAGE_PATHS.FEEDBACK_I_PROVIDED_LIST]: {
    name: "Feedback you provided",
    Icon: GroupIcon,
    children: [PAGE_PATHS.FEEDBACK_I_PROVIDED_BY_FORMID],
  },
};

import {
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { TextField } from "components/common/forms";
import React, { ChangeEvent } from "react";
import {
  AnswerOption,
  CreateEditAnswerOptionRequest,
} from "types/answer-option";
import { FieldErrors } from "types/field-errors.type";

type CreateAnswerOptionProps = {
  option: AnswerOption;
  onChange: (newQuestion: AnswerOption) => void;
  canMoveUp: boolean;
  canMoveDown: boolean;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onRemove: () => void;
  validationError?: FieldErrors<CreateEditAnswerOptionRequest>;
  onClearValidationError?: () => void;
};
const CreateAnswerOption: React.FC<CreateAnswerOptionProps> = ({
  option,
  onChange,
  canMoveUp,
  canMoveDown,
  onMoveUp,
  onMoveDown,
  onRemove,
  validationError,
  onClearValidationError,
}) => {
  const handleChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newOption: AnswerOption = {
      ...option,
      [e.target.name]: e.target.value,
    };
    onChange(newOption);
  };
  const sequence = option.sequence;
  return (
    <div className="flex gap-2 items-center justify-between">
      <input type="radio" className="w-[30px] h-[30px]" checked={false}></input>
      <div className="w-full">
        <TextField
          id={`option-label-${sequence}`}
          placeholder={`Option ${sequence + 1}`}
          value={option.label}
          onChange={handleChange}
          name="label"
          errorMessage={validationError?.label}
          onClearError={onClearValidationError}
        />
      </div>

      <div className="flex gap-2 w-fit h-fit items-center">
        <div
          onClick={onMoveUp}
          className={`${
            canMoveUp ? "cursor-pointer" : "cursor-not-allowed opacity-20"
          } flex items-center justify-center border-2 border-slate-400 rounded-full p-1`}
        >
          <ChevronUpIcon className="w-4 h-4" />
        </div>
        <div
          onClick={onMoveDown}
          className={`${
            canMoveDown ? "cursor-pointer" : "cursor-not-allowed opacity-20"
          } flex items-center justify-center border-2 border-slate-400 rounded-full p-1`}
        >
          <ChevronDownIcon className="w-4 h-4" />
        </div>
        <div
          onClick={onRemove}
          className="w-fit h-fit p-1 rounded-full border-[1px]
         border-red-500 hover:bg-red-300 cursor-pointer"
        >
          <TrashIcon className="w-4 h-4 text-red-500" />
        </div>
      </div>
    </div>
  );
};

export default CreateAnswerOption;

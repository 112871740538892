import { AnswerOption } from "types/answer-option";
import {
  CreateEditOptionQuestionRequest,
  CreateEditQuestionRequest,
  OptionQuestion,
  Question,
} from "types/question";
import { FieldErrors } from "types/field-errors.type";
import { isOptionQuestion } from "utils/question";
import AnswerOptions from "./AnswerOptions";

type AnswerOptionsWrapperProps = {
  question: Question;
  onChange: (newQuestion: Question) => void;
  validationError?: FieldErrors<CreateEditQuestionRequest>;
  onClearValidationError?: () => void;
};
const AnswerOptionsWrapper: React.FC<AnswerOptionsWrapperProps> = ({
  question,
  onChange,
  validationError,
  onClearValidationError,
}) => {
  if (!isOptionQuestion(question)) return null;

  const optionQuestionValidationError:
    | FieldErrors<CreateEditOptionQuestionRequest>
    | undefined = validationError;

  const handleChangeOptions = (newOptions: AnswerOption[]) => {
    const newQuestion: OptionQuestion = {
      ...question,
      answerOptions: newOptions,
    };
    onChange(newQuestion);
  };
  return (
    <AnswerOptions
      answerOptions={question.answerOptions}
      onChange={handleChangeOptions}
      validationError={optionQuestionValidationError?.answerOptions}
      onClearValidationError={onClearValidationError}
    />
  );
};
export default AnswerOptionsWrapper;

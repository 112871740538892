import { AsyncDropdown } from "components/common/forms";
import AnswerItem from "pages/feedback-create/AnswerItem/AnswerItem";
import { useMemo } from "react";
import { SingleValue } from "react-select";
import {
  ProactivelyGive360FeedbackFormData,
  ProactivelyGive360FeedbackRequest,
} from "types/feedback";
import { FieldErrors } from "types/field-errors.type";
import { FormWithQuestion } from "types/form";
import { IMultipleChoiceOption } from "types/input.type";
import { Question } from "types/question";
import { ArrayUtil } from "utils";
import Markdown from "react-markdown";

type ProactivelyGiveAnytimeFeedbackFormProps = {
  form?: FormWithQuestion;
  formData: ProactivelyGive360FeedbackFormData;
  onChange: (newFormData: ProactivelyGive360FeedbackFormData) => void;
  searchReviewee: (searchText: string) => Promise<IMultipleChoiceOption[]>;
  validationError: FieldErrors<ProactivelyGive360FeedbackRequest>;
  onClearValidationError: () => void;
};

const ProactivelyGive360FeedbackForm: React.FC<
  ProactivelyGiveAnytimeFeedbackFormProps
> = ({
  form,
  formData,
  onChange,
  searchReviewee,
  validationError,
  onClearValidationError,
}) => {
  const { reviewee, answers } = formData;
  const questions = form ? form.questions : [];
  const sortedQuestions = useMemo(() => {
    return ArrayUtil.sortAscBySequence(questions);
  }, [questions]);

  const onRevieweeChange = (value: SingleValue<IMultipleChoiceOption>) => {
    if (!value) return;

    const newFormData: ProactivelyGive360FeedbackFormData = {
      ...formData,
      reviewee: value,
    };

    onChange(newFormData);
  };

  const handleChange = (question: Question, value: string | number) => {
    const { id: questionId } = question;

    handleTextQuestionChange(questionId, String(value));
  };

  const handleTextQuestionChange = (questionId: number, value: string) => {
    const newAnswer = [...answers];
    if (!answers.find((item) => item.questionId === questionId)) {
      newAnswer.push({ questionId: questionId, answerText: value });
    } else {
      for (let i = 0; i < answers.length; i++) {
        if (answers[i].questionId === questionId) {
          newAnswer[i] = { ...answers[i], answerText: value };
          break;
        }
      }
    }

    const newFormData: ProactivelyGive360FeedbackFormData = {
      ...formData,
      answers: newAnswer,
    };

    onChange(newFormData);
  };

  return (
    <form className="flex flex-col gap-6">
      <AsyncDropdown<false>
        id="reviewee"
        value={reviewee}
        onChange={onRevieweeChange}
        label="Select the employee you want to proactively give feedback to"
        placeholder="Find people by email..."
        request={searchReviewee}
        errorMessage={validationError?.revieweeId}
        onClearError={onClearValidationError}
        required
        className="w-1/2"
      />

      {reviewee && form && (
        <div className="gap-4">
          <div className="m-4">
            <Markdown>{form.description}</Markdown>
          </div>

          <div className="flex-1 bg-blue-10 px-8 pt-8 pb-20 rounded-3xl">
            <div className="space-y-6">
              {sortedQuestions.map((question, index) => (
                <AnswerItem
                  key={index}
                  answers={answers}
                  question={question}
                  onChange={handleChange}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default ProactivelyGive360FeedbackForm;

import { BackButton } from "components/common/forms";
import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import { FeedbackByReviewee } from "components/feedback";
import UserBriefInfo from "components/user/UserBriefInfo";
import { FORM_TYPE_MAP } from "constants/form-type-map";
import {
  useError,
  useHttpQueryService,
  useLoading,
  useNumericParam,
} from "hooks";
import Markdown from "react-markdown";
import { FeedbackService, FormService, UserService } from "services";
import { getDisplayDate } from "utils/date";

const PeerFeedback: React.FC = () => {
  const formId = useNumericParam("formId");
  const revieweeId = useNumericParam("revieweeId");

  const {
    result: form,
    error: formFetchError,
    isLoading: isFormLoading,
  } = useHttpQueryService({
    request: () => FormService.getFormViewById(formId),
    requestOption: {
      enabled: !!formId,
    },
  });

  const {
    result: reviewee,
    error: revieweeFetchError,
    isLoading: isRevieweeLoading,
  } = useHttpQueryService({
    request: () => UserService.getUserProfileById(revieweeId),
    requestOption: {
      enabled: !!revieweeId,
    },
  });

  const {
    result: feedbacks,
    error: feedbackFetchError,
    isLoading: isFeedbackLoading,
  } = useHttpQueryService({
    request: () =>
      FeedbackService.getPeerFeedbackByReviewee(formId, revieweeId),
    requestOption: {
      enabled: !!revieweeId,
    },
  });

  useError(formFetchError, true);
  useError(feedbackFetchError, true);
  useError(revieweeFetchError, true);

  const isLoading = useLoading([
    isFeedbackLoading,
    isFormLoading,
    isRevieweeLoading,
  ]);

  return (
    <Layout>
      <LoadingWrapper isLoading={isLoading}>
        {form && reviewee && (
          <div>
            <div className="flex gap-6 items-center mb-6">
              <BackButton className="text-neutral-200" />
              <PageTitle className="flex items-center gap-[0.5rem]">
                <span>{form.name} for</span>
                <UserBriefInfo
                  status={reviewee.status}
                  name={reviewee.fullName}
                  email={reviewee.email}
                  avatarUrl={reviewee.avatar || ""}
                  triggerClassName="gap-x-[0.5rem]"
                  className="text-turquoise-75 text-[24px] font-[600] leading-[31.2px]"
                />
              </PageTitle>
            </div>
            <div className="my-6 flex flex-col gap-4">
              <div className="flex gap-2 items-center">
                <span className="text-neutral-100 body-14-regular">
                  {FORM_TYPE_MAP[form.formType]} - Deadline:{" "}
                  {form.dueDate && getDisplayDate(form.dueDate)}
                </span>
              </div>
              <Markdown className="body-16-regular text-neutral-150">
                {form?.description}
              </Markdown>
            </div>
            <FeedbackByReviewee
              questions={form.questions}
              feedbacks={feedbacks || []}
            ></FeedbackByReviewee>
          </div>
        )}
      </LoadingWrapper>
    </Layout>
  );
};

export default PeerFeedback;

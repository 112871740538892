import { PAGE_PATHS } from "constants/page-paths";
import { UserRole } from "enums/user";
import { useLocation, useNavigate } from "react-router-dom";
import {
  employeeMenuItems,
  getAdminMenuItems,
  isAdminMenuActive,
  isMenuItemActive,
} from "utils/menu-items";
import { checkBeingInAdminGroup } from "utils/roles";
import SideBarItem from "./SideBarItem";
import Toggle from "../Toggle";
import { LogoutIcon } from "components/icons";
import Logo from "./Logo";
import { KeycloakService } from "services";
import { toastError } from "utils/toast";
import { useFeedbackRequestStore } from "store";
import { getBadgeNumberForTodoRequest } from "utils/feedback-request.util";

type Props = {
  role: UserRole;
};
const SideBar: React.FC<Props> = ({ role }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const isInAdminGroup = checkBeingInAdminGroup(role);
  const adminToggle = isInAdminGroup ? isAdminMenuActive(pathName) : false;
  const totalsOfTodoRequest = useFeedbackRequestStore(
    (state) => state.totalsOfTodoRequest,
  );

  const menuItems = adminToggle ? getAdminMenuItems(role) : employeeMenuItems;

  const requestMenuItem = menuItems.find(
    (menuItem) => menuItem.to === PAGE_PATHS.REQUEST,
  );
  if (requestMenuItem) {
    const totals = totalsOfTodoRequest.reduce((accumulator, n) => {
      return accumulator + n.total;
    }, 0);
    requestMenuItem.badge = getBadgeNumberForTodoRequest(totals);
  }

  const handleLogout = async () => {
    try {
      await KeycloakService.logout({
        redirectUri: window.location.origin + PAGE_PATHS.LOGIN,
      });
    } catch (error) {
      toastError(
        "Logout unsuccessful due to an unexpected error. Please try again later.",
      );
    }
  };

  const handleChangeToggle = (value: boolean) => {
    if (value) return navigate(PAGE_PATHS.HOME);

    navigate(PAGE_PATHS.REQUEST);
  };

  return (
    <div className="sticky top-0 px-6 py-8 flex flex-col max-h-screen">
      <div className="mb-10">
        <Logo size="large" />
      </div>
      <div className="flex w-[232px] flex-col gap-4 flex-1">
        {isInAdminGroup && (
          <Toggle
            onName="Admin"
            offName="Employee"
            onChange={handleChangeToggle}
            on={adminToggle}
          />
        )}

        <div className="flex flex-grow w-full flex-col justify-between">
          <div className="flex w-full gap-2 flex-col">
            {menuItems.map((item, index) => {
              return (
                <SideBarItem
                  key={index + 1}
                  selected={isMenuItemActive(pathName, item.to)}
                  {...item}
                />
              );
            })}
          </div>
          <SideBarItem
            key={0}
            name={"Log out"}
            Icon={LogoutIcon}
            onClick={handleLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default SideBar;

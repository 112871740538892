import { create } from "zustand";
import { User } from "types/user";

type AuthStore = {
  user?: User;
  resetUser: () => void;
  updateUser: (newUser: User) => void;
};

const useAuthStore = create<AuthStore>((set) => ({
  user: undefined,

  resetUser: () => {
    set({ user: undefined });
  },
  updateUser: (newUser: User) => {
    set({ user: newUser });
  },
}));

export { useAuthStore };

import { LinkButton } from "components/common/forms";
import { FeedbackIcon } from "components/common/ui";
import UserList from "components/common/ui/UserList";
import { EyeIcon } from "components/icons";
import { FORM_TYPE_MAP } from "constants/form-type-map";
import { Form } from "types/form";
import { UserProfile } from "types/user";

type FormFeedbackItemProps = {
  form: Form;
  reviewees: UserProfile[];
  reviewers: UserProfile[];
};

const FormFeedbackItem: React.FC<FormFeedbackItemProps> = ({
  form,
  reviewees,
  reviewers,
}) => {
  const { name, formType, id } = form;
  const formTypeValue = FORM_TYPE_MAP[formType];

  return (
    <div className="flex flex-col bg-blue-10 px-8 py-6 rounded-3xl gap-14">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-1">
          <div className="flex gap-2 items-center">
            <FeedbackIcon type={formType} />
            <span className="body-20-semibold text-neutral-200">{name}</span>
          </div>

          <span className="body-14-regular text-neutral-100">
            {formTypeValue}
          </span>
        </div>
        <div>
          <LinkButton buttonClassName="py-[5px] body-14-semibold" to={`${id}`}>
            <EyeIcon className="w-[24px] h-[24px]" />
            View
          </LinkButton>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col text-neutral-100 gap-1 body-14-bold">
          <div className="flex gap-1">
            <span>{"Reviewer:"}</span>
            <UserList userProfiles={reviewers} />
          </div>
          <div className="flex gap-1">
            <span>{"Reviewee:"}</span>
            <UserList userProfiles={reviewees} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormFeedbackItem;

import { QuestionType } from "enums/form";
import { AnswerWithReviewer } from "types/answer-with-reviewer.type";
import { Question } from "types/question";
import MultipleChoiceFeedbacks from "./MultipleChoiceFeedbacks";
import TextFeedbacks from "./TextFeedbacks";

type QuestionWithFeedbacksProps = {
  question: Question;
  answersWithReviewers: AnswerWithReviewer[];
  showUserProfileCard?: boolean;
  isSingleCol: boolean;
};

const QuestionWithFeedbacks: React.FC<QuestionWithFeedbacksProps> = ({
  question,
  answersWithReviewers,
  showUserProfileCard = false,
  isSingleCol = true,
}) => {
  switch (question.questionType) {
    case QuestionType.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceFeedbacks
          question={question}
          answersWithReviewers={answersWithReviewers}
          showUserProfileCard={showUserProfileCard}
          isSingleCol={isSingleCol}
          hint={question.hint}
        />
      );
    default:
      return (
        <TextFeedbacks
          question={question}
          answersWithReviewers={answersWithReviewers}
          showUserProfileCard={showUserProfileCard}
          isSingleCol={isSingleCol}
          hint={question.hint}
        />
      );
  }
};

export default QuestionWithFeedbacks;

import {
  ChangeEvent,
  ChangeEventHandler,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FormWithQuestion } from "types/form";
import {
  getDate,
  getDateFieldValueWithTimezone,
  getDateTimeWithTimezone,
} from "utils/due-date-form";
import { AsyncDropdown, Button, DateField } from "components/common/forms";
import {
  SendOutPeerFormData,
  SendOutPeerRequest,
} from "types/feedback-request";
import { MultiValue, SingleValue } from "react-select";
import { IMultipleChoiceOption } from "types/input.type";
import { ConfirmModal } from "components/common/modals";
import { User } from "types/user";
import { FieldErrors } from "types/field-errors.type";
import { sendOutPeerSchema } from "schemas";
import { ValidationUtil } from "utils";
import { toastError } from "utils/toast";
import { AMOUNT_OF_DAY_ADDED_TO_DEADLINE } from "constants/date";

type SendOutPeerFormProps = {
  selectedForm?: FormWithQuestion;
  onSelectForm: ChangeEventHandler<HTMLSelectElement>;
  onSubmit: (data: SendOutPeerRequest) => void;
  isSubmitting?: boolean;
  onSearchUser: (searchText: string) => Promise<User[]>;
  setValidationError: (validationError: object) => void;
  validationError: FieldErrors<SendOutPeerFormData>;
};

const SendOutPeerForm: React.FC<SendOutPeerFormProps> = ({
  selectedForm,
  onSubmit,
  isSubmitting,
  onSearchUser,
  setValidationError,
  validationError,
}) => {
  const [formData, setFormData] = useState<Partial<SendOutPeerFormData>>(
    () => ({
      reviewerIds: [],
      dueDate: getDate(AMOUNT_OF_DAY_ADDED_TO_DEADLINE),
    }),
  );
  const [reviewee, setReviewee] = useState<IMultipleChoiceOption | null>(null);
  const [reviewers, setReviewers] = useState<IMultipleChoiceOption[]>([]);

  const [showConfirmSendModal, setShowConfirmSendModal] = useState(false);

  const getExistingReviewers = (): IMultipleChoiceOption[] => {
    const existingReviewee = getExistingReviewee();
    if (existingReviewee && selectedForm?.feedbackRequests) {
      return selectedForm?.feedbackRequests
        .filter((fr) => fr.revieweeId === existingReviewee.value)
        .map((fr) => {
          return {
            value: fr.reviewer.id,
            label: fr.reviewer.email,
            isDisabled: true,
            isRemoveDisabled: true,
          };
        });
    }
    return [];
  };

  const getExistingReviewee = (): IMultipleChoiceOption | null => {
    return selectedForm?.feedbackRequests &&
      selectedForm.feedbackRequests.length
      ? {
          value: selectedForm?.feedbackRequests[0].reviewee.id,
          label: selectedForm?.feedbackRequests[0].reviewee.email,
        }
      : null;
  };

  useEffect(() => {
    const existingReviewee = getExistingReviewee();
    const existingReviewers = getExistingReviewers();

    setReviewee(existingReviewee);
    setReviewers(existingReviewers);

    setFormData({
      ...formData,
      formId: selectedForm?.id,
      dueDate:
        selectedForm?.dueDate || getDate(AMOUNT_OF_DAY_ADDED_TO_DEADLINE),
    });
  }, [selectedForm]);

  const getFormDataWithRevieweeAndReviewers = (): SendOutPeerFormData => {
    return {
      ...formData,
      revieweeId: reviewee ? (reviewee.value as number) : undefined,
      reviewerIds: reviewers.map((reviewer) => reviewer.value as number),
    } as SendOutPeerFormData;
  };

  const handleChangeReviewee = (
    selectedOption: SingleValue<IMultipleChoiceOption>,
  ) => {
    setReviewee(selectedOption as IMultipleChoiceOption);
  };

  const handleChangeReviewers = (
    selectedOptions: MultiValue<IMultipleChoiceOption>,
  ) => {
    const existingReviewers = getExistingReviewers();

    const newlySelectedReviewers = (
      selectedOptions as IMultipleChoiceOption[]
    ).filter((option) => !option.isRemoveDisabled);

    setReviewers([...existingReviewers, ...newlySelectedReviewers]);
  };

  const handleChangeDueDate = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const newDueDate: Date = getDateTimeWithTimezone(value).toDate();

    const newFormData = {
      ...formData,
      dueDate: newDueDate,
    };

    setFormData(newFormData);
  };

  const handleSubmit = () => {
    const formDataToSubmit = getFormDataWithRevieweeAndReviewers();
    onSubmit(formDataToSubmit as SendOutPeerRequest);
    handleCloseConfirmModal();
  };

  const validateForm = () => {
    const formDataToValidate = getFormDataWithRevieweeAndReviewers();

    const { error } = sendOutPeerSchema.validate(formDataToValidate);

    if (error) {
      const newValidationError = ValidationUtil.transformValidationError(error);
      setValidationError(newValidationError);
      toastError(error.message);
      return false;
    }

    return true;
  };

  const handleShowConfirmSendModal = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) return;

    setShowConfirmSendModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmSendModal(false);
  };

  const handleSearchReviewer = useCallback(
    async (searchText: string) => {
      const users = await onSearchUser(searchText);

      const options = users.map<IMultipleChoiceOption>(({ email, id }) => ({
        label: email,
        value: id,
        isDisabled: id === reviewee?.value,
        isRemoveDisabled: selectedForm?.feedbackRequests
          ?.map((fr) => fr.reviewerId)
          .includes(id),
      }));

      return options;
    },
    [reviewee, selectedForm],
  );

  const handleSearchReviewee = useCallback(
    async (searchText: string) => {
      const users = await onSearchUser(searchText);

      const options = users.map<IMultipleChoiceOption>(({ email, id }) => ({
        label: email,
        value: id,
        isDisabled: reviewers.map((reviewer) => reviewer.value)?.includes(id),
      }));

      return options;
    },
    [reviewers],
  );

  const handleClearErrorValidation = () => {
    setValidationError({});
  };

  return (
    <form
      className="flex-1 flex flex-col gap-8"
      onSubmit={handleShowConfirmSendModal}
    >
      <div className="flex flex-col gap-6">
        <AsyncDropdown<false>
          id="reviewee"
          label="Reviewee"
          hint="Please enter the email of the person whose feedback is given to"
          value={reviewee}
          onChange={handleChangeReviewee}
          request={handleSearchReviewee}
          errorMessage={validationError?.revieweeId}
          onClearError={handleClearErrorValidation}
          isDisabled={!!selectedForm?.dueDate}
        />

        <AsyncDropdown<true>
          isMulti
          id="reviewers"
          value={reviewers}
          label="Choose your reviewer(s)"
          onChange={handleChangeReviewers}
          request={handleSearchReviewer}
          errorMessage={validationError?.reviewerIds}
          onClearError={handleClearErrorValidation}
        />

        <DateField
          id="form-due-date"
          label="Deadline"
          hint="Deadline for this survey"
          value={
            formData.dueDate
              ? getDateFieldValueWithTimezone(formData.dueDate)
              : ""
          }
          onChange={handleChangeDueDate}
          name="dueDate"
          errorMessage={validationError?.dueDate}
          onClearError={handleClearErrorValidation}
        />
      </div>

      <Button label="Send" className="w-full" type="submit" size="large" />
      <ConfirmModal
        open={showConfirmSendModal}
        title="Send out form"
        content="Are you sure you want to send this form to users?"
        yesButton="Yes"
        noButton="No"
        onYes={handleSubmit}
        onNo={handleCloseConfirmModal}
        yesButtonLoading={isSubmitting}
      />
    </form>
  );
};

export default SendOutPeerForm;

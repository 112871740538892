import classNames from "classnames";
import { IMultipleChoiceOption } from "types/input.type";

interface IRadioGroupProps {
  id: string | number;
  option: IMultipleChoiceOption;
  isSelected: boolean;
  name: string;
  onChange?: (value: string | number) => void;
}

const RadioButton: React.FC<IRadioGroupProps> = ({
  id,
  option,
  isSelected,
  name,
  onChange,
}) => {
  const handleOnChange = () => {
    if (onChange && !option.isDisabled) {
      onChange(option.value);
    }
  };

  const idStr = String(id);

  return (
    <div className="flex items-center custom-radio">
      <input
        id={idStr}
        type="radio"
        checked={isSelected}
        name={name}
        className="w-4 h-4"
        disabled={option.isDisabled === true}
        value={option.value}
        onChange={handleOnChange}
      />
      <span
        className="radio-mark flex-shrink-0"
        onClick={handleOnChange}
      ></span>
      <label
        htmlFor={idStr}
        className={classNames("ml-2 text-sm", {
          "font-medium": isSelected,
        })}
      >
        {option.label}
      </label>
    </div>
  );
};

export default RadioButton;

import CreateQuestion from "components/form/Question";
import { FormType, QuestionType } from "enums/form";
import { ChangeEvent, FormEvent, useMemo } from "react";
import { CreateEditFormRequest, FormWithQuestion } from "types/form";
import { createQuestionObject } from "utils/question";
import { Question } from "types/question";
import { ArrayUtil } from "utils";

import {
  Button,
  DropdownField,
  TextArea,
  TextField,
} from "components/common/forms";
import {
  MAXIMUM_FORM_TITLE,
  MAXIMUM_SLACK_TEXT_FIELD,
} from "constants/form-fields.constant";
import { FieldErrors } from "types/field-errors.type";

const DEFAULT_ADD_QUESTION_TYPE = QuestionType.TEXT;

type FormProps = {
  form: FormWithQuestion;
  onChange: (newForm: FormWithQuestion) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  formTypeDisabled?: boolean;
  validationError?: FieldErrors<CreateEditFormRequest>;
  onClearValidationError?: () => void;
};
const CreateEditForm: React.FC<FormProps> = ({
  form,
  onChange,
  onSubmit,
  formTypeDisabled,
  validationError,
  onClearValidationError,
}) => {
  const { name, description, formType, questions } = form;

  const formOptions = {
    [FormType.THREE_HUNDRED_SIXTY]: "360 Feedback",
    [FormType.PEER]: "Peer Feedback",
  };

  const handleChangeField = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newForm: FormWithQuestion = {
      ...form,
      [e.target.name]: e.target.value,
    };
    onChange(newForm);
  };

  const handleChangeDescription = (name: string, value: string) => {
    const newForm: FormWithQuestion = {
      ...form,
      [name]: value,
    };
    onChange(newForm);
  };

  const handleChangeFormType = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as FormType;

    const newForm: FormWithQuestion = {
      ...form,
      formType: value,
    };

    onChange(newForm);
  };

  const handleChangeQuestions = (newQuestions: Question[]) => {
    const newForm: FormWithQuestion = {
      ...form,
      questions: newQuestions,
    };
    onChange(newForm);
  };
  const handleAddQuestion = (type: QuestionType) => {
    const newQuestion: Question = createQuestionObject(type, {
      id: -Date.now(),
      sequence: questions.length,
      label: "",
      hint: "",
    });
    handleChangeQuestions([...questions, newQuestion]);
  };
  const handleChangeQuestion = (id: number, newQuestion: Question) => {
    const newQuestions = questions.map((question) =>
      question.id === id ? newQuestion : question,
    );
    handleChangeQuestions(newQuestions);
  };
  const handleDeleteQuestion = (sequence: number) => {
    const newQuestions = questions.reduce((res, question) => {
      if (question.sequence === sequence) return res;
      if (question.sequence < sequence) res.push(question);
      else {
        const newQuestion = {
          ...question,
          sequence: question.sequence - 1,
        };
        res.push(newQuestion);
      }
      return res;
    }, [] as Question[]);
    handleChangeQuestions(newQuestions);
  };
  const canMoveQuestionUp = (sequence: number) => sequence !== 0;
  const canMoveQuestionDown = (sequence: number) =>
    sequence !== questions.length - 1;
  const swapPosition = (sequence1: number, sequence2: number): void => {
    const newQuestions = questions.map((question) => {
      if (question.sequence === sequence1)
        return { ...question, sequence: sequence2 };
      if (question.sequence === sequence2)
        return { ...question, sequence: sequence1 };
      return question;
    });
    handleChangeQuestions(newQuestions);
  };
  const handleMoveQuestionUp = (sequence: number) => {
    if (!canMoveQuestionUp(sequence)) return;
    const prevSequence = sequence - 1;
    swapPosition(sequence, prevSequence);
  };
  const handleMoveQuestionDown = (sequence: number) => {
    if (!canMoveQuestionDown(sequence)) return;
    const nextSequence = sequence + 1;
    swapPosition(sequence, nextSequence);
  };
  const sortedQuestions = useMemo(
    () => ArrayUtil.sortAscBySequence(questions),
    [questions],
  );
  return (
    <form className="flex-1 flex flex-col gap-6" onSubmit={onSubmit}>
      <DropdownField
        label="Form type"
        hint="Either 360 Form or Peer Feedback Form"
        options={formOptions}
        value={formType}
        onChange={handleChangeFormType}
        id={"form-type"}
        name="formType"
        disabled={formTypeDisabled}
        errorMessage={validationError?.formType}
        onClearError={onClearValidationError}
      />

      <TextField
        id={"form-name"}
        label={"Form name"}
        hint={
          "Give your form a name. Form name might be useful for reminding you what the form is"
        }
        value={name}
        onChange={handleChangeField}
        maxLength={MAXIMUM_FORM_TITLE}
        name="name"
        errorMessage={validationError?.name}
        onClearError={onClearValidationError}
      />

      <TextArea
        id={"form-description"}
        label={"Form description"}
        hint={"Write more information about this form"}
        value={description}
        onTextFormatterChange={handleChangeDescription}
        maxLength={MAXIMUM_SLACK_TEXT_FIELD}
        name="description"
        errorMessage={validationError?.description}
        onClearError={onClearValidationError}
      />
      <div className="flex justify-between gap-6 mt-12">
        <Button
          label="Add question"
          type="button"
          variant="secondary"
          onClick={() => handleAddQuestion(DEFAULT_ADD_QUESTION_TYPE)}
          className="flex-1 h-full"
        />
        <Button label="Save form" type="submit" className="flex-1 h-full" />
      </div>
      <div className="flex flex-col gap-6">
        {sortedQuestions.map((question) => {
          const { id, sequence } = question;
          return (
            <CreateQuestion
              question={question}
              key={id}
              onChange={(newQuestion: Question) =>
                handleChangeQuestion(id, newQuestion)
              }
              canMoveUp={canMoveQuestionUp(sequence)}
              canMoveDown={canMoveQuestionDown(sequence)}
              onMoveUp={() => handleMoveQuestionUp(sequence)}
              onMoveDown={() => handleMoveQuestionDown(sequence)}
              onRemove={() => handleDeleteQuestion(sequence)}
              validationError={validationError?.questions?.[sequence]}
              onClearValidationError={onClearValidationError}
              formType={formType}
            />
          );
        })}
      </div>
    </form>
  );
};

export default CreateEditForm;

import { PAGE_PATHS } from "constants/page-paths";
import { FormType } from "enums/form";
import { FormEvent, useEffect, useState } from "react";
import { CreateEditFormRequest, FormWithQuestion } from "types/form";
import { toastError, toastSuccess } from "utils/toast";
import { editFormSchema } from "schemas/edit-form-schema";
import { sanitizeQuestionRequest } from "utils/question";
import { Question } from "types/question";
import { useNavigate } from "react-router-dom";
import { FormService } from "services";
import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import { CreateEditForm } from "components/form";
import {
  useError,
  useFormBlockNavigation,
  useHttpMutationService,
  useHttpQueryService,
  useLoading,
  useNumericParam,
} from "hooks";
import { BackButton } from "components/common/forms";
import { ConfirmModal } from "components/common/modals";
import { isEqual } from "lodash";
import { ValidationUtil } from "utils";
import { FieldErrors } from "types/field-errors.type";
import { PreviewForm } from "components/preview";

const INITIAL_FORM: FormWithQuestion = {
  id: 0,
  name: "",
  description: "",
  formType: FormType.THREE_HUNDRED_SIXTY,
  questions: [],
};
const EditForm: React.FC = () => {
  const id = useNumericParam("id");
  const [form, setForm] = useState<FormWithQuestion>(INITIAL_FORM);
  const { name, description, questions, formType } = form;
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState<
    FieldErrors<CreateEditFormRequest>
  >({});

  const {
    result: fetchedForm,
    error: fetchError,
    isLoading: isFetching,
  } = useHttpQueryService({
    request: () => FormService.getFormEditById(id),
    requestOption: { enabled: !!id },
  });

  const {
    result: response,
    mutate,
    isLoading: isSubmitting,
    error: submitError,
  } = useHttpMutationService({
    request: (data?: CreateEditFormRequest) => FormService.update(data!),
  });

  useEffect(() => {
    if (fetchedForm) setForm(fetchedForm);
  }, [fetchedForm]);

  const isLoading = useLoading([isFetching, isSubmitting]);
  useError(fetchError, true);
  useError(submitError);

  const handleChange = (newForm: FormWithQuestion) => {
    setForm(newForm);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const editQuestionDto = questions.map((question: Question) =>
      sanitizeQuestionRequest(question),
    );
    const data: CreateEditFormRequest = {
      id: id,
      name,
      formType,
      description,
      questions: editQuestionDto,
    };

    const { error, value: validatedData } = editFormSchema.validate(data);

    if (error) {
      const newValidationError =
        ValidationUtil.transformValidationError<CreateEditFormRequest>(error);

      setValidationError(newValidationError);
      toastError(error.message);
      return;
    }

    const response = await mutate(validatedData);

    if (response) {
      setFormIsDirty(false);
      toastSuccess("Form is updated successfully!");
    }
  };

  const {
    formIsDirty,
    setFormIsDirty,
    showLeaveModal,
    handleCancelLeaveModal,
    handleConfirmLeaveModal,
  } = useFormBlockNavigation();

  useEffect(() => {
    const formIsDirty = !isEqual(form, fetchedForm);
    setFormIsDirty(formIsDirty);
  }, [form]);
  const handleClearErrorValidation = () => {
    setValidationError({});
  };

  useEffect(() => {
    if (!response || formIsDirty) return;

    navigate(PAGE_PATHS.FORM);
  }, [response, formIsDirty]);

  return (
    <Layout>
      <LoadingWrapper isLoading={isLoading}>
        <div className="w-full h-fit pb-8">
          <div className="flex gap-6 items-center mb-6">
            <BackButton className="text-neutral-200" />
            <PageTitle>Edit Form</PageTitle>
          </div>
          <div className="flex gap-8">
            <CreateEditForm
              form={form}
              onChange={handleChange}
              onSubmit={handleSubmit}
              formTypeDisabled
              validationError={validationError}
              onClearValidationError={handleClearErrorValidation}
            />
            <PreviewForm
              name={name}
              questions={questions}
              className={"flex-1 h-fit"}
            />
          </div>
        </div>
        <ConfirmModal
          open={showLeaveModal}
          title="Leave Page?"
          content="Please be aware that any of your unsaved actions will be discarded. Proceed anyway?"
          yesButton="Confirm"
          noButton="Cancel"
          onYes={handleConfirmLeaveModal}
          onNo={handleCancelLeaveModal}
        />
      </LoadingWrapper>
    </Layout>
  );
};

export default EditForm;

import { AnswersGroupedByQuestion } from "types/answer-with-reviewer.type";
import { FormWithQuestion } from "types/form";
import FeedbackByForm from "./FeedbackByForm";

type FormWithFeedbackProps = {
  form: FormWithQuestion;
  answersGroupedByQuestion: AnswersGroupedByQuestion;
};
const FormWithFeedback: React.FC<FormWithFeedbackProps> = ({
  form,
  answersGroupedByQuestion,
}) => {
  const { questions } = form;

  return (
    <div className="px-8 pt-8 pb-20 bg-blue-10 rounded-3xl">
      <FeedbackByForm
        questions={questions}
        answersGroupedByQuestion={answersGroupedByQuestion}
        showUserProfileCard
        isSingleCol={true}
      />
    </div>
  );
};

export default FormWithFeedback;

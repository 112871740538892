import { UserProfileResponse } from "responses";
import { UserProfile } from "types/user";

export const ANONYMOUS_DISPLAY_NAME = "Anonymous";

export const randomAnonymousUserId = (): string => {
  return Math.random().toString(36);
};

export const transformToUserProfile = (
  user?: UserProfileResponse,
  isCurrentUser?: boolean,
  anonymousText = ANONYMOUS_DISPLAY_NAME,
): UserProfile => {
  if (!user) {
    return {
      displayName: anonymousText,
    };
  }

  const { email, id, avatar, fullName, status } = user;

  if (isCurrentUser) {
    return { displayName: "You", id, avatar };
  }

  return { displayName: fullName, id, avatar, email, status };
};

export const getReviewersInfoToDisplay = (
  rawReviewers: UserProfileResponse[],
): UserProfile[] => {
  const anonymousReviewer = rawReviewers.find(({ id }) => !id);

  if (anonymousReviewer) {
    return [
      transformToUserProfile(undefined, undefined, "Anonymous Reviewer(s)"),
    ];
  }

  return rawReviewers.map((reviewer) => transformToUserProfile(reviewer));
};

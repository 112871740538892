import LoginCarousel from "components/carousels/LoginCarousel";
import { Logo } from "components/common/layouts";
import { PAGE_PATHS } from "constants/page-paths";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAuthStore } from "store";
import { Button } from "components/common/forms";
import { KeycloakService } from "services";

const Login: React.FC = () => {
  const [searchParams] = useSearchParams();
  const user = useAuthStore((state) => state.user);

  const handleLogin = () => {
    KeycloakService.login();
  };

  if (user) {
    const encodedRedirectTo = searchParams.get("redirectTo");
    const pathName = encodedRedirectTo
      ? decodeURIComponent(encodedRedirectTo)
      : PAGE_PATHS.HOME;

    return <Navigate to={pathName} replace />;
  }

  return (
    <div className="w-screen h-screen flex">
      <div className="flex-1 h-full">
        <div className="flex flex-col h-full py-8 px-6">
          <Logo size="large" className="mb-5" />
          <div className="flex-auto flex flex-col justify-center items-center gap-2">
            <div className="flex gap-4">
              <h4>Welcome back!</h4>
              <img
                src="/images/waving-hand.png"
                alt="waving hand icon"
                width={50}
              />
            </div>
            <div className="flex flex-col gap-4 items-center">
              <span className="body-16-regular text-neutral-100">
                Enter your credentials to access your account
              </span>
              <Button onClick={handleLogin} size="large" className="w-full">
                Log in
              </Button>
            </div>
          </div>
          <div className="self-center">
            <span className="body-14-regular text-neutral-50">
              2023 © CodeLink Limited. All right reserved.
            </span>
          </div>
        </div>
      </div>
      <LoginCarousel />
    </div>
  );
};

export default Login;

import { DropdownProps } from "components/common/forms/DropdownField";
import { BackButton } from "components/common/forms";
import {
  CustomizedTabs,
  Layout,
  LoadingWrapper,
  PageTitle,
} from "components/common/layouts";
import { PAGE_PATHS } from "constants/page-paths";
import { useError, useHttpQueryService } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormService } from "services";
import { FormWithQuestion } from "types/form";
import MyReviewersTab from "./MyReviewersTab";
import Request360ReviewerTab from "./Request360ReviewerTab";
import FormPreviewSectionWrapper from "./FormPreviewSectionWrapper";
import { RequestAReviewTab } from "enums/request-a-review-tab.enum";

const Request360Feedback: React.FC = () => {
  const [selectedForm, setSelectedForm] = useState<FormWithQuestion>();
  const selectedFormId = selectedForm?.id;
  const navigate = useNavigate();
  const [isExpandingPreviewSection, setIsExpandingPreviewSection] =
    useState<boolean>(true);
  const [tabIndex, setTabIndex] = useState(RequestAReviewTab.RequestForm);

  const {
    result: forms,
    isLoading: formsLoading,
    error: formsFetchError,
  } = useHttpQueryService({
    request: () => FormService.getRunning360Forms(),
  });

  useEffect(() => {
    if (!forms) return;

    if (
      forms.length === 0 ||
      !forms[0].dueDate ||
      forms[0].dueDate < new Date()
    ) {
      navigate(PAGE_PATHS.HOME, { replace: true });
    }

    setSelectedForm(forms[0]);
  }, [forms]);

  const formOptions = useMemo(() => {
    if (!forms) return {};

    return forms.reduce(
      (res, form) => {
        res[form.id] = form.name;
        return res;
      },
      {} as DropdownProps["options"],
    );
  }, [forms]);

  const onSelectForm = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!forms) return;

    const form = forms.find((form) => form.id === Number(e.target.value));
    if (!form) return;

    setSelectedForm(form);
  };

  const panels: React.ReactNode[] = useMemo(
    () => [
      <Request360ReviewerTab
        key={"request"}
        selectedForm={selectedForm}
        onSelectForm={onSelectForm}
        formOptions={formOptions}
      />,
      <MyReviewersTab key={"my-reviewers"} formId={selectedFormId} />,
    ],
    [selectedFormId],
  );

  useError(formsFetchError, true);

  const handleCollapsePreviewSection = () => {
    setIsExpandingPreviewSection(false);
  };

  const handleClickPreviewIcon = () => {
    setIsExpandingPreviewSection((prev) => !prev);
  };

  return (
    <Layout mainClassName="!p-0">
      <LoadingWrapper isLoading={formsLoading}>
        <div className="flex justify-between h-full relative">
          <div className="h-fit flex-auto min-h-[200px] px-8 py-8">
            <div className="flex gap-6 items-center mb-6">
              <BackButton className="text-neutral-200" />
              <PageTitle>Request a 360 Review</PageTitle>
            </div>
            <CustomizedTabs
              titles={["Request a reviewer", "List of my reviewers"]}
              panels={panels}
              tabIndex={tabIndex}
              onSelect={setTabIndex}
            />
          </div>
          {tabIndex === RequestAReviewTab.RequestForm && selectedForm && (
            <FormPreviewSectionWrapper
              isExpanding={isExpandingPreviewSection}
              onCollapse={handleCollapsePreviewSection}
              onChange={handleClickPreviewIcon}
              form={selectedForm}
            />
          )}
        </div>
      </LoadingWrapper>
    </Layout>
  );
};

export default Request360Feedback;

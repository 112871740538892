import { Form } from "types/form";
import { getDisplayDate } from "utils/date";
import { Link } from "react-router-dom";
import { Column } from "react-table";
import { useMemo } from "react";
import { Button, Table } from "components/common/forms";
import { DeleteIcon } from "components/icons";

type TableFormProps = {
  forms: Form[];
  onDeleteForm: (id: number) => void;
};
const TableForm: React.FC<TableFormProps> = ({ forms, onDeleteForm }) => {
  const columns: Column<Form>[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        id: "name",
      },
      {
        Header: "Type",
        accessor: "formType",
        id: "formType",
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        id: "createdAt",
        Cell: ({ cell: { value } }) => {
          const dateString = value ? getDisplayDate(value) : "-";
          return <span>{dateString}</span>;
        },
      },
      {
        Header: "Deadline",
        accessor: "dueDate",
        id: "dueDate",
        Cell: ({ cell: { value } }) => {
          const dateString = value ? getDisplayDate(value) : "-";
          return <span>{dateString}</span>;
        },
      },
      {
        Header: "Publish Date",
        accessor: "publishDate",
        id: "publishDate",
        Cell: ({ cell: { value } }) => {
          const dateString = value ? getDisplayDate(value) : "-";
          return <span>{dateString}</span>;
        },
      },
      {
        Header: () => null,
        id: "buttons",
        Cell: ({ row }: { row: { original: Form } }) => (
          <div className="flex items-center justify-end gap-4">
            <Link to={`${row.original.id}`}>
              <Button label="Edit" variant="secondary" />
            </Link>
            <Button
              onClick={() => onDeleteForm(row.original.id)}
              variant="secondary"
            >
              <DeleteIcon className="w-6 h-6" />
            </Button>
          </div>
        ),
      },
    ],
    [onDeleteForm],
  );
  return <Table columns={columns} data={forms} />;
};

export default TableForm;

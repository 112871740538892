import { QuestionType } from "enums/form";
import { Question } from "types/question";
import PreviewMultipleChoiceQuestion from "./PreviewMultipleChoiceQuestion";
import PreviewTextQuestion from "./PreviewTextQuestion";
import PreviewCheckboxQuestion from "./PreviewCheckboxQuestion";

type PreviewQuestionProps = {
  question: Question;
};
const PreviewQuestion: React.FC<PreviewQuestionProps> = ({ question }) => {
  const { questionType } = question;
  switch (questionType) {
    case QuestionType.MULTIPLE_CHOICE:
      return <PreviewMultipleChoiceQuestion question={question} />;
    case QuestionType.CHECK_BOXES:
      return <PreviewCheckboxQuestion question={question} />;
    default:
      return <PreviewTextQuestion question={question} />;
  }
};

export default PreviewQuestion;

import cx from "classnames";
import { LoadingIcon } from "components/icons";
import { useOutsideClick } from "hooks";
import { DropdownItemType } from "pages/360-feedback-list/Three60FeedbackList";
import { useCallback } from "react";

type ExportDropdownButtonProps = {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  id: number;
  items: DropdownItemType[];
  isOpen: boolean;
  onOpenChange: (id: number) => void;
};

const ExportDropdownButton: React.FC<ExportDropdownButtonProps> = ({
  Icon,
  id,
  items,
  isOpen,
  onOpenChange,
}) => {
  const handleCloseDropdown = useCallback(() => {
    if (isOpen) onOpenChange(id);
  }, []);

  const ref = useOutsideClick<HTMLDivElement>(handleCloseDropdown);

  const handleOpenChange = () => {
    onOpenChange(id);
  };

  return (
    <div className="hover:cursor-pointer relative overflow-visible" ref={ref}>
      <button
        className={cx(
          "text-neutral-100 hover:text-turquoise-75  focus:text-turquoise-75 disabled:text-neutral-25 px-4 py-2",
          {
            "text-turquoise-75": open,
          },
        )}
        onClick={handleOpenChange}
      >
        <Icon />
      </button>
      {isOpen && (
        <div className="absolute top-[40px] overflow-visible right-0 border  border-neutral-25 bg-neutral-0 p-2 rounded-lg flex flex-col gap-2 z-10 shadow-md">
          {items.map(({ label, isExporting, onAction }, index) => {
            return (
              <div
                className={cx(
                  "w-[320px] h-10 pl-4 rounded-md hover:bg-neutral-15 flex items-center body-16-regular gap-4",
                  {
                    "hover:bg-transparent cursor-not-allowed": isExporting,
                  },
                )}
                key={index}
                onClick={() => {
                  onAction(index);
                }}
              >
                {isExporting && <LoadingIcon className="animate-spin" />}

                {label}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ExportDropdownButton;

import UserBriefInfo from "components/user/UserBriefInfo";
import { UserProfile } from "types/user";

type UserProfileCardProps = {
  userProfile: UserProfile;
};

const UserProfileCard: React.FC<UserProfileCardProps> = ({ userProfile }) => {
  return (
    <UserBriefInfo
      status={userProfile.status}
      name={userProfile.displayName}
      email={userProfile.email || ""}
      avatarUrl={userProfile.avatar || ""}
      triggerClassName="gap-x-[0.5rem]"
      className="text-turquoise-75 body-16-medium leading-[31.2px]"
    />
  );
};

export default UserProfileCard;

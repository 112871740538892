import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { forwardRef, useImperativeHandle } from "react";
import { remark } from "remark";
import { unified } from "unified";
import remarkHtml from "remark-html";
import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import remarkStringify from "remark-stringify";

import Toolbar from "./Toolbar";
import { cn } from "lib/utils";

const markdownToHtml = (markdownText: string) => {
  const file = remark().use(remarkHtml).processSync(markdownText);
  return String(file);
};

const htmlToMarkdown = (htmlText: string) => {
  const file = unified()
    .use(rehypeParse)
    .use(rehypeRemark)
    .use(remarkStringify)
    .processSync(htmlText);

  return String(file);
};

export type TipTapRef = {
  setContent: (content: string) => void;
};

interface TiptapProps {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  errorMessage?: string;
}
const Tiptap = forwardRef<TipTapRef, TiptapProps>((props, ref) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        strike: false,
        code: false,
        heading: {
          levels: [1, 2],
        },
      }),
    ],
    editable: !props.disabled,
    content: markdownToHtml(props.value!),
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      const mdValue = htmlToMarkdown(html);
      props.onChange?.(mdValue);
    },
  });

  useImperativeHandle(ref, () => ({
    setContent(content: string) {
      if (editor) {
        editor?.commands.setContent(content);
      }
    },
  }));

  return (
    <div
      className={cn("border border-neutral-25 rounded-2xl bg-white", {
        "pointer-events-none": props.disabled,
        "border-state-error-100": props.errorMessage,
      })}
    >
      {!!editor && <Toolbar editor={editor} />}
      <EditorContent
        onClick={() => {
          editor?.chain().focus();
        }}
        disabled={props.disabled}
        editor={editor}
        className={
          "px-[1rem] pt-[0.5rem] pb-[1rem] [&>*:focus]:outline-none min-h-[10rem] max-h-[20rem] overflow-auto"
        }
      />
    </div>
  );
});
Tiptap.displayName = "TipTap";

export default Tiptap;

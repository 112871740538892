import { useAuthStore } from "store";

const NoToDoFeedbackRequest: React.FC = () => {
  const user = useAuthStore((state) => state.user);

  if (!user) return null;

  return (
    <div className="flex flex-col h-full">
      <p className="pb-4">
        You don&#39;t have any item in your todo list. Please come back at a
        later time!
      </p>
      <img
        src="/images/employee-home-page-image.png"
        alt="main home image"
        height={457}
        className="rounded-3xl mt-auto"
      />
    </div>
  );
};

export default NoToDoFeedbackRequest;

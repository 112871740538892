import Markdown from "react-markdown";
import { Answer } from "types/answer";
import { User } from "types/user";
import { UserUtil } from "utils";
import UserProfileCard from "./UserProfileCard";

type TextFeedbackProps = {
  answer: Answer;
  reviewer?: User;
  showUserProfileCard?: boolean;
};

const TextFeedback: React.FC<TextFeedbackProps> = ({
  answer,
  reviewer,
  showUserProfileCard = false,
}) => {
  const userProfile = UserUtil.transformToUserProfile(reviewer);

  return (
    <div className="flex flex-col gap-2">
      {showUserProfileCard && <UserProfileCard userProfile={userProfile} />}
      <Markdown className="body-16-regular">{answer.answerText!}</Markdown>
    </div>
  );
};

export default TextFeedback;

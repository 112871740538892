import { BackButton, Button } from "components/common/forms";
import { Layout, LoadingWrapper, PageTitle } from "components/common/layouts";
import { ConfirmModal } from "components/common/modals";
import { FeedbackIcon, InfoMessageBlockFor360 } from "components/common/ui";
import UserBriefInfo from "components/user/UserBriefInfo";
import { FORM_TYPE_MAP } from "constants/form-type-map";
import { PAGE_PATHS } from "constants/page-paths";
import { FormType, QuestionType } from "enums/form";
import {
  useError,
  useFormBlockNavigation,
  useHttpMutationService,
  useHttpQueryService,
  useLoading,
  useNumericParam,
} from "hooks";
import { usePreviousPageNavigation } from "hooks/previous-page-navigation";
import { ValidationError } from "joi";
import { useCallback, useEffect, useMemo, useState } from "react";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { FeedbackIProvidedResponse } from "responses";
import { createFeedbackSchema } from "schemas";
import { saveDraftFeedbackSchema } from "schemas/feedback-save-draft.schema";
import { FeedbackRequestService, FeedbackService, FormService } from "services";
import {
  CreateAnswerRequest,
  DraftFeedbackPayload,
  SubmitFeedbackPayload,
} from "types/answer";
import { CreateFeedBackRequest } from "types/feedback";
import { Question } from "types/question";
import { ArrayUtil } from "utils";
import { getDisplayDate } from "utils/date";
import { toastError, toastSuccess } from "utils/toast";
import AnswerItem from "./AnswerItem/AnswerItem";

const isExistedAnswer = (
  answers: CreateAnswerRequest[],
  questionId: number,
  answerOptionId?: number,
): boolean => {
  if (answerOptionId) {
    return answers.some(
      (answer) =>
        answer.questionId === questionId &&
        answer.answerOptionId === answerOptionId,
    );
  } else {
    return answers.some((answer) => answer.questionId == questionId);
  }
};

const CreateFeedBack: React.FC = () => {
  const navigate = useNavigate();
  const id = useNumericParam("id");
  const [showConfirmSubmitModal, setShowConfirmSubmitModal] =
    useState<boolean>(false);
  const {
    result: feedbackRequest,
    error: feedbackRequestFetchError,
    isLoading: isFeedbackLoading,
  } = useHttpQueryService({
    request: () => FeedbackRequestService.getById(id),
    requestOption: { enabled: !!id },
  });
  const [validation, setValidation] = useState<ValidationError | undefined>();

  const {
    result: form,
    error: formFetchError,
    isLoading: isFormLoading,
  } = useHttpQueryService({
    request: () =>
      FormService.getFormViewById(feedbackRequest!.formId, {
        feedbackRequestId: id,
      }),
    requestOption: { enabled: !!feedbackRequest },
  });

  const {
    refetch: refetchDraft,
    result: draftedFeedback,
    error: draftedFeedbackFetchError,
    isLoading: isDraftedFeedbackLoading,
  } = useHttpQueryService({
    request: () => FeedbackService.getFeedbackDraftByFeedbackRequestId(id),
    requestOption: { enabled: !!feedbackRequest },
  });

  const [answers, setAnswers] = useState<CreateAnswerRequest[]>([]);

  const { navigateToPreviousPage } = usePreviousPageNavigation();

  const getInitialDraftedAnswers = (
    draftedFeedback: FeedbackIProvidedResponse | undefined,
  ): CreateAnswerRequest[] | null => {
    if (!draftedFeedback) return null;
    return draftedFeedback.answers.map(
      ({ questionId, answerText, answerOptionId }) => {
        const draftAnswer = { questionId, answerText, answerOptionId };
        if (draftAnswer.answerOptionId === null)
          delete draftAnswer.answerOptionId;
        if (draftAnswer.answerText === null) delete draftAnswer.answerText;
        return draftAnswer;
      },
    );
  };

  useEffect(() => {
    const initialDraftedAnswers = getInitialDraftedAnswers(draftedFeedback);
    if (initialDraftedAnswers) {
      setAnswers(initialDraftedAnswers);
    }
  }, [draftedFeedback]);

  // Handle error.
  useEffect(() => {
    if (
      feedbackRequestFetchError ||
      formFetchError ||
      draftedFeedbackFetchError
    ) {
      return navigate(PAGE_PATHS.PAGE_NOT_FOUND, { replace: true });
    }
  }, [feedbackRequestFetchError, formFetchError, draftedFeedbackFetchError]);

  const handleChange = (question: Question, value: string | number) => {
    const { id: questionId, questionType } = question;
    // clear all error messages.
    setValidation(undefined);
    switch (questionType) {
      case QuestionType.MULTIPLE_CHOICE:
        handleMultipleChoiceQuestionChange(questionId, +value);
        break;
      case QuestionType.CHECK_BOXES:
        handleCheckBoxesQuestionChange(questionId, +value);
        break;
      default:
        handleTextQuestionChange(questionId, String(value));
        break;
    }
  };

  const handleTextQuestionChange = (questionId: number, value: string) => {
    if (isExistedAnswer(answers, questionId)) {
      setAnswers((prev) =>
        prev.map((answer) => {
          if (answer.questionId == questionId) {
            return { ...answer, answerText: value };
          } else {
            return answer;
          }
        }),
      );
    } else {
      setAnswers((prev) => [
        ...prev,
        { questionId: questionId, answerText: value },
      ]);
    }
  };

  const handleMultipleChoiceQuestionChange = (
    questionId: number,
    value: number,
  ) => {
    if (isExistedAnswer(answers, questionId)) {
      setAnswers((prev) =>
        prev.map((answer) => {
          if (answer.questionId == questionId) {
            return { ...answer, answerOptionId: value };
          } else {
            return answer;
          }
        }),
      );
    } else {
      setAnswers((prev) => [
        ...prev,
        { questionId: questionId, answerOptionId: value },
      ]);
    }
  };

  const handleCheckBoxesQuestionChange = (
    questionId: number,
    value: number,
  ) => {
    if (isExistedAnswer(answers, questionId, value)) {
      setAnswers((prev) =>
        prev.filter(
          (answer) =>
            answer.questionId !== questionId || answer.answerOptionId !== value,
        ),
      );
    } else {
      setAnswers((prev) => [
        ...prev,
        { questionId: questionId, answerOptionId: value },
      ]);
    }
  };

  const {
    result: response,
    mutate,
    error: submitError,
    isLoading: isSubmitLoading,
  } = useHttpMutationService({
    request: (payload?: CreateFeedBackRequest) =>
      FeedbackService.create(payload!),
  });

  const {
    mutate: saveDraft,
    error: saveDraftError,
    isLoading: isSaveDraftLoading,
  } = useHttpMutationService({
    request: (payload?: CreateFeedBackRequest) =>
      FeedbackService.saveDraft(payload!),
  });

  const createSubmitPayload = (
    answers: CreateAnswerRequest[],
  ): SubmitFeedbackPayload => {
    return {
      feedbackRequestId: +feedbackRequest!.id,
      answers: sortedQuestions.map((question) => {
        const answer = answers.find((a) => a.questionId === question.id);
        if (!answer) {
          return {
            questionId: question.id,
          };
        }
        return answer;
      }),
    };
  };

  const createDraftPayload = (
    answers: CreateAnswerRequest[],
  ): DraftFeedbackPayload => {
    return {
      feedbackRequestId: +feedbackRequest!.id,
      answers,
    };
  };

  const handleSubmit = async () => {
    const payload = createSubmitPayload(answers);
    const { error } = createFeedbackSchema.validate(payload);

    if (error) {
      setValidation(error);
      toastError(
        "Required field(s) missing. Please fill in all the required field(s) before sharing your feedback.",
      );
      setShowConfirmSubmitModal(false);
      return;
    }

    const response = await mutate(payload);
    if (response) {
      setFormIsDirty(false);
      toastSuccess(
        `Feedback has been submitted for ${feedbackRequest!.reviewee.email}`,
      );
    }
  };

  useError(submitError);

  useError(saveDraftError);

  const isLoading = useLoading([
    isFeedbackLoading,
    isFormLoading,
    isDraftedFeedbackLoading,
    isSubmitLoading,
    isSaveDraftLoading,
  ]);

  const questions = form ? form.questions : [];

  const sortedQuestions = useMemo(() => {
    return ArrayUtil.sortAscBySequence(questions);
  }, [questions]);

  const {
    formIsDirty,
    setFormIsDirty,
    showLeaveModal,
    handleCancelLeaveModal,
    handleConfirmLeaveModal,
  } = useFormBlockNavigation();

  useEffect(() => {
    if (answers.length === 0) {
      setFormIsDirty(false);
    }

    const answersIsDirty = answers.some(
      ({ answerOptionId, answerText }) =>
        !!answerOptionId || !!answerText?.trim(),
    );

    const initialDraftedAnswers = getInitialDraftedAnswers(draftedFeedback);

    if (
      initialDraftedAnswers &&
      !isAnwserDifferentFromDraft(initialDraftedAnswers, answers)
    ) {
      setFormIsDirty(false);
    } else {
      setFormIsDirty(answersIsDirty);
    }
  }, [answers, draftedFeedback]);

  const isAnwserDifferentFromDraft = (
    initialDraftedAnswers: CreateAnswerRequest[],
    currentAnswers: CreateAnswerRequest[],
  ): boolean => {
    if (initialDraftedAnswers.length != currentAnswers.length) return true;

    for (let i = 0; i < currentAnswers.length; i++) {
      if (
        initialDraftedAnswers[i].answerText !== currentAnswers[i].answerText ||
        initialDraftedAnswers[i].questionId !== currentAnswers[i].questionId ||
        initialDraftedAnswers[i].answerOptionId !==
          currentAnswers[i].answerOptionId
      ) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (!response || formIsDirty) return;

    navigate(PAGE_PATHS.REQUEST);
  }, [response, formIsDirty]);

  const handleClickSaveDraftButton = async () => {
    const payload = createDraftPayload(answers);

    const { error } = saveDraftFeedbackSchema.validate(payload);

    if (error) {
      return;
    }

    const response = await saveDraft(payload);
    if (response) {
      refetchDraft();
      setFormIsDirty(false);
      toastSuccess(`Draft saved successfully`);
    }
  };

  const handleClickCancelButton = () => {
    navigateToPreviousPage();
  };

  const handleShowConfirmSubmitModal = () => {
    setShowConfirmSubmitModal(true);
  };

  const handleCloseConfirmSubmitModal = () => {
    setShowConfirmSubmitModal(false);
  };

  const confirmationMsg = useMemo(() => {
    const defaultMsg = "Your feedback will be released immediately.";
    if (!form || !feedbackRequest) return defaultMsg;

    if (
      form.formType === FormType.THREE_HUNDRED_SIXTY &&
      feedbackRequest.dueDate
    ) {
      return `This action cannot be reverted. Your feedback will be locked and shared to the reviewee on ${getDisplayDate(
        feedbackRequest.dueDate,
      )}. If you want to make temporary changes, please use "Save Draft" instead.`;
    }

    return defaultMsg;
  }, [form, feedbackRequest]);

  const getAnswerErrorMesssage = useCallback(
    (index: number) => {
      let errorMessage = "";
      if (validation) {
        for (let i = 0; i < validation.details.length; i++) {
          const indexOfError = validation.details[i].path[1];
          if (indexOfError === index) {
            errorMessage = validation.details[i].message;
            break;
          }
        }
      }
      return errorMessage;
    },
    [validation],
  );

  return (
    <Layout mainClassName="!p-0">
      <LoadingWrapper isLoading={isLoading}>
        <div className="h-full flex flex-col">
          {form && feedbackRequest && (
            <div className="flex justify-between h-fit">
              <div className="h-fit flex-auto min-h-[200px] px-8 py-8">
                <div className="mb-6 flex justify-between">
                  <div className="flex gap-6 items-center">
                    <BackButton className="text-neutral-200" />
                    <div className="flex gap-2 items-center">
                      <FeedbackIcon type={form.formType} />
                      <PageTitle className="flex items-center gap-[0.5rem]">
                        <span>{form.name} for</span>
                        <UserBriefInfo
                          name={feedbackRequest.reviewee.fullName}
                          email={feedbackRequest.reviewee.email}
                          avatarUrl={feedbackRequest.reviewee.avatar || ""}
                          status={feedbackRequest.reviewee.status}
                          triggerClassName="gap-x-[0.5rem]"
                          className="text-turquoise-75 text-[24px] font-[600] leading-[31.2px]"
                        />
                      </PageTitle>
                    </div>
                  </div>
                </div>

                <div className="my-6 flex flex-col gap-4">
                  <div className="flex gap-2 items-center">
                    <div>
                      <span className="text-neutral-100 body-16-medium">
                        Type:&nbsp;
                      </span>
                      <span className="text-neutral-150 body-16-regular">
                        {FORM_TYPE_MAP[form.formType]}
                      </span>
                    </div>
                    <span className="text-neutral-100 body-14-regular">|</span>
                    {feedbackRequest.dueDate && (
                      <div>
                        <span className="text-neutral-100 body-16-medium">
                          Deadline:&nbsp;
                        </span>
                        <span className="text-neutral-150 body-16-regular">
                          {getDisplayDate(feedbackRequest.dueDate)}
                        </span>
                      </div>
                    )}
                  </div>
                  <Markdown className="body-16-regular text-neutral-150">
                    {form?.description}
                  </Markdown>

                  {form && form.formType === FormType.THREE_HUNDRED_SIXTY && (
                    <InfoMessageBlockFor360 />
                  )}
                </div>
                <form
                  className="flex-1 bg-blue-10 px-8 pt-8 pb-20 rounded-3xl"
                  onSubmit={handleSubmit}
                >
                  <div className="space-y-6">
                    {sortedQuestions.map((question, index) => (
                      <AnswerItem
                        key={index}
                        answers={answers}
                        question={question}
                        onChange={handleChange}
                        errorMessage={
                          validation ? getAnswerErrorMesssage(index) : ""
                        }
                      />
                    ))}
                  </div>
                </form>
              </div>
            </div>
          )}

          <div className="sticky bottom-0 mt-auto bg-neutral-0 flex justify-end gap-4 pt-6 pb-8 px-8 border-t border-neutral-20 rounded-b-3xl">
            {form && form.formType === FormType.THREE_HUNDRED_SIXTY ? (
              <Button
                variant="secondary"
                size="medium"
                label="Save Draft"
                disabled={!formIsDirty}
                onClick={handleClickSaveDraftButton}
              />
            ) : (
              <Button
                variant="secondary"
                size="medium"
                label="Cancel"
                onClick={handleClickCancelButton}
              />
            )}
            <Button
              variant="primary"
              size="medium"
              label="Share your feedback"
              onClick={handleShowConfirmSubmitModal}
            />
          </div>
        </div>

        <ConfirmModal
          open={showLeaveModal}
          title="Leave Page?"
          content="Please be aware that any of your unsaved actions will be discarded. Proceed anyway?"
          yesButton="Confirm"
          noButton="Cancel"
          onYes={handleConfirmLeaveModal}
          onNo={handleCancelLeaveModal}
        />
        <ConfirmModal
          open={showConfirmSubmitModal}
          title="Share your feedback?"
          content={confirmationMsg}
          yesButton="Share Feedback"
          noButton="Cancel"
          onYes={handleSubmit}
          onNo={handleCloseConfirmSubmitModal}
          yesButtonLoading={isSubmitLoading}
        />
      </LoadingWrapper>
    </Layout>
  );
};

export default CreateFeedBack;

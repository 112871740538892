import { create } from "zustand";
import { TotalOfTodoRequestResponse } from "responses/todo-request.response";

type FeedbackRequestStore = {
  totalsOfTodoRequest: TotalOfTodoRequestResponse[];
  setTotalsOfTodoRequest: (
    totalsOfTodoRequest: TotalOfTodoRequestResponse[],
  ) => void;
};

const useFeedbackRequestStore = create<FeedbackRequestStore>((set) => ({
  totalsOfTodoRequest: [],

  setTotalsOfTodoRequest: (
    totalsOfTodoRequest: TotalOfTodoRequestResponse[],
  ) => {
    set({ totalsOfTodoRequest: totalsOfTodoRequest });
  },
}));

export { useFeedbackRequestStore };

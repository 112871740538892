import { FormApi } from "apis";
import {
  FormFeedbackForMeResponse,
  FormFeedbackIProvidedResponse,
} from "responses";
import {
  CreateEditFormRequest,
  Form,
  FormFilter,
  FormWithQuestion,
  GetFormViewQuery,
} from "types/form";

const convertStrDateToDate = <T extends Form>(form: T): T => {
  const result: T = { ...form };
  const { dueDate, createdAt, publishDate } = result;
  if (dueDate) result.dueDate = new Date(dueDate);
  if (createdAt) result.createdAt = new Date(createdAt);
  if (publishDate) result.publishDate = new Date(publishDate);

  return result;
};

const FormService = {
  get: async (filter?: FormFilter): Promise<FormWithQuestion[]> => {
    const res = await FormApi.get(filter);
    return res.map((item) => convertStrDateToDate(item));
  },

  getFormFeedbackForMe: async (): Promise<FormFeedbackForMeResponse[]> => {
    return await FormApi.getFormFeedbackForMe();
  },

  getFormFeedbackIProvided: async (): Promise<
    FormFeedbackIProvidedResponse[]
  > => {
    return await FormApi.getFormFeedbackIProvided();
  },

  get360FormFeedback: async (): Promise<Form[]> => {
    const res = await FormApi.get360FormFeedback();
    return res.map((item) => convertStrDateToDate(item));
  },

  getFormViewById: async (id: number, query?: GetFormViewQuery) => {
    const response = await FormApi.getFormViewById(id, query);
    return convertStrDateToDate(response);
  },

  getFormEditById: async (id: number) => {
    const response = await FormApi.getFormEditById(id);
    return convertStrDateToDate(response);
  },

  create: async (data: CreateEditFormRequest) => {
    return await FormApi.create(data);
  },

  update: async (data: CreateEditFormRequest) => {
    return await FormApi.put(data);
  },

  delete: async (id: number) => {
    return await FormApi.delete(id);
  },

  getRunning360Forms: async () => {
    const res = await FormApi.getRunning360Forms();
    return res.map((item) => convertStrDateToDate(item));
  },
};

export default FormService;
